import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import axios from "axios";
import Selectrix from "react-selectrix";
import Modal from "react-bootstrap/Modal";
import { FaGraduationCap, FaWhatsapp, FaPhone, FaRunning, FaEye, FaCreditCard, FaPaypal } from "react-icons/fa";
import { MdWork, MdEmail, MdPayments, MdModeNight, MdOutlineZoomOutMap } from "react-icons/md";
import { IoCloseCircleOutline } from "react-icons/io5";
import {
  AiOutlineDown,
  AiOutlineRight,
  AiFillPlayCircle,
  AiFillPauseCircle,
} from "react-icons/ai";
import "react-toastify/dist/ReactToastify.css";
import ReactCountryFlag from "react-country-flag";
import ReactPlayer from "react-player";
import FlipCountdown from "@rumess/react-flip-countdown";
import { GoRocket } from "react-icons/go";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import { CiUser } from "react-icons/ci";
import { IoIosCloseCircleOutline } from "react-icons/io";
import TestimonialOne from "../blocks/testimonial/TestimonialOne";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Typical from 'react-typical';
import { FiCheck, FiClock, FiUser, FiMonitor, FiRadio, FiDownload, FiVideo, FiCheckCircle, FiPackage} from "react-icons/fi";
import { BsCircle, BsArrowLeftCircle, BsSun, BsFillPlayCircleFill, BsCloudSunFill } from "react-icons/bs";


const KeyCountry =  [
    {key: "PE", label: "Perú"},
    {key: "BO", label: "Bolivia"},
    {key: "MX", label: "México"},
    {key: "CO", label: "Colombia"},
    {key: "EC", label: "Ecuador"},
    {key: "PR", label: "Puerto Rico"},
    {key: "SV", label: "El Salvador"},
    {key: "CL", label: "Chile"},
    {key: "CR", label: "Costa Rica"},
    {key: "HN", label: "Honduras"},
    {key: "ES", label: "España"},
    {key: "UY", label: "Uruguay"},
    {key: "GT", label: "Guatemala"},
    {key: "PA", label: "Panamá"},
    {key: "NI", label: "Nicaragua"},
    {key: "PY", label: "Paraguay"},
    {key: "DO", label: "República Dominicana"},
    {key: "BR", label: "Brasil"},
    {key: "US", label: "Estados Unidos"},
    {key: "CA", label: "Canada"},
]

class WebinarLive extends Component {
  constructor() {
    super();

    const rango = Array.from({ length: 7 }, (_, index) => index + 10);
    this.state = {
      pricingAPI: {},
      bootcampSlug: 'blackfriday',
      bootcampSlugMensual: "blackfriday",
      Temario: "",
      Programa: "",
      isOpen: false,
      countryCode: "PE",
      Email: "",
      Phone: "",
      Name: "",
      isOpenTemario: false,
      isPdfPrice: false,
      langUS: false,
      isPlaying: false,
      isOpenPlanPay: false,
      price_mensual_regular: "US$ 159 mensual",
      price_completo_regular: "",
      price_completo: "",
      price_mensual: "US$ 125",
      titlePricePre: "Antes del viernes 17 de noviembre",
      demoTitle: "",
      demoUrl: "",
      demoPreview: "",
      fechaLimite: "",
      DescuentoBody: "",
      visitasNumber: this.getRandomVisitasNumber(),
      videoRef: React.createRef(),
      isOpenLead: false,
      loadingEnroll: false,
      demo_alt: false,
      body_intro: false,
      evento_1: "",
      evento_2: "",
      select_mes: "",
      DataFechas: [],
      select_turno: "",
      isOpenResume: false,
      Turnos: [
        {key: "noche", label: "Noche"},
        {key: "tarde", label: "Tarde"},
    ],
      durationVideo: 0,
      durationPercen: 0,
      registerRequerid: false,
      videoInit: false,
      mount: "",
      isOpen2: false,
      title_partes: 'o en 5 pagos de',
      planPagoUSD: "",
      planPagoPE: "",
      isOpenPlanPay: false,
      DescuentoBody: "",
      price_mount: "",
      selectFecha: false,
      DescuentoBodyUSD: "",
      DescuentoBodyPEN: "",
      isOpenReunion: false,
      cuotaDolar: '',
      cuotaSoles: '',
      isOpenModalInfo: false,
      isModalWS: false,
      showMore: false,
      showPrice: false
    };
    this.GetLinkWS = this.GetLinkWS.bind(this);
    this.openModalEmail = this.openModalEmail.bind(this);
    this.onRenderOption = this.onRenderOption.bind(this);
    this.DownloadPdf = this.DownloadPdf.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.DynamicCountry = this.DynamicCountry.bind(this);
    this.openModal2 = this.openModal2.bind(this);

  }

  openModal2 () {
    this.setState({isOpen2: !this.state.isOpen2});
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.state.Email.trim() === "") {
      toast.warning("El campo Email es requerido.");
      return;
    }

    this.setState({ loadingEnroll: true, sendDataForm: true });

    localStorage.setItem("email", this.state.Email);
    localStorage.setItem('name', this.state.Name);
    // localStorage.setItem('phone', this.state.Phone);

    axios
      .post("https://cprij4toee.execute-api.us-east-1.amazonaws.com/dev", {
        lead: true,
        evento: true,
        bootcamp: "Web full stack",
        email: this.state.Email,
        name: this.state.Name,
        country: this.state.countryCode,
        Token: "c3b4b89c",
        landing: 'webinar-1'
      })
      .then((res) => {
        window.gtag_report_conversion();

        toast.success(
          "¡Gracias por registrarte al webinar de introducción!"
        );

        this.setState({
          loadingEnroll: false,
          isOpenLead: false,
          isPlaying: true,
          registerRequerid: false
        });
      });
  }

  getRandomVisitasNumber() {
    const rango = Array.from({ length: 7 }, (_, index) => index + 10);
    return rango[Math.floor(Math.random() * rango.length)];
  }

  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };


  

  DynamicCountry(country='' ){
    window.urlWasi = this.GetLinkWS();

    const newCountry = country || this.state.countryCode;

    if (country){
        this.setState({countryCode: country});
    }

    
    this.setState({
        select_mes:  this.state.pricingAPI[this.state.bootcampSlug]['fechas'][0]['mes'],
        isPricePais: false,
        utc_offset: '-0500',
        utc_dif: 0,
        timestamp24h: this.state.pricingAPI[this.state.bootcampSlug]['timestamp24h'],
        semibeca_img: this.state.pricingAPI[this.state.bootcampSlug]['semibeca_img'],
        DataFechas: this.state.pricingAPI[this.state.bootcampSlug]['fechas'],
        mesesInicio: this.state.pricingAPI[this.state.bootcampSlug]['mesesInicio'],
        Turnos: this.state.pricingAPI[this.state.bootcampSlug]['turnos'],
        fechaLimite: this.state.pricingAPI[this.state.bootcampSlug]['fechaLimite'],
        isPriceEspecial: this.state.pricingAPI[this.state.bootcampSlug]['isPriceEspecial'],
        PriceEspecialBody: this.state.pricingAPI[this.state.bootcampSlug]['PriceEspecialBody'],
        PriceEspecialPreBody: this.state.pricingAPI[this.state.bootcampSlug]['PriceEspecialBody'],
        timePrueba: this.state.pricingAPI[this.state.bootcampSlug]['time_prueba'],
        FooterColor: this.state.pricingAPI[this.state.bootcampSlug]['FooterColor'],
        price_completo: this.state.pricingAPI[this.state.bootcampSlug]['price']['usd']['price_view_base'], //495
        price_completo_regular: this.state.pricingAPI[this.state.bootcampSlug]['price']['usd']['price_view_base_regular'],
        price_mensual: this.state.pricingAPI[this.state.bootcampSlugMensual]['price']['usd']['price_view_final'],
        price_mensual_regular: this.state.pricingAPI[this.state.bootcampSlugMensual]['price']['usd']['price_view_base_regular'],
        titlePrice: this.state.pricingAPI[this.state.bootcampSlug]['titlePrice'],
        titlePricePre: this.state.pricingAPI[this.state.bootcampSlug]['titlePrice'],
        price_payment: "1",
        salario_fullstack_jr: "US$ 14,000 ANUAL",
        salario_fullstack_md: "US$ 24,000 ANUAL",
        salario_fullstack_sr: "+US$ 40,000 ANUAL",
        planPagoUSD: this.state.pricingAPI[this.state.bootcampSlug]['planPagoUSD'],
        planPagoPE: this.state.pricingAPI[this.state.bootcampSlug]['planPagoPE'],
        DescuentoBody: this.state.pricingAPI[this.state.bootcampSlug]['DescuentoBody'],
        price_mount: this.state.pricingAPI[this.state.bootcampSlug]['price']['usd']['mount'],
        DescuentoBodyUSD: this.state.pricingAPI[this.state.bootcampSlug]['DescuentoBodyUSD'],
        DescuentoBodyPEN: this.state.pricingAPI[this.state.bootcampSlug]['DescuentoBodyPEN'],
        cuotaDolar: this.state.pricingAPI[this.state.bootcampSlug]['cuotaDolar'],
        cuotaSoles: this.state.pricingAPI[this.state.bootcampSlug]['cuotaSoles'],
    })

    try {
        if (this.state.pricingAPI[this.state.bootcampSlug].price[country]){
            this.setState({
                isPricePais: true,
                price_completo: this.state.pricingAPI[this.state.bootcampSlug].price[country].price_view_base,
                price_completo_regular: this.state.pricingAPI[this.state.bootcampSlug].price[country].price_view_base_regular,
                price_mensual: this.state.pricingAPI[this.state.bootcampSlugMensual]['price'][country]['price_view_final'],
                price_mensual_regular: this.state.pricingAPI[this.state.bootcampSlugMensual]['price'][country]['price_view_base_regular']
            });
        }
    } catch (error) {
        console.log('Log',error);
    }



    if (newCountry === 'PE') {
        this.setState({
            utc_offset: '-0500',
            salario_fullstack_jr: "S/ 45,000 ANUAL",
            salario_fullstack_md: "S/ 79,00 ANUAL",
            salario_fullstack_sr: "+ S/ 135,000 ANUAL"
        });
    } else if (newCountry === 'MX') {
        this.setState({
            utc_dif: -1,
            utc_offset: '-0600',
            salario_fullstack_jr: "$300,000 MXN ANUAL",
            salario_fullstack_md: "$450,000 MXN ANUAL",
            salario_fullstack_sr: "+ $800,000 MXN ANUAL"
            });
    } else if (newCountry === 'CR'){
        this.setState({utc_dif: -1, utc_offset: '-0600'});
    } else if (newCountry === 'CL'){
        this.setState({utc_dif: -1, utc_offset: '-0400'});
    } else if (newCountry === 'BO'){
        this.setState({
            utc_dif: -1,
            utc_offset: '-0400',
            });
    } else if (newCountry === 'CU'){
        this.setState({utc_dif: +1});
    } else if (newCountry === 'SV'){
        this.setState({utc_dif: -1, utc_offset: '-0600'});
    } else if (newCountry === 'GT'){
        this.setState({utc_dif: -1});
    } else if (newCountry === 'HN'){
        this.setState({utc_dif: -1, utc_offset: '-0600'});
    } else if (newCountry === 'NI'){
        this.setState({utc_dif: -1});
    } else if (newCountry === 'PY'){
        this.setState({utc_dif: +1, utc_offset: '-0300'});
    } else if (newCountry === 'PR'){
        this.setState({utc_dif: +1, utc_offset: '-0300'});
    } else if (newCountry === 'DO'){
        this.setState({utc_dif: +1});
    } else if (newCountry === 'UY'){
        this.setState({
            utc_dif: +2,
            utc_offset: '-0300'
        });
    } else if (newCountry === 'BR'){
        this.setState({
            utc_dif: +2,
            utc_offset: '-0300'
        });
    }

    if (newCountry === 'ES') {
        this.setState({
            utc_offset: '+0100',
        });
    }

}


  renderPrice(price, prueba = false) {
    return (
      <>
        <div className="row" style={{ placeContent: "center" }}>
          <div className="col-12">
            <p
              style={{
                fontSize: "45px",
                color: prueba ? "black" : "white",
                fontWeight: "bold",
                paddingTop: "9px",
                paddingBottom: "9px",
              }}
            >
              {price}
              <span
                style={{
                  display: "inline-block",
                  fontSize: window.type_device ? "20px" : "25px",
                  marginLeft: "5px",
                }}
              >
                {" "}
              </span>
            </p>
          </div>
        </div>
      </>
    );
  }
  onRenderOption(option, complete) {
    return (
      <li>
        <ReactCountryFlag
          countryCode={option.key}
          svg
          style={{
            width: "2em",
            height: "2em",
            padding: "1px",
          }}
          title="país"
        />{" "}
        {complete ? option.label : option.key}
      </li>
    );
  }

  onRenderSelection(selected, complete) {
    return (
      <span style={{ padding: 2 }}>
        {selected ? (
          <span style={{ color: "black" }}>
            <ReactCountryFlag
              countryCode={selected.key}
              svg
              style={{
                width: "2.3em",
                height: "2.3em",
                padding: "2px",
              }}
              title="país"
            />{" "}
            {complete ? selected.label : selected.key}
          </span>
        ) : (
          <span style={{ padding: 5 }}></span>
        )}
      </span>
    );
  }

  GetLinkWS() {
    if (window.type_device) {
      return `https://api.whatsapp.com/send?phone=51936670597&text=Hola,%20informaci%C3%B3n%20sobre%20el%20Bootcamp%20Web%20Full%20Stack%20online%20en%20vivo.`;
    } else {
      return `https://web.whatsapp.com/send?phone=51936670597&text=Hola,%20informaci%C3%B3n%20sobre%20el%20Bootcamp%20Web%20Full%20Stack%20online%20en%20vivo.`;
    }
  }

  openModalEmail() {
    this.setState({ Phone: "" });
    this.setState({ isOpenTemario: !this.state.isOpenTemario });
  }

  DownloadPdf(e) {
    e.preventDefault();

    if (this.state.Email === "") {
      toast.warning("El campo Email es requerido.");
      return;
    }

    localStorage.setItem("email", this.state.Email);

    toast.success(
      !this.state.langUS
        ? "Hola!, enviamos el programa por Email, gracias!"
        : "Hello! We sent the program by Email, thank you!"
    );

    this.openModalEmail();

    axios
      .post("https://cprij4toee.execute-api.us-east-1.amazonaws.com/dev", {
        lead: true,
        evento: true,
        bootcamp: this.state.slugBootcamp,
        email: this.state.Email,
        name: this.state.Name,
        country: this.state.countryCode,
        Token: "c3b4b89c",
      })
      .then((res) => {
        window.gtag_report_conversion();
        // window.location.href = `/inscripcion/fullstack/${this.state.countryCode}`;
        // toast.success(`Hola ${this.state.Name}!, tengamos una breve sesión informativa para completar tu inscripción`);
      });
  }


  onRenderOption ( option, complete ) {
    return (
        <li>
        <ReactCountryFlag
            countryCode={option.key}
            svg
            style={{
                width: '1.5em',
                height: '1.5em',
                padding: '1px'
            }}
            title="país"
        />  {option.key }
        </li>
            )
}

onRenderSelection( selected, complete ){
    return 	(<span style={{ padding: 2 }}>
    { selected ?
        <span style={{"color": "black"}}>
            <ReactCountryFlag
            countryCode={selected.key}
            svg
            style={{
                width: '2em',
                height: '2em',
                padding: '2px'
            }}
            title="país"
    />
        </span>
        :
           <span style={{padding: 5}}></span>
    }
</span>)
}

renderPais(complete=false){
    return (
        <>
        <span style={{
            fontSize: "17px",
            fontWeight: "bold",
            position: "absolute",
            left: "15px",
            top: "-25px",
            zIndex: "1",
        }} > Zona horaria </span>
            <Selectrix
        height={300}
        onRenderOption={(option) => this.onRenderOption(option, complete)}
        onRenderSelection={(option) => this.onRenderSelection(option, complete)}
        placeHolderInside={true}
        placeholder={"País"}
        customScrollbar={true}
        searchable={false}
        materialize={false}
        defaultValue={this.state.countryCode}
        options={KeyCountry}
        onChange={value => {
            this.DynamicCountry(value.key)}
        }
    />
        </>

    )
}







renderMes(){
  return (
      <>
      <span style={{
          fontSize: "17px",
          fontWeight: "bold",
          position: "absolute",
          left: "15px",
          top: "-25px",
          zIndex: "1",
      }} > {!this.state.langUS ? "Mes:": "Month:"} </span>
      <Selectrix
      height={250}
      placeHolderInside={true}
      placeholder={!this.state.langUS ? "Todos": "All" }
      customScrollbar={true}
      searchable={false}
      materialize={false}
      defaultValue={this.state.select_mes}
      options={this.state.mesesInicio.map((item) => ({key: item, label: item})) }
      onChange={value => this.setState({select_mes: value.key})}
  />
      </>

  )
}


renderTurno(){
    return (
        <>
        <span style={{
            fontSize: "17px",
            fontWeight: "bold",
            position: "absolute",
            left: "15px",
            top: "-25px",
            zIndex: "1",
        }} >{!this.state.langUS ? "Turno": "Shift"}:</span>
        <Selectrix
        height={250}
        placeHolderInside={true}
        placeholder={!this.state.langUS ? "Todos": "All" }
        customScrollbar={true}
        searchable={false}
        materialize={false}
        defaultValue={this.state.select_turno}
        options={this.state.Turnos}
        onChange={value => this.setState({select_turno: value.key})}
    />
        </>

    )
}

renderHorario(){
  if (this.state.utc_offset === "-0500") {
      return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">8:00 PM a 10:35 PM</p>
  } else if (this.state.utc_offset === "-0600") {
      return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">7:00 PM a 9:35 PM</p>
  } else if (this.state.utc_offset === "-0400"){
      return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">9:00 PM a 11:35 PM</p>
  }  else {
      return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">8:00 PM a 10:35 PM</p>
  }
}

renderHorarioFinSemana(){
if (this.state.utc_offset === "-0500") {
  return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">10:00 AM a 12:35 PM</p>
} else if (this.state.utc_offset === "-0600"){
  return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">09:00 AM a 11:35 AM</p>
} else if (this.state.utc_offset === "-0400"){
  return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">11:00 AM a 13:35 PM</p>
} else if (this.state.utc_offset === "+0100"){
  return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">16:00 PM a 18:35 PM</p>
} else {
  return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">10:00 AM a 12:35 PM</p>
}

}

renderHorarioFinSemanaTarde(){
if (this.state.utc_offset === "-0500") {
  return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">03:00 PM a 05:35 PM</p>
} else if (this.state.utc_offset === "-0600"){
  return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">02:00 PM a 04:35 PM</p>
} else if (this.state.utc_offset === "-0400"){
  return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">04:00 PM a 06:35 PM</p>
} else if (this.state.utc_offset === "+0100"){
  return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">09:00 PM a 11:35 PM</p>
} else {
  return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">03:00 PM a 05:35 PM</p>
}
}



    renderFechaSelect(item, key){
      return (
          <div key={key} className="row row--35 align-items-center rn-address" data-aos="fade-up"  style={{boxShadow: "#c6c9d8 8px 6px 15px 1px"}} >
          <div className="col-12 mb-3">

              <h2 style={{marginBottom: "0px", fontSize: window.type_device && "39px" }}>
                  {item.fecha_inicio}
              </h2>
              <div style={{width: window.type_device ? "200px": "450px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "20px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>

              <div className="row">
                  <div className="col-lg-2 col-md-3 col-12  mt-2">
                  <span style={{padding: "3px", "padding-top": "4px","padding-bottom": "4px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                  <FiVideo/> {!this.state.langUS ? "En vivo": "Live"}
                                  </span>
                  </div>
                  <div className="col-md-4 col-lg-3 col-12  mt-2">
                      <span style={{"padding-top": "4px","padding-bottom": "4px", "font-size": "18px", "color":"white", "background-color": "slateblue", "border-radius": "8px", "padding-left": "8px", "padding-right": "8px"}}>
                        Plazas limitadas
                      </span>
                  </div>
              </div>

          </div>

          <div className="col-md-3 col-12" style={{height: "110px", borderRight: !window.type_device ? "1px solid #121212" : "none" }}>
              <div> <h5 className="mb-2" ><b> {!this.state.langUS ? "Duración": "Duration"} </b></h5> </div>
              <div> <p style={{fontSize: "18px"}}> {!this.state.langUS ? "5 meses": "5 months"}
              <span style={{display: "inline-block"}}></span>
              </p> </div>
              <span className="bg-white m-1" style={{padding: "5px 7px", border: "1px solid mediumpurple", display: "inline-block", borderRadius: "10px", fontSize: "16px"}}>
              <b>{item.fecha_start_end}</b>
              </span>
          </div>

          <div className="col-md-3 col-12" style={{height: "110px", marginTop: window.type_device ? "25px": "none", borderRight: !window.type_device ? "1px solid #121212" : "none" }}>
              <div> <h5 className="mb-2"><b> {!this.state.langUS ? "Días": "Days"} </b></h5> </div>
              <div> <p style={{fontSize: "18px"}}> {item.dias}  </p> </div>
          </div>

          <div className="col-md-3 col-12" style={{height: "110px", marginTop: "none"}}>
          <div> <h5 className="mb-2">
              <b>
              Horario
               {item.turno === 'dia' &&  <> (Mañana) <BsSun style={{height: "35px", width: "35px", color: "goldenrod", marginLeft: "3px", fontSize: "26px"}}/>  </>  }
               {item.turno === 'tarde' && <> (Tarde) <BsCloudSunFill style={{height: "35px", width: "35px", color: "darkblue", marginLeft: "3px", fontSize: "26px"}} /> </>  }
               {item.turno === 'noche' && <> (Noche) <MdModeNight style={{height: "35px", width: "35px", color: "darkblue", marginLeft: "3px", fontSize: "26px"}} /> </>   }
              </b>
              </h5> </div>
              <div className="row">
                  <div className="col-12">
                  {(item.turno == 'noche' || item.turno == 'night') && this.renderHorario() }
                  {item.turno == 'dia'  && this.renderHorarioFinSemana() }
                  {item.turno == 'tarde'  && this.renderHorarioFinSemanaTarde() }
                  </div>

              </div>
          </div>

          <div className="col-md-3 col-12" style={{marginTop: "30px"}}>
              <div>
              <a href={"#inscripcion"}>
              <button
              style={{lineHeight: "20px", fontSize: "18px", marginBottom: "13px", padding: "15px 30px" }}
              className=" btn-efect">
              ELEGIR FECHA
              </button>
              </a>
              </div>
          </div>

      </div>
      )
    }


    renderFechas() {
      return (
          <div>
              {!this.state.select_mes && this.state.DataFechas.map((item, key) => (

                  this.state.select_turno ?
                   this.state.select_turno == item.turno && this.renderFechaSelect(item, key)
                  :
                  this.renderFechaSelect(item, key)

              ))}

              {this.state.select_mes && this.state.DataFechas.map((item, key) => (

                  this.state.select_turno ?
                  this.state.select_turno == item.turno && this.state.select_mes == item.mes && this.renderFechaSelect(item, key)
                 :
                  this.state.select_mes == item.mes && this.renderFechaSelect(item, key)


              ))}
        </div>

      )}



    renderBeca() {
      return (
          <>
  
   {/* Start PRicing Table Area  */}
   <div className="col-12 mt-1" style={{paddingBottom: "13px", paddingRight: "15px", paddingLeft: "15px", maxWidth: "470px", marginRight: window.type_device ? "auto": "1px" }} >
  
  
  <div className="rn-pricing" style={{boxShadow: "rgba(140, 82, 255, 0.9) 4px 4px 15px 3px", border: "none", background: "linear-gradient(135deg, #C56CD6 0%,#3425AF 100%)", borderRadius: "20px"}} >
      <div className="pricing-table-inner">
  
          <div className="pricing-header pb-0 mb-2 mt-1">
  
    
          
          <span style={{padding: "6px 10px", margin: "5px", "background-color": "tomato", "border-radius": "7px", "color": "white", "font-size": "20px", fontWeight: "bold"}}>
                  {this.state.countryCode === 'PE' ? this.state.DescuentoBodyPEN: this.state.DescuentoBodyUSD}
          </span>
  
  
          <h3 className="text-white mt-1 mb-0">Monto Total con la Beca50</h3>
               
              <span style={{color: "white", display: "inline-block"}}>
              <del style={{color: "lightgray", fontSize: "25px"}}>
              &nbsp; {this.state.price_completo_regular} &nbsp;
              </del>
              </span>
  
  
              <h3 className="pricing" style={{marginBottom: "5px"}} >
              {this.renderPrice(this.state.price_completo)}
              </h3>
  
              <p className="text-white" style={{fontSize: '20px', marginTop: '10px'}}>
                  ¡Ofrecemos la posibilidad de pagar en 3 partes de {this.state.countryCode === 'PE' ? this.state.cuotaSoles: this.state.cuotaDolar} cada una!
              </p>
  
              {this.state.countryCode === 'MX' &&
              <div className="d-flex" style={{justifyContent: "center"}}>
  
              <a style={{color: "white", fontStyle: "italic", fontSize: "16px", textDecoration: "underline"}} href={`https://www.google.com/search?q=convertir+${this.state.price_mount}+d%C3%B3lares+a+pesos+mexicanos`} target="_blank">
              Convertir a pesos mexicanos
              </a>
              </div>}
  
  
              <div className="slide-btn" style={{marginTop: "15px", textAlign: "center"}}>
          <a href={`/inscripcion/${this.state.bootcampSlug}/${this.state.countryCode}`}>
                  <button
                  onClick={this.TrackEventConversion}
                  id="enroll_bootcamp_2"
                  style={{lineHeight: "28px", fontSize: "23px", marginBottom: "13px", padding: "15px 20px" }}
                  className=" btn-efect">
                  Empezar inscripción
                   </button>
                  </a>
          </div>
  
  
  
  
          </div>
  
      </div>
  </div>
  </div>
  {/* End PRicing Table Area  */}
  
  
          </>
      )
  }

  

  render() {
    const TypingAnimation2 =  React.memo(()=>{
      return !this.state.langUS ?
      <Typical
      steps={['Aprende 100% online a tu propio ritmo.', 500 ,'con la guía de tu Mentor.', 1000]}
      loop={Infinity}
      wrapper="h2"/>
      :
      <Typical
      steps={['Learn 100% online live', 500 ,'with the guidance of your Mentor.', 1000]}
      loop={Infinity}
      wrapper="h2"
  />
    },(props,prevProp)=> true ); // this line prevent re rendering
    

    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Documental | Bootcamp Full Stack" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.webp"
          register={true}
          langUS={this.state.langUS}
        />

        <div
          className="breadcrumb-area rn-bg-color bg_image bg_image--1"
          style={{ height: "85px" }}
          data-black-overlay="6"
        ></div>

        {/* Start Page Wrapper */}
        <div
          className="rn-service-details ptb--1 bg_color--1 py-1"
          style={{ textAlign: "center", backgroundColor: "#0d0d0d" }}
        >
          <div className={window.type_device ? "w-100" : "container"}>
            <div className="row justify-content-center">
                <div className="service-details-inner w-100">
                  <div className="inner">
                    {/* Start Single Area */}



                      <div className="col-12 mt-2" data-aos="fade-right">
                        <h3 className="mb-1 title-gradient-2"
                        style={{
                          lineHeight: window.type_device ? "35px": "65px"
                        }}
                        >
                          <span
                           style={{fontFamily: "'Poppins',sans-serif",
                            fontStyle: "italic", fontSize: window.type_device ? "29px": "53px" }}
                           >
                          ¡Desde Cero a Desarrollador <br/> Web Full Stack!
                          </span>
                        </h3>
                      <h5 style={{fontStyle: "italic", marginBottom: "3px", fontSize: "18px", color: "whitesmoke"}}>Clic sobre el Vídeo 🔊 para activar el audio</h5> 
                      
                      <h4 className="my-3" style={{ color: "white" }}>
                                  <span style={{padding: "8px", marginLeft: "8px", "background-color": "slateblue", "border-radius": "9px", "padding-left": "15px", "padding-right": "15px", "color": "white", "font-size": "23px"}}>
                                      {window.location.pathname.includes("clase-1") && "Clase #1"}
                                      {window.location.pathname.includes("clase-2") && "Clase #2"}
                                      {window.location.pathname.includes("clase-3") && "Clase #3"}
                                  </span>
                        </h4>

                      </div>

                 



                      <div className="col-12 w-100" style={{backgroundColor: "rgb(13, 13, 13)"}}>
                        <iframe id="iframe-video" style={{borderRadius: "8px", backgroundColor: 'rgb(13, 13, 13)'}} allowFullScreen frameborder="0"  border="none" width={"100%"} height={"auto"}
                        src={"https://player.dojofullstack.com/?url=" + this.state.demoUrl +"&poster=https://media.dojofullstack.com/bootcamp/avatar-preview-flex-video.webp"}></iframe>
                      </div>

                      {/* <div
                        className="col-12"
                        style={{
                          textAlign: "-webkit-center",
                          position: "relative",
                          maxHeight: window.type_device ? "450px" : "500px",
                        }}
                      >
                        <ReactPlayer
                          muted={true}
                          ref={this.state.videoRef}
                          id="video-play"
                          style={{ backgroundColor: "#0d0d0d" }}
                          controls={false}
                          url={this.state.demoUrl}
                          playing={this.state.isPlaying}
                          width={"original"}
                          height={"100%"}
                          onProgress={(progress) => {
                              this.setState({durationPercen: (progress.playedSeconds/this.state.durationVideo)*100 || 0});

                              // if (progress.playedSeconds >= 65 && this.state.Email === '' && this.state.registerRequerid){
                              // this.setState({isOpenLead: true, isPlaying: false});
                              // }

                          }}
                          onDuration={(duration) => {
                            this.setState({durationVideo: duration});
                          }}
                        />
    <div>
      <ProgressBar striped animated variant="info" now={this.state.durationPercen} style={{maxWidth: "890px"}} />
    </div>

                        <button
                          className=" btn-solid m-3 play-buton-zoom buton-zoom"
                          style={{
                            margin: "5px",
                            fontWeight: "bold",
                            textTransform: "none",
                            display: this.state.isPlaying
                              ? "none"
                              : "inline-block",
                          }}
                          onClick={(e) => {
                            if (!this.state.videoInit){
                              document.querySelector("#video-play > video").currentTime = 0;
                              document.querySelector("#video-play > video").muted = false;
                              document.querySelector("#video-play > video").volume = 0.9;  
                            }
                            this.setState({ isPlaying: true, videoInit: true });
                          } }
                        >
                          <AiFillPlayCircle
                            style={{
                              borderRadius: "5px",
                              border: "1px solid white",
                              color: "white",
                              marginRight: "6px",
                              marginBottom: "3px",
                              fontSize: window.type_device ? "105px" : "180px",
                            }}
                          />
                        </button>

                        <button
                          className=" btn-solid m-3 pause-buton-zoom"
                          style={{
                            margin: "5px",
                            fontWeight: "bold",
                            textTransform: "none",
                            display: this.state.isPlaying
                              ? "inline-block"
                              : "none",
                          }}
                          onClick={(e) => this.setState({ isPlaying: false })}
                        >
                          <AiFillPauseCircle
                            style={{
                              marginRight: "5px",
                              marginBottom: "3px",
                              fontSize: "30px",
                              color: "white",
                            }}
                          />
                          <span
                            className="texto-btn"
                            style={{
                              fontSize: window.type_device ? "16px" : "20px",
                              color: "white",
                            }}
                          >
                            Pausar
                          </span>
                        </button>

               

                        <button
                          className=" btn-solid m-3 zoom-buton-zoom"
                          style={{ margin: "5px", fontWeight: "bold" }}
                          onClick={(e) => (
                            document
                              .querySelector("#video-play")
                              .requestFullscreen(),
                            this.setState({ isPlaying: true })
                          )}
                        >
                          <MdOutlineZoomOutMap
                            style={{ fontSize: "30px", color: "white" }}
                          />
                        </button>
                
                      </div> */}

{true &&
                      <div className="pb-3 mb-2 texto-especial">
             
                        <p
                          className="px-4 text-white mt-3"
                          style={{
                            fontSize: window.type_device ? "16px" : "18px",
                          }}
                        >
                        
                        ¡Aprender desarrollo web full stack te abrirá las puertas a un mundo de oportunidades emocionantes y bien remuneradas! Domina las habilidades de programación front-end y back-end para crear aplicaciones web dinámicas y funcionales.

                        </p>


                        <p
                          className="px-4 text-white mt-1"
                          style={{
                            fontSize: "18px",
                          }}
                        >
                          
                          ¿Ya tuviste la oportunidad de ver la clase? Te invito a descubrir nuestro programa más completo de 3 meses, en línea y en vivo.
                           ¡No te lo pierdas! 👉

                        </p>


                        <div className="container px-1 mb-5"  >
          
          <a href="/">
 <button
className="btn-efect-live mt-0"
  style={{color: "white", width: "100%", fontSize: "23px", display: "block", margin: "auto", textTransform: "capitalize"}}>

{!this.state.loadingEnroll  && <b style={{FontFamily: "'Poppins',sans-serif", fontSize: "23px"}}>
🚀 Bootcamp Full Stack de 3 meses
    </b> }

    </button>
    </a>
</div> 
         

                    

                      </div>

                      
  }
                    </div>
                  </div>
                </div>
              </div>
            </div>
        {/* End Page Wrapper */}






{this.state.showPrice &&
        <div className="rn-pricing-table-area ptb--40 bg_color--5 mb-2" data-aos="fade-right" id="empezar">

<div className="container px-1" >
    <div className="row" style={{justifyContent: "center", textAlign: "-webkit-center"}}>


<div className="col-12 mt-1" style={{paddingBottom: "13px", paddingRight: "15px", paddingLeft: "15px", maxWidth: "490px", marginRight: window.type_device ? "auto": "1px" }} >

<div className="rn-pricing" style={{boxShadow: "rgba(140, 82, 255, 0.9) 4px 4px 15px 3px", border: "none", background: "linear-gradient(to right, #8e2de2, #4a00e0)", borderRadius: "20px"}} >
<div className="pricing-table-inner mb-5">

<div className="pricing-header pb-0 mb-2 mt-1">




<h3 className="text-white p-0 m-0">Bootcamp</h3>

<h2 className="text-white" style={{fontSize: window.type_device ? "38px": ""}}>Web Full Stack</h2>


<span style={{padding: "6px 10px", margin: "5px", "background-color": "tomato", "border-radius": "7px", "color": "white", "font-size": "18px", fontWeight: "bold"}}>
BECA PARCIAL DEL 50%
</span>

<div>
<p style={{fontSize: "15px", marginTop: "10px", color: "white"}}>
¡Inscríbete hoy y ahorra un 50%! No dejes pasar esta oportunidad única para beneficiarte.
</p>
</div>


<h3 className="text-white mt-2 mb-0">
Con la Beca Parcial Paga Únicamente 
</h3>

<span style={{color: "white", display: "inline-block"}}>
<del style={{color: "white", fontSize: "23px"}}>
&nbsp; {this.state.price_completo_regular} &nbsp;
</del>
</span>




<h3 className="pricing" style={{margin: "10px"}} >
{this.renderPrice(this.state.price_completo)}
</h3>



<div className="slide-btn" style={{marginTop: "20px", textAlign: "center"}}>
<a href={`/inscripcion/${this.state.bootcampSlug}/${this.state.countryCode}`}>
<button
onClick={this.TrackEventConversion}
id="enroll_bootcamp_2"
style={{fontFamily: "'Poppins',sans-serif",  lineHeight: "28px", fontSize: "25px", marginBottom: "13px", padding: "15px 30px", textTransform: "uppercase" }}
className="btn-efect">
<GoRocket style={{fontSize: "23px", marginRight: "10px"}}/>
Inscríbete aquí
</button>
</a>
</div>


<div className="col-12 text-center mt-2">
  <a target="_blank" href="/">
<button
name="submit"
style={{color: "white", marginTop: "5px", marginBottom: "5px", fontSize: "22px",
"border-radius": "20px",
"border": "2px solid white",
"padding": "9px 20px"
}}>
Más Información y Plan de Estudio
<AiOutlineRight style={{padding: "5px", fontSize: "30px"}}/>
</button>
</a>

</div>


</div>

</div>
</div>
</div>

{/* End PRicing Table Area  */}


    </div>

</div>
      </div>
  }



                  



        <Modal
          show={this.state.isOpenLead}
          size="xl"
          style={{top:  window.type_device ? "1%": "15%"}}
        >
                <Modal.Body className="body-evento">
         
          <div className="inner" style={{paddingTop: window.type_device ? "10px": "20px"}}>
                    <div className="contact-form--1">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                <h3 className="mb-2 mt-1" style={{fontSize: window.type_device ? "25px": "39px"}}>
                                Regístrate al webinar de introducción
                                <span style={{padding: "3px", marginLeft: "7px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": window.type_device ? "20px": "23px" }}>
                                    Gratuito
                        </span>
                                {/* <img style={{marginLeft: "9px"}} alt="canal zoom" height="70px" src="/assets/images/dojopy/zoom_.webp"></img> */}
                                </h3>

                                <div style={{width: window.type_device ? "250px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>
                                    <>
                                    <h4 className="mb-2 mt-1" style={{fontWeight: "bold"}}>
                                    ¡Únete a la revolución tecnológica y desarrolla tu futuro!
                                    Regístrate para acceder a una sesión demo exclusiva.
                                      </h4>

                                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "18px"}}>
                                    <span style={{"color":"slateblue", "fontSize": "25px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                    ¿Qué es el Desarrollo Web Full Stack y qué aprenderás en el BootCamp?
                                    
                                    </p>
                                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "18px"}}>
                                    <span style={{"color":"slateblue", "fontSize": "25px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                    ¿Cómo es la metodología de aprendizaje online en vivo y quienes serán tus instructores?
                                    </p>
                                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "18px"}}>
                                    <span style={{"color":"slateblue", "fontSize": "25px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                    ¿Cuál es la RoadMap o ruta de aprendizaje?
                                    
                                    </p>
                                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "18px"}}>
                                    <span style={{"color":"slateblue", "fontSize": "25px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                    ¿Qué es Frontend y Backend?
                                    
                                    </p>
                                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "18px"}}>
                                    <span style={{"color":"slateblue", "fontSize": "25px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                    Proyecto Final Crea tu web "Tarjeta Digital"
                                    
                                    </p>
                                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "18px"}}>
                                    <span style={{"color":"slateblue", "fontSize": "25px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                    Recibe una beca con un descuento exclusivo.
                                    </p>
                                    </>
                                </div>


                                <div className="col-12 col-md-6" style={{marginBottom: "10px"}}>
                           
                                    <div style={{position: "relative", textAlign: "center", padding: "5px", background: "linear-gradient(to right, #0f2027, #203a43, #2c5364)", borderRadius: "20px"}}>
                         

                                    <form onSubmit={this.onSubmit} className="px-1" style={{paddingTop: "25px", paddingBottom: "25px" }}>
           
                            <span  style={{color: "white", marginTop: "1px", marginBottom: "5px", padding: "5px 7px", border: "1px solid white", display: "inline-block", borderRadius: "10px", fontSize: "18px"}}>
                                    Tendrá una duración de 2 horas
                                    </span>
 <label class="col-12 text-left font-weight-bold" htmlFor="nameDojo">
                            <span style={{fontSize: "16px", color: "white"}}>
                            Nombre:
                            </span>
                            <input
                            id='nameDojo'
                            style={{background: "white", marginBottom: "15px"}}
                            type="text"
                            name="name"
                            value={this.state.Name}
                            onChange={(e) => this.setState({ Name: e.target.value})}
                            placeholder= {"👋 Hola! ¿Cuál es tu nombre?"}
                            />
                        </label>

                            <label className="col-12 text-left font-weight-bold" htmlFor="item02">
                            <span style={{fontSize: "16px", color: "white"}}>
                            Email:
                            </span>
                                <input
                                    style={{background: "white"}}
                                    type="email"
                                    name="email"
                                    id="item02"
                                    value={this.state.Email}
                                    onChange={(e)=>{this.setState({Email: e.target.value});}}
                                    placeholder="📧 ¿Cuál es tu Email?"
                                    />
                            </label>

                       
<div className="row mt-4 text-left">
          <div className="col-12 mx-4" style={{color: "black", fontSize: "16px", textAlign: "left", fontStyle: "italic"}}>
          <label htmlFor="checkNotify">
              <span>Evento Online de Acceso Libre</span>
          </label>
          </div>
      </div>

                            <button
                            className="rn-button-style--2 btn-solid btn_evento"
                            type="submit" name="submit"  style={{fontSize: "18px", display: "block", margin: "auto"}}>

                            {/* {!this.state.loadingEnroll  &&
                                <b><SiZoom style={{fontSize:"30px", marginRight: "5px"}} />Regístrate</b>} */}

{!this.state.loadingEnroll  && <b>Registrarse <AiOutlineRight style={{marginLeft: "6px", marginBottom: "3px"}} /></b> }

                                {this.state.loadingEnroll  &&
                                                <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                style={{fontSize: "20px", marginRight: "8px"}}
                                                />
                                }

                            </button>

                     


                            </form>
                                    </div>
                                    
                            

                        </div>

                                </div>
                        </div>
                        </div>


          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.isOpenPlanPay}
          size="md"
          onHide={() => this.setState({ isOpenPlanPay: false })}
          style={{ zIndex: "9999999999" }}
        >
          <Modal.Body>
            <span
              style={{
                left: "-5px",
                position: "absolute",
                textAlign: "left",
                color: "slateblue",
                zIndex: "999999999",
              }}
            >
              {" "}
              <IoIosCloseCircleOutline
                onClick={() => this.setState({ isOpenPlanPay: false })}
                style={{
                  cursor: "pointer",
                  fontSize: "45px",
                  marginLeft: "12px",
                  color: "slateblue",
                }}
              />{" "}
            </span>

            <div className="container">
              <div
                className="row"
                style={{
                  justifyContent: "center",
                  textAlign: "-webkit-center",
                }}
              >
                {/* Start PRicing Table Area  */}
                <div
                  className="col-12 mt-1"
                  style={{
                    paddingBottom: "13px",
                    paddingRight: "15px",
                    paddingLeft: "15px",
                    maxWidth: "470px",
                    marginRight: window.type_device ? "auto" : "1px",
                  }}
                >
                  {/* <img
                    src="/assets/images/dojopy/black-friday.webp"
                    height={"80px"}
                    style={{
                      position: "relative",
                      bottom: "-25px",
                      zIndex: "99",
                    }}
                  /> */}

                  <div
                    className="rn-pricing"
                    style={{
                      boxShadow: "rgba(140, 82, 255, 0.9) 4px 4px 15px 3px",
                      border: "none",
                      background:
                        "linear-gradient(135deg, #C56CD6 0%,#3425AF 100%)",
                      borderRadius: "20px",
                    }}
                  >
                    <div className="pricing-table-inner">
                      <div className="pricing-header pb-0 mb-2 mt-1">
                        <div className="mb-3">
                          <FlipCountdown
                            size={"small"}
                            yearTitle="Year"
                            dayTitle="Días"
                            hourTitle="Horas"
                            minuteTitle="Min"
                            secondTitle="Segs"
                            monthTitle="Mes"
                            hideYear
                            hideMonth
                            endAt={this.state.fechaLimite} // Date/Time
                          />
                        </div>

                        <span
                          style={{
                            padding: "6px 10px",
                            margin: "5px",
                            "background-color": "tomato",
                            "border-radius": "7px",
                            color: "white",
                            "font-size": "20px",
                            fontWeight: "bold",
                          }}
                        >
                          {this.state.DescuentoBody}
                        </span>

                        <h4 className="text-white mt-2 mb-1 mx-1">
                          {this.state.titlePricePre}
                        </h4>

                        <h3 className="text-white mt-1 mb-0">Monto Total
                    
                        </h3>

                        <span
                          style={{ color: "white", display: "inline-block" }}
                        >
                          <del style={{ color: "lightgray", fontSize: "26px" }}>
                            &nbsp; {this.state.price_completo_regular} &nbsp;
                          </del>
                        </span>

                        <h3
                          className="pricing"
                          style={{ marginBottom: "15px" }}
                        >
                          {this.renderPrice(this.state.price_completo)}
                        </h3>

                        <h4 style={{ margin: "5px", color: "white" }}>
                          {this.state.title_partes} {this.state.price_mensual}
                        </h4>

                        <div
                          className="slide-btn"
                          style={{ marginTop: "15px", textAlign: "center" }}
                        >
                          <a
                            href={`/inscripcion/${this.state.bootcampSlug}/${this.state.countryCode}`}
                          >
                            <button
                              onClick={this.TrackEventConversion}
                              id="enroll_bootcamp_2"
                              style={{
                                lineHeight: "28px",
                                fontSize: "20px",
                                marginBottom: "13px",
                                padding: "15px 30px",
                              }}
                              className=" btn-efect"
                            >
                              <GoRocket
                                style={{
                                  fontSize: "20px",
                                  marginRight: "10px",
                                }}
                              />
                              Empezar inscripción
                            </button>
                          </a>
                        </div>

                        <div className="col-12 text-center mt-2">
                          <button
                            onClick={() =>
                              this.setState({
                                isOpenResume: !this.state.isOpenResume,
                              })
                            }
                            name="submit"
                            id="mc-embedded-subscribe-"
                            style={{
                              color: "white",
                              marginTop: "5px",
                              marginBottom: "5px",
                              fontSize: "22px",
                              "border-radius": "20px",
                              border: "2px solid white",
                              padding: "9px 20px",
                            }}
                          >
                            Ver detalles
                            <AiOutlineRight
                              style={{ padding: "5px", fontSize: "30px" }}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End PRicing Table Area  */}
              </div>
            </div>
          </Modal.Body>
        </Modal>



        <Modal show={this.state.isOpen2} size="md" onHide={this.openModal2} style={{zIndex: "9999999999"}} >
                <span style={{textAlign: "left", "color":"slateblue"}}> <IoCloseCircleOutline onClick={() => this.openModal2()} style={{ cursor: "pointer", fontSize: "35px", marginLeft: "12px", marginTop: "12px", color: "slateblue"}} /> </span>

                    <Modal.Body>
                    <div className="about-inner inner">
                        <div className="section-title">
                            <p>
No te preocupes. Nuestros programas son aptos para principiantes y no requieren ningún conocimiento previo ni hay limite de edad.
Además, no estarás solo. Nuestro equipo estará a tu lado para guiarte en el proceso, ayudarte a establecer objetivos y a tener éxito.
                            </p>
                            <p>
                                <b>*Instalar la herramienta de transmisión en vivo:</b>
                                <img alt="canal zoom" height="95px" src="/assets/images/dojopy/zoom_.webp"></img>
                            </p>
                    </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{"justify-content": "center"}}>
                    <button className="rn-btn" onClick={this.openModal2}>{!this.state.langUS ? "Cerrar": "Close"}</button>
                    </Modal.Footer>
                </Modal>



        <Modal show={this.state.isOpenResume} size="md" onHide={() => this.setState({isOpenResume: false})} style={{zIndex: "9999999999"}} >
                    <span style={{textAlign: "left", "color":"slateblue"}}>
                         <IoCloseCircleOutline onClick={() => this.setState({isOpenResume: false}) }
                         style={{ cursor: "pointer", fontSize: "45px", marginLeft: "12px", marginTop: "12px", color: "slateblue"}} /> </span>

                    <Modal.Body>
                    <div className="">
                        <div className="section-title">
                            <h3 className="title"></h3>

                            <div className="pricing-body mb-3">

                                      <ul className="list-style--1" style={{marginTop: "5px", color: 'black'}}>

                                        <div className="row">
                                            <div className="col-1">
                                                <span style={{"color":"slateblue", "fontSize": "22px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "4px", color: "slateblue"}} /> </span>
                                            </div>
                                            <div className="col-10 pl-2">
                                            <p style={{fontSize: "16px", "margin-bottom": "5px", fontWeight: "bold"}}>
                                                <b style={{color: "black"}}> Te ayudamos a despegar y potenciar tus habilidades, esto puede ahorrarle años valiosos de su tiempo.</b> <br/>
                                            </p>
                                            </div>
                                            </div>

                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Aprende 100% online en vivo. <br/>
                                          </p>

                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          {!this.state.langUS ? "5 meses": "5 months"} (Part-time)<br/>
                                          </p>


                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          5 horas en Vivo y 2 horas de actividades asíncronas, cada semana. <br/>
                                          </p>

                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                           Seguimiento y mentorías. <br/>
                                          </p>


                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Prácticas y Código colaborativo en tiempo real en cada sesión.<br/></p>


                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Aprende con proyectos Full Stack reales <br/> (Tienda Online, Web de pedidos)
                                          </p>


                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Las sesiones en vivo quedan grabadas, acceso a la plataforma sin límite de tiempo, para seguir practicando y aprendiendo. <br/></p>

                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Certificación<br/></p>

                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Una comunidad de apoyo profesional de por vida.<br/></p>


                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Portafolio de proyectos y carta de presentación optimizada 👌.
                                          <br/></p>


                                      </ul>
                                      </div>
                                      <div className="mb-1 text-center">
                                        <button style={{fontSize: "18px"}} className="rn-button-style--2 btn-solid" onClick={() => this.setState({isOpenResume: false})}>{!this.state.langUS ? "Cerrar": "Close"}</button>
                                      </div>
                    </div>


                    </div>


                    </Modal.Body>
               
                </Modal>



      </React.Fragment>
    );
  }

  async componentDidMount() {

    const lang = window.location.pathname;

    const pathdata = window.location.href;

    const currentUrl = window.location.href;
    const keyword = "webinar";
    let registerRequerid = false;
    if (currentUrl.includes(keyword)) {
      registerRequerid = true;
    }

    const demoId = "evento";

    window.urlWasi = this.GetLinkWS();

    const respo = await axios.get(
      "https://edxcwnqqclvq7dccamuytezonu0hqrod.lambda-url.us-east-1.on.aws/"
    );
    const responseDemo = respo.data;


    this.setState({
      registerRequerid: registerRequerid,
      // demoTitle: responseDemo[demoId]["title"],
      demoUrl: responseDemo[demoId]["url"],
      evento_1: responseDemo[demoId]["evento_1"],
      evento_2: responseDemo[demoId]["evento_2"],
      demo_alt: responseDemo[demoId]["demo_alt"],
      body_intro: responseDemo[demoId]["body_intro"],
    });
    // const ImagePreview = responseDemo[demoId]["image_preview"];
    // document.querySelector("#video-play > video").poster = ImagePreview;
    // document.querySelector("#video-play > video").muted = true;


    if (window.location.pathname.includes("clase-1")){
      this.setState({demoUrl: responseDemo[demoId]["evento_1"]});
    } else if (window.location.pathname.includes("clase-2")){
      this.setState({demoUrl: responseDemo[demoId]["evento_2"]});
    } else if (window.location.pathname.includes("clase-3")){
      this.setState({demoUrl: responseDemo[demoId]["evento_3"]});
    }

    axios
      .get(
        "https://hiuaik2gocmwdeh2ub6lqz3hxq0ycvbe.lambda-url.us-east-1.on.aws/"
      )
      .then((response) => {
        const dataApi = response.data;

        this.setState({pricingAPI: dataApi});

        // document.getElementById("whatsapp").style.bottom = "30px";
        // document.getElementById('iframe_chat').src = 'https://ai.dojopy.com/?config=dojopy-asesor&agente=1&corpus_id=dojopy_data.txt';
        // document.getElementById('chat-ai').style.display='block';


        if (!lang.includes('/es')){
          axios.get("https://ipapi.co/json/").then(data => {
            if (!data.data.country_code) throw "api error";
    
            this.setState({ countryCode: data.data.country_code });
            this.DynamicCountry(data.data.country_code);
          }).catch(error => {
            axios
            .get(
              "https://bdzpehnjfmdq56bcrp6gb4mcru0nxqci.lambda-url.us-east-1.on.aws/"
            )
            .then((response) => {
              let data = response.data;
              this.setState({ countryCode: data.country_code });
              this.DynamicCountry(data.country_code);
            })
            .catch((error) => {
              console.log("error api", error);
            });
          })
        }


      })
      .catch((error) => {
        console.log(error);
      });



    this.sleep(60*1000*30).then(r => {
      this.setState({ showPrice: true });
      document.getElementById("whatsapp").style.display = "block";
    })



    const iframe = document.getElementById('iframe-video');
    const estilo = window.getComputedStyle(iframe);
    const anchoExacto = parseInt(estilo.width, 10)

    const windowWidth = window.type_device ? window.innerWidth : anchoExacto;
    // Calcular el alto basado en la proporción 16:9
    const aspectRatio = 16 / 9;
    const height = windowWidth / aspectRatio;
    iframe.style.height = height  + 'px';



  }
}

export default WebinarLive;
