// React Required
import React, { Component, render } from 'react';
import ReactDOM from "react-dom";
// import loadable from '@loadable/component'

// Create Import File
import './index.scss';

// Common Layout
// import Layout from "./component/common/App";

// import BootcampFullStackLive from './home/BootcampFullStackLive';
// import BootcampFullStackLiveWebApp from './home/BootcampFullStackLiveWebApp';
// import BootcampFrontend from './home/BootcampFrontend';

// Element Layout
import Blog from "./elements/Blog";
import BlogDetalle from "./elements/BlogDetalle";
// import ServiceDetails from "./elements/ServiceDetails";
import LandingTestimonial from "./elements/LandingTestimonial";
import LandingAWS from "./home/LandingAWS";
import PolicePrivacy from "./elements/PolicePrivacy";
import Inscripcion from "./elements/Inscripcion";
import CertifcateDojopy from "./elements/CertifcateDojopy";
import VisorTemario from "./elements/VisorTemario";
import PoliceReembolso from "./elements/PoliceReembolso";
import WebinarLive from "./elements/WebinarLive";
// import Vsl from "./elements/Vsl";
import VslDefault from "./elements/VslDefault";
// import VslDefaultSell from "./elements/VslDefaultSell";
import error404 from "./elements/error404";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import BootcampBackend from './home/BootcampBackend';
import Funnel4 from './home/funnels/Funnel4';
import FunnelNews from './home/funnels/FunnelNews';
// import FunnelAWS from './home/funnels/FunnelAWS';
// import BootcampFlex from './home/BootcampFlex';
import FunnelOne from './home/funnels/FunnelOne';
import FunnelTwo from './home/funnels/FunnelTwo';
import FunnelStart from './home/funnels/FunnelStart';
import BootcampFullStackLiveEmpleo from './home/BootcampFullStackLiveEmpleo';
import BootcampFullStackLiveFlex from './home/BootcampFullStackLiveFlex';

serviceWorker.unregister();

// const BootcampFullStackLive = loadable(() => import('./home/BootcampFullStackLive'));


class Root extends Component {

    render(){

        return(
            <HelmetProvider>
            <BrowserRouter basename={'/'}>
                <Switch>
                <Route exact path={`${process.env.PUBLIC_URL}/`} component={BootcampFullStackLiveEmpleo}/>
                <Route exact path={`${process.env.PUBLIC_URL}/beca`} component={BootcampFullStackLiveEmpleo}/>
                <Route exact path={`${process.env.PUBLIC_URL}/evento`} component={FunnelNews}/>
                <Route exact path={`${process.env.PUBLIC_URL}/evento-online`} component={FunnelNews}/>
                <Route exact path={`${process.env.PUBLIC_URL}/zoom`} component={FunnelNews}/>
                <Route exact path={`${process.env.PUBLIC_URL}/zoom-en-vivo`} component={FunnelNews}/>
                {/* <Route exact path={`${process.env.PUBLIC_URL}/registro`} component={FunnelStart}/> */}
                {/* <Route exact path={`${process.env.PUBLIC_URL}/empezar`} component={Funnel4}/> */}
                {/* <Route exact path={`${process.env.PUBLIC_URL}/funnel`} component={Funnel4}/> */}
                {/* <Route exact path={`${process.env.PUBLIC_URL}/flex`} component={BootcampFullStackLiveFlex}/> */}
                    <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={Blog}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/blog/:slug`} component={BlogDetalle}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/bootcamp-aws`} component={LandingAWS}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/bootcamp-backend`} component={BootcampBackend}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/testimonios`} component={LandingTestimonial}/>
                    <Route path={`${process.env.PUBLIC_URL}/inscripcion/:bootcamp/:country`} component={Inscripcion}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/politicas-privacidad`} component={PolicePrivacy}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/politicas-reembolso`} component={PoliceReembolso}/>
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/flex`} component={BootcampFullStackLiveFlex}/> */}
                    <Route exact path={`${process.env.PUBLIC_URL}/clase-1`} component={WebinarLive}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/clase-2`} component={WebinarLive}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/clase-3`} component={WebinarLive}/>
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/documental`} component={WebinarLive}/> */}
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/webinar`} component={Funnel5}/> */}
                    <Route exact path={`${process.env.PUBLIC_URL}/@:user`} component={CertifcateDojopy}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/temario`} component={VisorTemario}/>
                    <Route component={error404}/>
                </Switch>
            </BrowserRouter>
            </HelmetProvider>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
