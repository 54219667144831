import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Header from "../component/header/Header";
import FooterTwoFunnel from "../component/footer/FooterTwoFunnel";
import axios from "axios";
import Selectrix from "react-selectrix";
import Modal from "react-bootstrap/Modal";
import { FaGraduationCap, FaWhatsapp, FaPhone, FaRunning, FaEye, FaCreditCard, FaStar } from "react-icons/fa";
import { MdWork, MdEmail, MdPayments, MdModeNight, MdOutlineZoomOutMap } from "react-icons/md";
import { IoCloseCircleOutline } from "react-icons/io5";
import {
  AiOutlineDown,
  AiOutlineRight,
  AiFillPlayCircle,
  AiFillPauseCircle,
} from "react-icons/ai";
import "react-toastify/dist/ReactToastify.css";
import ReactCountryFlag from "react-country-flag";
// import ReactPlayer from "react-player";
import FlipCountdown from "@rumess/react-flip-countdown";
import { GoRocket } from "react-icons/go";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast } from 'react-toastify';
import { CiUser } from "react-icons/ci";
import { IoIosCloseCircleOutline } from "react-icons/io";
// import ProgressBar from 'react-bootstrap/ProgressBar';
import Typical from 'react-typical';
import { FiCheck, FiClock, FiUser, FiMonitor, FiRadio, FiDownload, FiVideo, FiCheckCircle, FiPackage} from "react-icons/fi";
import { BsCircle, BsArrowLeftCircle, BsSun, BsFillPlayCircleFill, BsCloudSunFill } from "react-icons/bs";
import cx from "classnames";
import NonPassiveTouchTarget from "../home/NonPassiveTouchTarget";
import TouchCarousel, { clamp } from "react-touch-carousel";
import touchWithMouseHOC from "react-touch-carousel/lib/touchWithMouseHOC";
import { FaCalendarAlt } from "react-icons/fa";




const data2 = [
  {
    title: "¡Muy bien!",
    body: "Gracias al bootcamp Full Stack Web, conseguí un puesto en una destacada empresa tecnológica. Mis ingresos han crecido hasta $2000 dólares al mes y estoy entusiasmado por todo lo que está por venir ¡Este es solo el inicio de mi carrera!",
    faceImg: "/assets/images/team/faces/face_2.webp",
    name: "Carlos P.",
  },
  {
    title: "¡Definitivamente recomiendo!",
    body: "Después de completar el bootcamp de Full Stack Web, gané 1600 dólares por primera vez. El material didáctico era tan práctico que pude aplicarlo de inmediato a mis proyectos y ver resultados tangibles ¡Fue una experiencia transformadora!",
    faceImg: "/assets/images/team/faces/face_3.webp",
    name: "Bladimir S.",
  },
  {
    title: "¡Me encanta!",
    body: "Decidí inscribirme en el bootcamp Full Stack Web y fue la mejor decisión de mi vida. Ahora tengo la oportunidad de trabajar con importantes empresas y mis ingresos han mejorado significativamente ¡Gracias Dojofullstack, por brindarme una educación de calidad!",
    faceImg: "/assets/images/team/faces/face_4.webp",
    name: "Victor L.",
  },
  {
    title: "¡Muy bien!",
    body: "El conocimiento y buenas prácticas que dan a conocer los instructores a través de la experiencia, ¡me encantó 100% recomendado!",
    faceImg: "/assets/images/team/faces/face_1.webp",
    name: "Luciana R.",
  },
];


const KeyCountry =  [
  {key: "PE", label: "Perú", countryNumber: "+51"},
  {key: "MX", label: "México", countryNumber: "+52"},
  {key: "EC", label: "Ecuador", countryNumber: "+593"},
  {key: "CR", label: "Costa Rica", countryNumber: "+506"},
  {key: "SV", label: "El Salvador", countryNumber: "+503"},
  {key: "BO", label: "Bolivia", countryNumber: "+591"},
  {key: "PR", label: "Puerto Rico", countryNumber: "+1"},
  {key: "HN", label: "Honduras", countryNumber: "+504"},
  {key: "UY", label: "Uruguay", countryNumber: "+598"},
  {key: "PY", label: "Paraguay", countryNumber: "+595"},
  {key: "GT", label: "Guatemala", countryNumber: "+502"},
  {key: "PA", label: "Panamá", countryNumber: "+507"},
  {key: "NI", label: "Nicaragua", countryNumber: "+505"},
  {key: "DO", label: "República Dominicana", countryNumber: "+1"},
  {key: "BR", label: "Brasil", countryNumber: "+55"},
  {key: "US", label: "Estados Unidos", countryNumber: "+1"}
]


const VIDEO_MOBILE = "https://media.dojofullstack.com/bootcamp/beca-intro-vls-beta-v-7.mp4";
const VIDEO_DESKTOP = "https://media.dojofullstack.com/bootcamp/beca-intro-vls-beta-v-7.mp4";

const VIDEO_VLS = window.type_device ? VIDEO_MOBILE: VIDEO_DESKTOP;

const VIDEO_POSTER = window.type_device ? "https://media.dojofullstack.com/bootcamp/ads/vlcsnap-2024-10-30-16h36m12s314.png": "https://media.dojofullstack.com/bootcamp/ads/vlcsnap-2024-10-30-16h36m12s314.png";

const carouselWidth = clamp(window.innerWidth, 0, 960);


function CarouselContainer2(props) {
  const {
    cursor,
    carouselState: { active, dragging },
    ...rest
  } = props;
  let current = -Math.round(cursor) % data2.length;
  while (current < 0) {
    current += data2.length;
  }
  // Put current card at center
  const translateX =
    (cursor - cardPadCount) * 385 + (carouselWidth - 385) / 2;
  return (
    <NonPassiveTouchTarget
      className={cx("carousel-container", {
        "is-active": active,
        "is-dragging": dragging,
      })}
      style={{height: "500px"}}
    >
      <NonPassiveTouchTarget
        className="carousel-track"
        style={{ transform: `translate3d(${translateX}px, 0, 0)` }}
        {...rest}
      />

      <div className="carousel-pagination-wrapper">
        <ol className="carousel-pagination">
          {data2.map((_, index) => (
            <li key={index} className={current === index ? "current" : ""} />
          ))}
        </ol>
      </div>
    </NonPassiveTouchTarget>
  );
}


const Container2 = touchWithMouseHOC(CarouselContainer2);

const cardSize2 = 350;
const cardPadCount = 2;
// const carouselWidth = clamp(window.innerWidth, 0, 960);



// Componente funcional memoizado
// const TypingAnimation = () => {
//   return (
//       <Typical
//           steps={['Reinvéntate Profesionalmente en Desarrollo Web Full Stack']}
//           wrapper="h3"
//           loop={false}
//           />
//   );
// };

// const MemoizedTypingAnimation = React.memo(TypingAnimation, (props,prevProp)=> true);

const pathname = window.location.pathname;



class VslDefault extends Component {
  constructor() {
    super();

    const rango = Array.from({ length: 7 }, (_, index) => index + 10);
    this.state = {
      pricingAPI: {},
      bootcampSlug: 'fullstack-descuento',
      bootcampSlugMensual: "fullstack-descuento",
      Temario: "",
      Programa: "",
      isOpen: false,
      countryCode: "PE",
      Email: "",
      Phone: "",
      Name: "",
      isOpenTemario: false,
      isPdfPrice: false,
      langUS: false,
      isPlaying: false,
      isOpenPlanPay: false,
      price_mensual_regular: "",
      price_completo_regular: "",
      price_completo: "",
      price_mensual: "",
      titlePricePre: "Antes del viernes 17 de noviembre",
      demoTitle: "",
      demoUrl: "",
      demoPreview: "",
      fechaLimite: "",
      DescuentoBody: "",
      visitasNumber: this.getRandomVisitasNumber(),
      videoRef: React.createRef(),
      isOpenLead: false,
      loadingEnroll: false,
      demo_alt: false,
      body_intro: false,
      select_mes: "",
      DataFechas: [],
      select_turno: "",
      isOpenResume: false,
      Turnos: [
        {key: "noche", label: "Noche"},
        {key: "tarde", label: "Tarde"},
    ],
      durationVideo: 0,
      durationPercen: 0,
      registerRequerid: false,
      videoInit: false,
      mount: "",
      isOpen2: false,
      title_partes: 'o en 5 pagos de',
      planPagoUSD: "",
      planPagoPE: "",
      isOpenPlanPay: false,
      DescuentoBody: "",
      price_mount: "",
      selectFecha: false,
      DescuentoBodyUSD: "",
      DescuentoBodyPEN: "",
      isOpenReunion: false,
      cuotaDolar: '',
      cuotaSoles: '',
      isOpenModalInfo: false,
      isModalWS: false,
      showMore: false,
      showTestimonial: false,
      showButton: false,
      sendEventContent: false,
      showForm: true
    };
    this.GetLinkWS = this.GetLinkWS.bind(this);
    this.openModalEmail = this.openModalEmail.bind(this);
    this.onRenderOption = this.onRenderOption.bind(this);
    this.DownloadPdf = this.DownloadPdf.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.DynamicCountry = this.DynamicCountry.bind(this);
    this.openModal2 = this.openModal2.bind(this);


  }

  openModal2 () {
    this.setState({isOpen2: !this.state.isOpen2});
  }






  
  onSubmit(e) {
    e.preventDefault();

    if (this.state.loadingEnroll){
      return;
    }
    
    if(this.state.Phone.trim() === "" || this.state.Phone.trim().length < 5  ){
        toast.dark('¡Ups! 😅 El campo WhatsApp es necesario para continuar.');
        return;
    }

    
    // if(this.state.Email.trim() === "" || this.state.Email.trim().length < 5 || !this.state.Email.trim().includes("@") ){
    //   toast.dark('¡Ups! 😅 El campo Email es necesario para continuar.');
    //   return;
    // }


    window.fbq("track", "Contact");


    this.setState({loadingEnroll: true, sendDataForm: true});


    if (this.state.Name.trim()){
        localStorage.setItem('name', this.state.Name.trim());
    }

    if (this.state.Email.trim()){
      localStorage.setItem('email', this.state.Email.trim());
    }

    const source = window.location.search.split('source=')[1] || 'home';

     const timer = setTimeout(() => {
          const link = this.GetLinkWS();
          window.open(link, '_self').focus();
    }, 3000); // 3 segundos


    axios.post('https://cprij4toee.execute-api.us-east-1.amazonaws.com/dev',
    {evento: false, lead: true, bootcamp: 'Web full stack', email: this.state.Email, name: this.state.Name, country: this.state.countryCode,
        Token: "c3b4b89c",
        landing: pathname,
        source: source,
        phone: this.state.Phone
},)
    .then(res => {

        // const body = this.GetLinkWS();
        // window.open(body, '_blank').focus();
        // toast.success(`¡Hola ${this.state.Name}! 🎉 Gracias. Hemos recibido tu solicitud y nuestro equipo la revisará pronto. Si tienes alguna pregunta, no dudes en ponerte en contacto con nosotros. ¡Estamos aquí para ayudarte!`);

        this.setState({isModalWS: false, isOpenTemario: false, isOpenModalInfo : false, selectFecha: false, loadingEnroll: false});


        // window.fbq("track", "Contact");
        // window.gtagSendEvent();
        // this.setState({isModalWS: true})
        // const timer = setTimeout(() => {
        //     const link = this.GetLinkWS();
        //     window.open(link, '_blank').focus();
        // }, 5000); // 3 segundos



    })
}

  getRandomVisitasNumber() {
    const rango = Array.from({ length: 7 }, (_, index) => index + 10);
    return rango[Math.floor(Math.random() * rango.length)];
  }

  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };


  

  async DynamicCountry (country='' ){

    window.urlWasi = this.GetLinkWS();

    const newCountry = country || this.state.countryCode;

    if (country){
        this.setState({countryCode: country});

      const response = await axios.get(
        "https://hiuaik2gocmwdeh2ub6lqz3hxq0ycvbe.lambda-url.us-east-1.on.aws/"
      );

      const pricingAPI = response.data;
      let urlVid;

      const imagePoster = pricingAPI[this.state.bootcampSlug]["imagePoster"];

      if (window.type_device){
        urlVid = pricingAPI[this.state.bootcampSlug]["vls_default"];
      } else {
        urlVid = pricingAPI[this.state.bootcampSlug]["vls_default"];
      }

      this.setState({
        demoUrl: urlVid,
        select_mes:  pricingAPI[this.state.bootcampSlug]['fechas'][0]['mes'],
        isPricePais: false,
        utc_offset: '-0500',
        utc_dif: 0,
        timestamp24h: pricingAPI[this.state.bootcampSlug]['timestamp24h'],
        semibeca_img: pricingAPI[this.state.bootcampSlug]['semibeca_img'],
        DataFechas: pricingAPI[this.state.bootcampSlug]['fechas'],
        mesesInicio: pricingAPI[this.state.bootcampSlug]['mesesInicio'],
        Turnos: pricingAPI[this.state.bootcampSlug]['turnos'],
        fechaLimite: pricingAPI[this.state.bootcampSlug]['fechaLimite'],
        isPriceEspecial: pricingAPI[this.state.bootcampSlug]['isPriceEspecial'],
        PriceEspecialBody: pricingAPI[this.state.bootcampSlug]['PriceEspecialBody'],
        PriceEspecialPreBody: pricingAPI[this.state.bootcampSlug]['PriceEspecialBody'],
        timePrueba: pricingAPI[this.state.bootcampSlug]['time_prueba'],
        FooterColor: pricingAPI[this.state.bootcampSlug]['FooterColor'],
        price_completo: pricingAPI[this.state.bootcampSlug]['price']['usd']['price_view_base'], //495
        price_completo_regular: pricingAPI[this.state.bootcampSlug]['price']['usd']['price_view_base_regular'],
        price_mensual: pricingAPI[this.state.bootcampSlugMensual]['price']['usd']['price_view_final'],
        price_mensual_regular: pricingAPI[this.state.bootcampSlugMensual]['price']['usd']['price_view_base_regular'],
        titlePrice: pricingAPI[this.state.bootcampSlug]['titlePrice'],
        titlePricePre: pricingAPI[this.state.bootcampSlug]['titlePrice'],
        price_payment: "1",
        salario_fullstack_jr: "US$ 14,000 ANUAL",
        salario_fullstack_md: "US$ 24,000 ANUAL",
        salario_fullstack_sr: "+US$ 40,000 ANUAL",
        planPagoUSD: pricingAPI[this.state.bootcampSlug]['planPagoUSD'],
        planPagoPE: pricingAPI[this.state.bootcampSlug]['planPagoPE'],
        DescuentoBody: pricingAPI[this.state.bootcampSlug]['DescuentoBody'],
        price_mount: pricingAPI[this.state.bootcampSlug]['price']['usd']['mount'],
        DescuentoBodyUSD: pricingAPI[this.state.bootcampSlug]['DescuentoBodyUSD'],
        DescuentoBodyPEN: pricingAPI[this.state.bootcampSlug]['DescuentoBodyPEN'],
        cuotaDolar: pricingAPI[this.state.bootcampSlug]['cuotaDolar'],
        cuotaSoles: pricingAPI[this.state.bootcampSlug]['cuotaSoles'],
    })

    try {
        if (pricingAPI[this.state.bootcampSlug].price[country]){
            this.setState({
                isPricePais: true,
                price_completo: pricingAPI[this.state.bootcampSlug].price[country].price_view_base,
                price_completo_regular: pricingAPI[this.state.bootcampSlug].price[country].price_view_base_regular,
                price_mensual: pricingAPI[this.state.bootcampSlugMensual]['price'][country]['price_view_final'],
                price_mensual_regular: pricingAPI[this.state.bootcampSlugMensual]['price'][country]['price_view_base_regular']
            });
        }
    } catch (error) {
        console.log('Log',error);
    }


    if (newCountry === 'PE') {
        this.setState({
            utc_offset: '-0500',
            salario_fullstack_jr: "S/ 45,000 ANUAL",
            salario_fullstack_md: "S/ 79,00 ANUAL",
            salario_fullstack_sr: "+ S/ 135,000 ANUAL"
        });
    } else if (newCountry === 'MX') {
        this.setState({
            utc_dif: -1,
            utc_offset: '-0600',
            salario_fullstack_jr: "$300,000 MXN ANUAL",
            salario_fullstack_md: "$450,000 MXN ANUAL",
            salario_fullstack_sr: "+ $800,000 MXN ANUAL"
            });
    } else if (newCountry === 'CR'){
        this.setState({utc_dif: -1, utc_offset: '-0600'});
    } else if (newCountry === 'CL'){
        this.setState({utc_dif: -1, utc_offset: '-0400'});
    } else if (newCountry === 'BO'){
        this.setState({
            utc_dif: -1,
            utc_offset: '-0400',
            });
    } else if (newCountry === 'CU'){
        this.setState({utc_dif: +1});
    } else if (newCountry === 'SV'){
        this.setState({utc_dif: -1, utc_offset: '-0600'});
    } else if (newCountry === 'GT'){
        this.setState({utc_dif: -1});
    } else if (newCountry === 'HN'){
        this.setState({utc_dif: -1, utc_offset: '-0600'});
    } else if (newCountry === 'NI'){
        this.setState({utc_dif: -1});
    } else if (newCountry === 'PY'){
        this.setState({utc_dif: +1, utc_offset: '-0300'});
    } else if (newCountry === 'PR'){
        this.setState({utc_dif: +1, utc_offset: '-0300'});
    } else if (newCountry === 'DO'){
        this.setState({utc_dif: +1});
    } else if (newCountry === 'UY'){
        this.setState({
            utc_dif: +2,
            utc_offset: '-0300'
        });
    } else if (newCountry === 'BR'){
        this.setState({
            utc_dif: +2,
            utc_offset: '-0300'
        });
    }


    this.sleep(250).then(r => {
      try {
        document.querySelector("#video-play > video").poster = imagePoster;

        document.querySelector("#video-play > video").muted = true;
        document.querySelector("#video-play > video").play();

      } catch (error) {
      }
    })

    // this.sleep(5000).then(r => {
    //   this.setState({
    //     showTestimonial: true
    //   });
    // })

    // this.sleep(13000).then(r => {
    //   this.setState({
    //     showMore: true
    //   });
    // })


    }
    

}


  renderPrice(price, prueba = false) {
    return (
      <>
        <div className="row" style={{ placeContent: "center" }}>
          <div className="col-12">
            <p
              style={{
                fontSize: "45px",
                color: prueba ? "black" : "white",
                fontWeight: "bold",
                paddingTop: "9px",
                paddingBottom: "9px",
              }}
            >
              {price}
              <span
                style={{
                  display: "inline-block",
                  fontSize: window.type_device ? "20px" : "25px",
                  marginLeft: "5px",
                }}
              >
                {" "}
              </span>
            </p>
          </div>
        </div>
      </>
    );
  }

  
    
  onRenderOption(option, complete) {
    return (
      <li>
        <ReactCountryFlag
          countryCode={option.key}
          svg
          style={{
            width: "2em",
            height: "2em",
            padding: "1px",
          }}
          title="país"
        />{" "}
        {complete ? option.label : option.key}
      </li>
    );
  }


  
  onRenderSelectionPais( selected, settings, deselect ){
    return 	(<span style={{ marginRight: 10, padding: 5 }}>
{ selected ?
        <span style={{"color": "white"}}>
            <ReactCountryFlag
            countryCode={selected.key}
            svg
            style={{
                width: '2.3em',
                height: '2.3em',
                padding: '7px'
            }}
            title="país"
    />  {selected.countryNumber}
        </span>
        :
           <span style={{padding: 5}}>País</span>
    }
    
<i style={{ paddingLeft: 5, cursor: "pointer" }} onClick={ deselect } className="fa fa-window-close"></i>
</span>)
}

onRenderOption(option, complete) {
return (
  <li>
    <ReactCountryFlag
      countryCode={option.key}
      svg
      style={{
        width: "2em",
        height: "2em",
        padding: "1px",
      }}
      title="país"
    />{" "}
    {complete ? option.key : option.key}
  </li>
);
}


  GetLinkWS() {
    if (window.type_device) {
      return `https://api.whatsapp.com/send?phone=51936670597&text=Hola,%20información%20sobre%20el%20Bootcamp%20Full%20Stack%20online%20en%20vivo.`;
    } else {
      return `https://web.whatsapp.com/send?phone=51936670597&text=Hola,%20información%20sobre%20el%20Bootcamp%20Full%20Stack%20online%20en%20vivo.`;
    }
  }

  openModalEmail() {
    this.setState({ Phone: "" });
    this.setState({ isOpenTemario: !this.state.isOpenTemario });
  }

  DownloadPdf(e) {
    e.preventDefault();

    if (this.state.Email === "") {
      toast.warning("El campo Email es requerido.");
      return;
    }

    localStorage.setItem("email", this.state.Email);

    toast.success(
      !this.state.langUS
        ? "Hola!, enviamos el programa por Email, gracias!"
        : "Hello! We sent the program by Email, thank you!"
    );

    this.openModalEmail();

    axios
      .post("https://cprij4toee.execute-api.us-east-1.amazonaws.com/dev", {
        lead: true,
        evento: true,
        bootcamp: this.state.slugBootcamp,
        email: this.state.Email,
        name: this.state.Name,
        country: this.state.countryCode,
        Token: "c3b4b89c",
      })
      .then((res) => {
        window.gtag_report_conversion();
        // window.location.href = `/inscripcion/fullstack/${this.state.countryCode}`;
        // toast.success(`Hola ${this.state.Name}!, tengamos una breve sesión informativa para completar tu inscripción`);
      });
  }


  onRenderOption ( option, complete ) {
    return (
        <li>
        <ReactCountryFlag
            countryCode={option.key}
            svg
            style={{
                width: '1.5em',
                height: '1.5em',
                padding: '1px'
            }}
            title="país"
        />  {option.key }
        </li>
            )
}

onRenderSelection( selected, complete ){
    return 	(<span style={{ padding: 2 }}>
    { selected ?
        <span style={{"color": "black"}}>
            <ReactCountryFlag
            countryCode={selected.key}
            svg
            style={{
                width: '2em',
                height: '2em',
                padding: '2px'
            }}
            title="país"
    />
        </span>
        :
           <span style={{padding: 5}}></span>
    }
</span>)
}

renderCard2(index, modIndex) {
  const item = data2[modIndex];
  return (
      <div
      key={index}
      className="carousel-card mx-3"
      >
      <div style={{width: "350px"}}>
         <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "20px", "padding": "20px", "background": "rebeccapurple"}}>
              
              <div className="d-flex gap-3">
                <div className="mx-1">
                <FaStar className="" style={{paddingRight: "3px"}} />
                <FaStar className="" style={{paddingRight: "3px"}} />
                <FaStar className="" style={{paddingRight: "3px"}} />
                <FaStar className="" style={{paddingRight: "3px"}} />
                <FaStar className="" style={{paddingRight: "3px"}} />
                </div>
                <div className="mx-1">
                  <p className="text-white" style={{fontSize: "14px"}}>
                  5.0 rating
                  </p>
                </div>
         
              </div>

              <h4 className="text-white text-left mt-2 mb-1" style={{fontSize: "17px", fontWeight: "bold"}}>
              {item.title}
              </h4>
              <p className="text-white text-left mt-1" style={{fontSize: "16px", fontWeight: 500}}>
              {item.body}
              </p>

              <div className="d-flex gap-3 mt-2" style={{alignItems: "center", gap: "8px"}}>
                 <img alt={item.name} height="45px" src={item.faceImg} style={{borderRadius: "50%"}} />
                 <h5 className="text-white text-left my-0" >
                   {item.name}
                 </h5>
              </div>
          </div>
      </div>
      </div>
  );
  }


renderPais(complete=false){
    return (
        <>
        <span style={{
            fontSize: "17px",
            fontWeight: "bold",
            position: "absolute",
            left: "15px",
            top: "-25px",
            zIndex: "1",
        }} > Zona horaria </span>
            <Selectrix
        height={300}
        onRenderOption={(option) => this.onRenderOption(option, complete)}
        onRenderSelection={(option) => this.onRenderSelection(option, complete)}
        placeHolderInside={true}
        placeholder={"País"}
        customScrollbar={true}
        searchable={false}
        materialize={false}
        defaultValue={this.state.countryCode}
        options={KeyCountry}
        onChange={value => {
            this.DynamicCountry(value.key)}
        }
    />
        </>

    )
}







renderMes(){
  return (
      <>
      <span style={{
          fontSize: "17px",
          fontWeight: "bold",
          position: "absolute",
          left: "15px",
          top: "-25px",
          zIndex: "1",
      }} > {!this.state.langUS ? "Mes:": "Month:"} </span>
      <Selectrix
      height={250}
      placeHolderInside={true}
      placeholder={!this.state.langUS ? "Todos": "All" }
      customScrollbar={true}
      searchable={false}
      materialize={false}
      defaultValue={this.state.select_mes}
      options={this.state.mesesInicio.map((item) => ({key: item, label: item})) }
      onChange={value => this.setState({select_mes: value.key})}
  />
      </>

  )
}


renderTurno(){
    return (
        <>
        <span style={{
            fontSize: "17px",
            fontWeight: "bold",
            position: "absolute",
            left: "15px",
            top: "-25px",
            zIndex: "1",
        }} >{!this.state.langUS ? "Turno": "Shift"}:</span>
        <Selectrix
        height={250}
        placeHolderInside={true}
        placeholder={!this.state.langUS ? "Todos": "All" }
        customScrollbar={true}
        searchable={false}
        materialize={false}
        defaultValue={this.state.select_turno}
        options={this.state.Turnos}
        onChange={value => this.setState({select_turno: value.key})}
    />
        </>

    )
}

renderHorario(){
  if (this.state.utc_offset === "-0500") {
      return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">8:00 PM a 10:35 PM</p>
  } else if (this.state.utc_offset === "-0600") {
      return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">7:00 PM a 9:35 PM</p>
  } else if (this.state.utc_offset === "-0400"){
      return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">9:00 PM a 11:35 PM</p>
  }  else {
      return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">8:00 PM a 10:35 PM</p>
  }
}

renderHorarioFinSemana(){
if (this.state.utc_offset === "-0500") {
  return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">10:00 AM a 12:35 PM</p>
} else if (this.state.utc_offset === "-0600"){
  return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">09:00 AM a 11:35 AM</p>
} else if (this.state.utc_offset === "-0400"){
  return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">11:00 AM a 13:35 PM</p>
} else if (this.state.utc_offset === "+0100"){
  return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">16:00 PM a 18:35 PM</p>
} else {
  return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">10:00 AM a 12:35 PM</p>
}

}

renderHorarioFinSemanaTarde(){
if (this.state.utc_offset === "-0500") {
  return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">03:00 PM a 05:35 PM</p>
} else if (this.state.utc_offset === "-0600"){
  return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">02:00 PM a 04:35 PM</p>
} else if (this.state.utc_offset === "-0400"){
  return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">04:00 PM a 06:35 PM</p>
} else if (this.state.utc_offset === "+0100"){
  return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">09:00 PM a 11:35 PM</p>
} else {
  return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">03:00 PM a 05:35 PM</p>
}
}



    renderFechaSelect(item, key){
      return (
          <div key={key} className="row row--35 align-items-center rn-address" data-aos="fade-up"  style={{boxShadow: "#c6c9d8 8px 6px 15px 1px"}} >
          <div className="col-12 mb-3">

              <h2 style={{marginBottom: "0px", fontSize: window.type_device && "39px" }}>
                  {item.fecha_inicio}
              </h2>
              <div style={{width: window.type_device ? "200px": "450px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "20px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>

              <div className="row">
                  <div className="col-lg-2 col-md-3 col-12  mt-2">
                  <span style={{padding: "3px", "padding-top": "4px","padding-bottom": "4px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                  <FiVideo/> {!this.state.langUS ? "En vivo": "Live"}
                                  </span>
                  </div>
                  <div className="col-md-4 col-lg-3 col-12  mt-2">
                      <span style={{"padding-top": "4px","padding-bottom": "4px", "font-size": "18px", "color":"white", "background-color": "slateblue", "border-radius": "8px", "padding-left": "8px", "padding-right": "8px"}}>
                        Plazas limitadas
                      </span>
                  </div>
              </div>

          </div>

          <div className="col-md-3 col-12" style={{height: "110px", borderRight: !window.type_device ? "1px solid #121212" : "none" }}>
              <div> <h5 className="mb-2" ><b> {!this.state.langUS ? "Duración": "Duration"} </b></h5> </div>
              <div> <p style={{fontSize: "18px"}}> {!this.state.langUS ? "5 meses": "5 months"}
              <span style={{display: "inline-block"}}></span>
              </p> </div>
              <span className="bg-white m-1" style={{padding: "5px 7px", border: "1px solid mediumpurple", display: "inline-block", borderRadius: "10px", fontSize: "16px"}}>
              <b>{item.fecha_start_end}</b>
              </span>
          </div>

          <div className="col-md-3 col-12" style={{height: "110px", marginTop: window.type_device ? "25px": "none", borderRight: !window.type_device ? "1px solid #121212" : "none" }}>
              <div> <h5 className="mb-2"><b> {!this.state.langUS ? "Días": "Days"} </b></h5> </div>
              <div> <p style={{fontSize: "18px"}}> {item.dias}  </p> </div>
          </div>

          <div className="col-md-3 col-12" style={{height: "110px", marginTop: "none"}}>
          <div> <h5 className="mb-2">
              <b>
              Horario
               {item.turno === 'dia' &&  <> (Mañana) <BsSun style={{height: "35px", width: "35px", color: "goldenrod", marginLeft: "3px", fontSize: "26px"}}/>  </>  }
               {item.turno === 'tarde' && <> (Tarde) <BsCloudSunFill style={{height: "35px", width: "35px", color: "darkblue", marginLeft: "3px", fontSize: "26px"}} /> </>  }
               {item.turno === 'noche' && <> (Noche) <MdModeNight style={{height: "35px", width: "35px", color: "darkblue", marginLeft: "3px", fontSize: "26px"}} /> </>   }
              </b>
              </h5> </div>
              <div className="row">
                  <div className="col-12">
                  {(item.turno == 'noche' || item.turno == 'night') && this.renderHorario() }
                  {item.turno == 'dia'  && this.renderHorarioFinSemana() }
                  {item.turno == 'tarde'  && this.renderHorarioFinSemanaTarde() }
                  </div>

              </div>
          </div>

          <div className="col-md-3 col-12" style={{marginTop: "30px"}}>
              <div>
              <a href={"#inscripcion"}>
              <button
              style={{lineHeight: "20px", fontSize: "18px", marginBottom: "13px", padding: "15px 30px" }}
              className=" btn-efect">
              ELEGIR FECHA
              </button>
              </a>
              </div>
          </div>

      </div>
      )
    }


    renderFechas() {
      return (
          <div>
              {!this.state.select_mes && this.state.DataFechas.map((item, key) => (

                  this.state.select_turno ?
                   this.state.select_turno == item.turno && this.renderFechaSelect(item, key)
                  :
                  this.renderFechaSelect(item, key)

              ))}

              {this.state.select_mes && this.state.DataFechas.map((item, key) => (

                  this.state.select_turno ?
                  this.state.select_turno == item.turno && this.state.select_mes == item.mes && this.renderFechaSelect(item, key)
                 :
                  this.state.select_mes == item.mes && this.renderFechaSelect(item, key)


              ))}
        </div>

      )}






      
  eventPing(name_event) {
    const config = {
      method: 'get',
      url: `https://api.dojofullstack.com/api/v1/crm/event/${name_event}`,
      headers: { 
        'Authorization': '0101010010001'
      }
    };
    axios(config)
      .then(response => {})
      .catch(error => {});
  };



  render() {


    

    return (
      <React.Fragment style={{backgroundColor: "black"}}>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Bootcamp Full Stack Online en vivo" />
        {/* End Pagehelmet  */}

<div className="title-mural" style={{background: "rgb(27, 18, 18)"}}>

        <div  style={{paddingLeft: "1px",  paddingTop: "1px", textAlign: "center", maxWidth: "590px", background: "rgb(27, 18, 18)"}} >
    
    <div className="d-flex">
     <a href={'/unirme'}>
        <img style={{borderRadius: "50%"}} height="40px" className="logo-1" src="/assets/images/logo/dojopy_software.webp" alt="DojoFullStack"/>
    </a>
    <div className="logo_dojopy" style={{color: "white", marginTop: "5px", marginBottom: "5px", fontFamily: "'El Messiri', sans-serif", lineHeight: "19px", "place-self": "center", "margin-left": "5px"}}>

<a href={'/unirme'} style={{color: "white"}}>
<span style={{color: "white", fontFamily: "'El Messiri', sans-serif", fontSize: window.type_device ? "22px": "30px" }}>
  DOJO
</span>
<span style={{color: "white", marginLeft: "1px", fontFamily: "'El Messiri', sans-serif", fontSize: window.type_device ? "10px": "14px", display: "block"}}>
  FULLSTACK
</span>
</a>
</div>
    </div>
    </div>
    </div>

        {/* Start Page Wrapper */}
       <div
          className="rn-service-details ptb--1 bg_color--1"
          style={{ textAlign: "center", background: "#1B1212" }}
        >
          <div className={window.type_device ? "w-100" : "container"}>
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="service-details-inner">
                  <div className="inner">



                    <div className="row justify-content-center">
                    <div className="col-12 text-center text-white px-1" >
                        <h3 className="my-1 mt-3"
                        style={{
                            textAlign: "center",
                          lineHeight: window.type_device ? "30px": "65px"
                        }}
                        >
                        
                        <span
                          className=" title-gradient-funnel"
                           style={{fontFamily: "'Poppins',sans-serif",
                            fontStyle: "italic", fontSize: window.type_device ? "33px !important": "50px" }}
                           >
                          Transforma Tu Carrera en Desarrollo <br/> Web Full Stack
                          </span>
                        </h3>

                        <h5 className="my-3 px-3" style={{color: "mediumspringgreen", fontSize: "20px", fontStyle: "italic"}} >
                        ¿Quieres Reinventarte Profesionalmente en menos de 12 semanas y ganar entre $1,000 a $5,000 USD al Mes?
                        </h5>

                        <h5 style={{fontWeight: "normal", fontStyle: "italic", marginBottom: "5px", fontSize: "15px", color: "whitesmoke"}}>Clic sobre el Vídeo 🔊 para activar el audio</h5> 
                      
                      </div>



<div className="col-12 w-100"   >
                        <iframe style={{borderRadius: "8px"}} id="iframe-video" allowFullScreen frameborder="0"  border="none" width={"100%"} height={"auto"}
                        src={`https://player.dojofullstack.com/?url=${VIDEO_VLS}&poster=${VIDEO_POSTER}`}></iframe>
                      </div>




{this.state.showForm &&

<>
<div className="py-1 px-1">

<h2 className="dojo-h2 my-1" style={{fontSize: "18px", color: "white", padding: window.type_device ? "10px 18px": "20px 70px"  }}>

Aprenderás a crear aplicaciones web demandadas por las empresas en tan solo 3 meses.
<br />
Somos una Startup Educativa, el aliado que te acompaña para desarrollarte como programador y desarrollador web full stack, durante y después del Bootcamp.

  </h2>

             
 <div className="d-flex justify-content-center mx-4 my-1" style={{flexDirection: "column", alignItems: "center"}}>

  <div className="col-12 col-6">


                            <div className="contact-form--1" id="registro">
                                <div className="container" style={{maxWidth: "570px"}}>
                            <div className="align-items-center justify-content-center">
                                <div>
                                <div style={{textAlign: "-webkit-center"}} className="form-wrapper">

     


    <form onSubmit={this.onSubmit} >
                        <div className="row">
                        <div className="col-12 text-center p-1 mt-2" >

             

 


                     <label className="col-12 text-left font-weight-bold px-1 text-white" htmlFor="nameDojo">
                        <span style={{fontSize: "18px", color: "white"}}>👋 Hola! ¿Cuál es tu nombre?</span>
                            <input
                            id='nameDojo'
                            style={{marginBottom: "5px", backgroundColor: 'white'}}
                            type="text"
                            name="name"
                            value={this.state.Name}
                            onChange={(e) => this.setState({ Name: e.target.value})}
                            placeholder= {"Tu primer nombre"}
                            />
                        </label>
                    


                    
                        {/* <label className="col-12 text-left font-weight-bold px-1 text-black mt-3" htmlFor="emailDojo">
                            <span style={{color: "white", fontSize: "18px", display: "inline-block", marginBottom: "5px"}}>
                            📧 Ingresa tu Correo Electrónico
                            </span>
                            <input
                            style={{marginBottom: "5px", backgroundColor: 'white'}}
                            type="email"
                            name="email"
                            id="emailDojo"
                            value={this.state.Email}
                            onChange={(e) => this.setState({ Email: e.target.value})}
                            placeholder="correo@ejemplo.com"
                            />
                        </label> */}


                   <label className="col-12 text-left font-weight-bold px-1 mt-3" style={{fontSize: "18px", color: "white", textAlign: "left"}}>
                      Recibir Info por WhatsApp:
                      </label> 

        <div className="d-flex justify-content-center mx-1 " style={{flexDirection: "row"}}>


                <div className="mx-2 mt-2">
                    <Selectrix
                    className="pais-inscription"
                    height={300}
                    onRenderOption={this.onRenderOption}
                    onRenderSelection={this.onRenderSelectionPais}
                    placeHolderInside={true}
                    placeholder={"País"}
                    customScrollbar={true}
                    searchable={false}
                    materialize={false}
                    defaultValue={this.state.countryCode}
                    options={KeyCountry}
                    />

                </div>

                <div style={{width: "80%"}}>
                <label htmlFor="Phone">
                    
                    <input
                    style={{background: "white"}}
                    type="tel"
                    name="phone"
                    id="Phone"
                    value={this.state.Phone}
                    onChange={(e) => {
                        this.setState({ Phone: e.target.value });
                    }}
                    placeholder="N.º de WhatsApp"
                    aria-label="Ingresa tu número de WhatsApp"
                    />
                 </label>
                </div>
              
                </div> 




                    <button disabled={this.state.loadingEnroll} className="btn-efect-live mt-1" type="submit" name="submit"
                            style={{color: "white", fontFamily: "Poppins,sans-serif", width: "100%", fontSize: "23px", fontWeight: "bold", textTransform: "uppercase"}}>
                        {this.state.loadingEnroll  &&
                                        <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        style={{fontSize: "20px", marginRight: "5px"}}
                                        />}
                          
                          Obtén Más Información sobre la Beca Parcial
                        </button> 


                      
                
                  

                        </div>
           
                        </div>

                        </form>
  


             
                        
                      
            


                        </div>
                        </div>
                        </div>
                        </div>
                        </div>

 




  </div>



  {/* <div className="col-12 col-6">

      <p className="mt-1 text-white" style={{fontSize: "19px", fontStyle: "italic"}}>
      ¡Solicita Más Información Sobre la Beca del 75% OFF! 🎓 ¡Contáctanos a través de WhatsApp!
          </p> 
  </div> */}


  {/* <div className="col-12 col-md-6 mb-5">
    <a href="https://api.whatsapp.com/send?phone=51936670597&text=Hola, me gustaría saber más sobre el Bootcamp Full Stack Online y la Beca.">
      <button 
          onClick={() => window.fbq("track", "Contact")}
                className="btn-grupo-ws mt-2"
              style={{backgroundColor: "springgreen", color: "black",
              fontFamily: "Poppins,sans-serif", width: "100%", fontSize: "20px", fontWeight: "bold", textTransform: "uppercase"}}>
            
            <FaWhatsapp style={{marginRight: "7px", fontSize: "29px"}} />
            Más Info sobre la Beca 75% OFF Aquí 
          </button>
    </a>
  </div> */}


   

         

</div>








 <div class="row mt-5 mb-3">
                    <div class="col-lg-12 col-12 mt-4" >
                        <div class="section-title service-style--3 mb--25 mb_sm--0 text-center">
                            <h3 class="title text-white px-4">
                            Nuestros estudiantes trabajan en reconocidas empresas internacionales.
                             </h3>

                             <div className="d-flex justify-content-center">
                              <img className="col-12 col-md-6 w-100 img-fluid" src="/assets/images/dojopy/brands_2025.png" alt="" srcset="" />
                             </div>
                        </div>
                    </div>
  </div> 
  




  <div data-aos="fade-right" className="about-area ptb--10  px-0" style={{backgroundColor: "rgb(27, 18, 18)"}}>
                    <div className="about-wrapper">
                        <div className="container px-0">
                            <div className="row align-items-center">

                                <div className="col-12 px-0" style={{padding: 0}}>
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                        

                                            <h3 class="title text-white px-3 text-center">
                                            LO QUE DICEN DE NOSOTROS
                                            </h3>


                                        </div>

                                        <TouchCarousel
                                        component={Container2}
                                        cardSize={cardSize2}
                                        cardCount={data2.length}
                                        cardPadCount={cardPadCount}
                                        loop={true}
                                        autoplay={8e3}
                                        renderCard={this.renderCard2}
                                        />


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
  


            



                    
                      </div>

                      </>
                    }
                    </div>
                        
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



        {/* <div className="row justify-content-center m-0 p-0">
      <div data-aos="fade-right" className="col-12 col-md-6 mt-1" style={{marginBottom: "15px"}}  id="">
     
     <div className="mb-2" style={{paddingTop: "5px", paddingBottom: "5px" }}>
      


      <a href={this.GetLinkWS()}>
      <button
      onClick={() => {
        window.fbq('track', 'Contact', {
          content_type: 'product',
          content_ids: ['BOOTCAMP'], // Cambia con el ID del producto
          content_name: 'Bootcamp Web Full Stack' // Cambia con el nombre del producto
        });
        this.eventPing("Contact");
        // const link = this.GetLinkWS();
        // window.open(link, '_blank').focus();
      }}
      
  className="btn-efect-live"
   style={{borderRadius: "15px", padding: "15px", color: "white", width: "100%", fontSize: "25px", display: "block", margin: "auto", textTransform: "capitalize"}}>
🚀 Únete al Bootcamp: Más Información
</button>
      </a>
</div>
</div>
</div>  */}




{/* <div className="about-wrapper" style={{paddingTop: window.type_device ? "1px": "29px", paddingBottom: "10px", background: "#1B1212" }}>
<div data-aos="fade-top"  className={window.type_device ? "container-fluid ": "container"}>

 <div className="d-flex mt-1 justify-content-center text-white" style={{flexDirection: window.type_device ? "column": "row", gap: window.type_device ? "1px": "15px" }}>


 <div className="d-flex mb-3" style={{maxWidth: "540px" , borderRadius: "20px", border: "1px solid mediumpurple", padding: "5px", alignItems: "center"}}>
    <img className="ml-1" style={{borderRadius: "30px", marginRight: "5px"}} height="89px" src="/assets/images/dojopy/1-face.webp" />
    <div className="ml-2 mt-2">
      <h5 style={{color: "white", fontStyle: "italic"}} className="my-0">Elon Musk <br />
        <span style={{marginLeft: "1px", fontSize: "13px", color: "mediumseagreen"}}>CEO SpaceX & CTO Twitter</span>
      </h5>
      <p style={{fontSize: "14px", fontStyle: "italic"}}>La vida es demasiado corta para ser pequeña. Tienes que hacer algo grande.</p>
    </div>
  </div>


<div className="d-flex mb-3" style={{maxWidth: "540px", borderRadius: "20px", border: "1px solid mediumpurple", padding: "5px", alignItems: "center"}}>
    <img className="ml-1" style={{borderRadius: "30px", marginRight: "5px"}} height="89px" src="/assets/images/dojopy/3-face.jpg" />
    <div className="ml-2 mt-2">
      <h5 style={{color: "white", fontStyle: "italic"}} className="my-0">Mark Zuckerberg
        <span style={{marginLeft: "5px", fontSize: "13px", color: "mediumseagreen"}}>CEO Facebook</span>
      </h5>
      <p style={{fontSize: "14px", fontStyle: "italic"}}>
        La gente siempre tiene miedo a lo desconocido. Pero si no te arriesgas a salir de tu zona de confort, nunca sabrás de lo que eres capaz.
        </p>
    </div>
  </div>



  <div className="d-flex mb-1" style={{borderRadius: "20px", border: "1px solid mediumpurple", padding: "5px", alignItems: "center"}}>
    <img className="ml-1" style={{borderRadius: "30px", marginRight: "5px"}} height="89px" src="/assets/images/dojopy/2-face.jpeg" />
    <div className="ml-2 mt-2">
      <h5 style={{color: "white", fontStyle: "italic"}} className="my-0">Simón Borrero
        <span style={{marginLeft: "5px", fontSize: "13px", color: "mediumseagreen"}}>Founder Rappi</span>
      </h5>
      <p style={{fontSize: "14px", fontStyle: "italic"}}>
      La tecnología puede cambiar el mundo, pero el verdadero cambio lo generan las personas.
        </p>
    </div>
  </div> 



</div>
</div>
</div> */}






{/* 
<div className="about-wrapper" style={{paddingTop: window.type_device ? "5px": "50px", paddingBottom: "50px" }} id="webinar">

<div data-aos="fade-right"  className={window.type_device ? "container-fluid ": "container"}>
                     
        <div className="inner">
    <div className="contact-form--1">
            <div className="row" data-aos="fade-right" >
                <div className="col-12"
                style={{paddingLeft: window.type_device ? "5px": "50px", paddingRight: window.type_device ? "5px": "50px"}}
                >
                <div className="mx-3">
                  <MemoizedTypingAnimation/>
                <div className=" mx-2" style={{width: window.type_device ? "250px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>
                </div>

                    <span
                     style={{display: "inline-block", padding: "3px", marginLeft: "15px", "background-color": "tomato", "border-radius": "17px", "padding-left": "15px", "padding-right": "15px", "color": "white", "font-size": "18px"}}>
                    <span class="pulsar"></span> Online en vivo
                    </span>

                    <div className="mx-2 mt-1" data-aos="fade-right">
                        <p className="p-2">
                       <b>Aquí hay algunas transformaciones que experimentarás:</b> 
                        </p>
                    </div>


                 <div className="mx-2 mt-1" data-aos="fade-right" style={{
                  background: "linear-gradient(to right, #8e2de2, #4a00e0)",
                  color: "white",
                  borderRadius: "19px",
                  padding: "13px"
                 }}>
                  
                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "18px"}}>
                    <span style={{"color":"slateblue", "fontSize": "28px"}}> <FiCheckCircle style={{fontSize: "25px", marginRight: "7px", color: "white"}} /> </span>
                    Crea Aplicaciones Web Completas e Impresionantes.
                    </p>

                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "18px"}}>
                    <span style={{"color":"slateblue", "fontSize": "28px"}}> <FiCheckCircle style={{fontSize: "25px", marginRight: "7px", color: "white"}} /> </span>
                    Contribuye en una Industria Tecnológica Valorada en Billones.
                    </p>

                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "18px"}}>
                    <span style={{"color":"slateblue", "fontSize": "28px"}}> <FiCheckCircle style={{fontSize: "25px", marginRight: "7px", color: "white"}} /> </span>
                    Descubre tu guía definitiva para dominar las tecnologías más solicitadas por las empresas: ¡HTML, JavaScript, React, Python y muchas más!
                    </p>

                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "18px"}}>
                    <span style={{"color":"slateblue", "fontSize": "28px"}}> <FiCheckCircle style={{fontSize: "25px", marginRight: "7px", color: "white"}} /> </span>
                    Oportunidades Laborales Infinitas.
                    </p>

                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "18px"}}>
                    <span style={{"color":"slateblue", "fontSize": "28px"}}> <FiCheckCircle style={{fontSize: "25px", marginRight: "7px", color: "white"}} /> </span>
                    Confianza para Innovar.
                    </p>
             

                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "18px"}}>
                    <span style={{"color":"slateblue", "fontSize": "28px"}}> <FiCheckCircle style={{fontSize: "25px", marginRight: "7px", color: "white"}} /> </span>
                    Crecimiento Profesional y Personal.
                    </p>
                 
                </div>


                <div className="row justify-content-center mx-1">
      <div data-aos="fade-right" className="col-12 col-md-6 mt-1" style={{marginBottom: "25px"}}  id="registro">
     
     <div className="mb-2" style={{paddingTop: "5px", paddingBottom: "5px" }}>
      
      <a href="#registro">
      <button
  className="btn-efect-live"
   style={{borderRadius: "15px", padding: "15px", color: "white", width: "100%", fontSize: "25px", display: "block", margin: "auto", textTransform: "capitalize"}}>
 ¡Obtén Más Información!
</button>
      </a>

</div>
</div>

</div>

                </div>


                </div>
        </div>
        </div>
        
        </div>
        </div>

 */}









    






<Modal show={this.state.isModalWS} size={window.type_device ? "md": "lg"} onHide={() => this.setState({isModalWS: true})} style={{zIndex: "9999999999"}} >

                    <Modal.Body>
                    <div className="about-inner inner">
                        <div className="section-title">
                          <h3 className="mt-3 p-2 text-dark">🌟 ¡Hola! {this.state.Name}</h3>
                            <p className="p-2 text-dark">
                            📲 Envíanos un mensaje por WhatsApp y
                            <b> recibirás todos los detalles de inmediato. </b>
                              ¡Estamos aquí para ayudarte!
                            </p>

                            <div className="w-100 m-1">
                              <button onClick={() => {
                                 const link = this.GetLinkWS();
                                 window.open(link, '_blank').focus();
                              }} className="btn-grupo-ws">
                                <FaWhatsapp style={{marginRight: "5px", width: "25px", height: "25px", position: "relative", bottom: "3px"}}/>
                                ¡Sí, Quiero Más Información! 
                                </button>

                                <p>
                                  
                                </p>
                            </div>

                           
                    </div>
                    </div>
                    </Modal.Body>
                </Modal>



                {false && (

<a href="https://api.whatsapp.com/send?phone=51936670597&text=Hola, me gustaría saber más sobre el Bootcamp Full Stack Online y la Beca."  onClick={() => window.fbq("track", "Contact")}>

      <div className="floating-button-container btn-efect-live" style={{borderRadius: 0}}>

        <div className="floating-button" style={{padding: "3px"}}>

            <div>

                <div className="mt-1">
                  Solicitar Más Info sobre la Beca 75%
                </div>
            
            </div>

        </div>

        </div>
</a>

)}



        <Modal show={this.state.isOpen2} size="md" onHide={this.openModal2} style={{zIndex: "9999999999"}} >
                <span style={{textAlign: "left", "color":"slateblue"}}> <IoCloseCircleOutline onClick={() => this.openModal2()} style={{ cursor: "pointer", fontSize: "35px", marginLeft: "12px", marginTop: "12px", color: "slateblue"}} /> </span>

                    <Modal.Body>
                    <div className="about-inner inner">
                        <div className="section-title">
                            <p>
No te preocupes. Nuestros programas son aptos para principiantes y no requieren ningún conocimiento previo ni hay limite de edad.
Además, no estarás solo. Nuestro equipo estará a tu lado para guiarte en el proceso, ayudarte a establecer objetivos y a tener éxito.
                            </p>
                            <p>
                                <b>*Instalar la herramienta de transmisión en vivo:</b>
                                <img alt="canal zoom" height="95px" src="/assets/images/dojopy/zoom_.webp"></img>
                            </p>
                    </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{"justify-content": "center"}}>
                    <button className="rn-btn" onClick={this.openModal2}>{!this.state.langUS ? "Cerrar": "Close"}</button>
                    </Modal.Footer>
                </Modal>



        <Modal show={this.state.isOpenResume} size="md" onHide={() => this.setState({isOpenResume: false})} style={{zIndex: "9999999999"}} >
                    <span style={{textAlign: "left", "color":"slateblue"}}>
                         <IoCloseCircleOutline onClick={() => this.setState({isOpenResume: false}) }
                         style={{ cursor: "pointer", fontSize: "45px", marginLeft: "12px", marginTop: "12px", color: "slateblue"}} /> </span>

                    <Modal.Body>
                    <div className="">
                        <div className="section-title">
                            <h3 className="title"></h3>

                            <div className="pricing-body mb-3">

                                      <ul className="list-style--1" style={{marginTop: "5px", color: 'black'}}>

                                        <div className="row">
                                            <div className="col-1">
                                                <span style={{"color":"slateblue", "fontSize": "22px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "4px", color: "slateblue"}} /> </span>
                                            </div>
                                            <div className="col-10 pl-2">
                                            <p style={{fontSize: "16px", "margin-bottom": "5px", fontWeight: "bold"}}>
                                                <b style={{color: "black"}}> Te ayudamos a despegar y potenciar tus habilidades, esto puede ahorrarle años valiosos de su tiempo.</b> <br/>
                                            </p>
                                            </div>
                                            </div>

                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Aprende 100% online en vivo. <br/>
                                          </p>

                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          {!this.state.langUS ? "5 meses": "5 months"} (Part-time)<br/>
                                          </p>


                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          5 horas en Vivo y 2 horas de actividades asíncronas, cada semana. <br/>
                                          </p>

                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                           Seguimiento y mentorías. <br/>
                                          </p>


                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Prácticas y Código colaborativo en tiempo real en cada sesión.<br/></p>


                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Aprende con proyectos Full Stack reales <br/> (Tienda Online, Web de pedidos)
                                          </p>


                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Las sesiones en vivo quedan grabadas, acceso a la plataforma sin límite de tiempo, para seguir practicando y aprendiendo. <br/></p>

                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Certificación<br/></p>

                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Una comunidad de apoyo profesional de por vida.<br/></p>


                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Portafolio de proyectos y carta de presentación optimizada 👌.
                                          <br/></p>


                                      </ul>
                                      </div>
                                      <div className="mb-1 text-center">
                                        <button style={{fontSize: "18px"}} className="rn-button-style--2 btn-solid" onClick={() => this.setState({isOpenResume: false})}>{!this.state.langUS ? "Cerrar": "Close"}</button>
                                      </div>
                    </div>


                    </div>


                    </Modal.Body>
               
                </Modal>





 <ToastContainer
                position="bottom-center"
                autoClose={5000}
                zIndex={10000}
                /> 




{/* <div className="row justify-content-center">
  <div className="col-12 col-md-6">
  <div class="calendly-inline-widget"
      data-url={`https://calendly.com/dojofullstack/agendar?hide_event_type_details=1&hide_gdpr_banner=1&email=${this.state.Email}&name=${this.state.Name}`}
      style={{minWidth:"320px", height: "700px"}}></div>
  </div>

</div> */}





{this.state.showForm &&
        <FooterTwoFunnel  />
}

      </React.Fragment>
    );
  }

  async componentDidMount() {


    document.body.style.backgroundColor = "rgb(27, 18, 18)";

    // document.getElementById("whatsapp").style.display = "block";





    // console.log(pathname);

    const pathdata = window.location.href;

    const keyword = "webinar";
    let registerRequerid = false;
    const currentUrl = window.location.href;
    if (currentUrl.includes(keyword)) {
      registerRequerid = true;
    }

    const demoId = "evento";
    window.urlWasi = this.GetLinkWS();

    
    try {
      const responseIP = await axios.get("https://ipapi.co/json/");
      let dataPriceIP = responseIP.data;
      if (!dataPriceIP.country_code) throw "api error";

      this.setState({countryCode: dataPriceIP.country_code});
      this.DynamicCountry(dataPriceIP.country_code);

  } catch {
      try {
          const response = await axios.get('https://bdzpehnjfmdq56bcrp6gb4mcru0nxqci.lambda-url.us-east-1.on.aws/');
          let data = response.data;
          this.setState({ countryCode: data.country_code });
          this.DynamicCountry(data.country_code);
          
      } catch (error) {
          console.log("Error API", error);
      }
  }


    // Obtener ancho de la ventana
    // const windowWidth = ;
    const iframe = document.getElementById('iframe-video');
    const estilo = window.getComputedStyle(iframe);
    const anchoExacto = parseInt(estilo.width, 10)

    const windowWidth = window.type_device ? window.innerWidth : anchoExacto;
    // Calcular el alto basado en la proporción 16:9
    const aspectRatio = 16 / 9;
    const height = windowWidth / aspectRatio;
    iframe.style.height = height  + 'px';



    this.sleep(15000).then(r => {
      this.setState({showForm: true});
    })



  }
}

export default VslDefault;
