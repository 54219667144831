import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { BsCircle, BsArrowLeftCircle } from "react-icons/bs";
import Selectrix from "react-selectrix";
import { FcDataProtection } from "react-icons/fc";
import { MdWork, MdEmail, MdHelp } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactCountryFlag from "react-country-flag";


const KeyCountry =  [
    {key: "US", label: "Estados Unidos"},
    {key: "CA", label: "Canada"},
    {key: "PE", label: "Perú"},
    {key: "BO", label: "Bolivia"},
    {key: "MX", label: "México"},
    {key: "CO", label: "Colombia"},
    {key: "EC", label: "Ecuador"},
    {key: "PR", label: "Puerto Rico"},
    {key: "SV", label: "El Salvador"},
    {key: "CL", label: "Chile"},
    {key: "CR", label: "Costa Rica"},
    {key: "HN", label: "Honduras"},
    {key: "UY", label: "Uruguay"},
    {key: "GT", label: "Guatemala"},
    {key: "US", label: "Estados Unidos"},
    {key: "PA", label: "Panamá"},
    {key: "CU", label: "Cuba"},
    {key: "ES", label: "España"},
    {key: "NI", label: "Nicaragua"},
    {key: "PY", label: "Paraguay"},
    {key: "AR", label: "Argentina"},
    {key: "DO", label: "República Dominicana"},
    {key: "VE", label: "Venezuela"},
    {key: "BR", label: "Brasil"}                                                
]


class VisorTemario extends Component{
    constructor () {
        super()
        this.state = {
          slugBootcamp: '',
          Temario: 'https://media.dojopy.com/bootcamp/FULLSTACK-2024-EN-VIVO_compressed_.pdf',
          Programa: '',
          isOpen: false,
          countryCode: "US",
          Email: '',
          Phone: '',
          isOpenTemario: false,
          isPdfPrice: false,
          langUS: false
        }
        this.GetLinkWS = this.GetLinkWS.bind(this);
        this.openModalEmail = this.openModalEmail.bind(this);
        this.onRenderOption = this.onRenderOption.bind(this);
        this.DownloadPdf = this.DownloadPdf.bind(this);



    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    onRenderOption ( option, complete ) {
        return (
            <li>
            <ReactCountryFlag
                countryCode={option.key}
                svg
                style={{
                    width: '2em',
                    height: '2em',
                    padding: '1px'
                }}
                title="país"
            />  {complete ? option.label : option.key }
            </li>
                )
    }

    onRenderSelection( selected, complete ){
        return 	(<span style={{ padding: 2 }}>
		{ selected ?
            <span style={{"color": "black"}}>
                <ReactCountryFlag
                countryCode={selected.key}
                svg
                style={{
                    width: '2.3em',
                    height: '2.3em',
                    padding: '2px'
                }}
                title="país"
        />  {complete ? selected.label: selected.key}
            </span>
            :
               <span style={{padding: 5}}></span>  
        }
	</span>)
    }

    GetLinkWS(){
        if (window.type_device){
            return `https://api.whatsapp.com/send?phone=51936670597&text=Hola%20DojoFullStack%2C%20información%20sobre%20el%20Programa%20de%20${this.state.Programa}%20online%20en%20vivo.`;
        } else {
            return `https://web.whatsapp.com/send?phone=51936670597&text=Hola%20DojoFullStack%2C%20información%20sobre%20el%20Programa%20de%20${this.state.Programa}%20online%20en%20vivo.`;
        }
    }

    openModalEmail (){
        this.setState({Phone: ""});
        this.setState({isOpenTemario: !this.state.isOpenTemario});
    }

    DownloadPdf(e) {

        e.preventDefault();

        if(this.state.Email === "" ){
            toast.warning('El campo Email es requerido.');
            return;
        }

        localStorage.setItem('email', this.state.Email);

        toast.success(!this.state.langUS ? "Hola!, enviamos el programa por Email, gracias!": "Hello! We sent the program by Email, thank you!");
        
        this.openModalEmail();

        axios.post('https://cprij4toee.execute-api.us-east-1.amazonaws.com/dev',
        {lead: true, evento: false, bootcamp: this.state.slugBootcamp , email: this.state.Email, name: this.state.Name, phone: this.state.Phone, country: this.state.countryCode, langUS: this.state.langUS, Token: "c3b4b89c"})
        .then(res => {
            console.log(res);
            window.gtag_report_conversion();
            // window.location.href = `/inscripcion/fullstack/${this.state.countryCode}`;
            // toast.success(`Hola ${this.state.Name}!, tengamos una breve sesión informativa para completar tu inscripción`);
        })
    }


    render(){

        return(
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Temario Full Stack Web' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.webp" register={true} langUS={this.state.langUS} />

<div className="breadcrumb-area rn-bg-color bg_image bg_image--1" style={{height: "85px"}} data-black-overlay="6">
</div>


                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--1 bg_color--1" style={{padding: "0px", textAlign: "center"}}>
                    <div className={window.type_device ? "w-100" : "container"}>
                        <div className="row">
                            <div className="col-12 px-1">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        
                                        {this.state.Temario &&
                                            <iframe src={`https://d352y0uj302yx3.cloudfront.net/web/viewer.html?file=${this.state.Temario}`} style={{"width":"100%", "height":"1200px"}} frameBorder="0"></iframe>                                        
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
             
                {/* End Back To Top */}


                <Modal show={this.state.isOpenTemario} size="md" onHide={this.openModalEmail} style={{zIndex: "9999999999"}}>
                
                <div style={{marginTop: "15px"}}>
                <span style={{fontSize: "20px", "text-align": "left", fontWeight: "600", marginBottom: "1px", color: "black"}}>
                    <BsArrowLeftCircle onClick={() => this.openModalEmail()} style={{marginRight: "10px", cursor: "pointer", fontSize: "39px", marginLeft: "12px", color: "slateblue"}} />
                     {!this.state.langUS ? "Programa y Temario": "Syllabus" }
                    </span>
                </div>

            <Modal.Body >
                <div className="inner">
                    <div className="contact-form--1">
                        <div className="container" style={{maxWidth: "370px"}}>
                            <div className="row row--35 align-items-center">
                                <div>

                                <div style={{textAlign: "-webkit-center"}} className="form-wrapper">
                                <div className="thumbnail" style={{paddingBottom: "8px", maxWidth: window.type_device ? "310px": "360px" }}>
                                <img style={{boxShadow: "#424241 6px 5px 15px 0px"}} className="image_temario" src={`/assets/images/dojopy/${this.state.slugBootcamp}.png`} alt="temario bootcamp"/>
                                </div>


                    <form onSubmit={this.DownloadPdf}>
                        <div className="row p-1">
                        <div className="col-12 text-center p-1" >

                        <label class="col-12" htmlFor="item02">
                            <input
                            style={{marginBottom: "5px"}}
                            type="email"
                            name="email"
                            id="item02"
                            value={this.state.Email}
                            onChange={(e) => this.setState({ Email: e.target.value})}
                            placeholder= {!this.state.langUS ? "¿Cuál es tu correo electrónico?": "What is your email?"} 
                            />
                        </label>
                            

                    <div className="row p-2">
                        <div className="col-5">
                            <Selectrix
                            height={300}
                            onRenderOption={this.onRenderOption}
                            onRenderSelection={this.onRenderSelection}
                            placeHolderInside={true}
                            placeholder={"País"}
                            customScrollbar={true}
                            searchable={false}
                            materialize={false}
                            defaultValue={this.state.countryCode}
                            options={KeyCountry}
                            // onChange={(value) => this.DynamicCountry(value.key)}
                            />
                        </div>


                        <label className="col-7" htmlFor="Phone" style={{paddingLeft: "1px"}}>
                            <input
                            style={{marginBottom: "5px"}}
                            type="text"
                            name="phone"
                            id="Phone"
                            value={this.state.Phone}
                            onChange={(e) => {
                                this.setState({ Phone: e.target.value });
                            }}
                            placeholder={!this.state.langUS ? "N.º Celular / WhatsApp": "N.º Phone / WhatsApp"} 
                            />
                        </label>
                        
                    </div>

                    <div className="col-12" style={{color: "white", fontSize: "14px", textAlign: "left"}}>
                    <label htmlFor="checkNotify" style={{float: "left", color: "black"}}>
                        <input
                            style={{width: 18, float: "left", marginTop: "-10px", marginRight: "9px", marginBottom: "1px"}} 
                            defaultChecked={true}
                            type="checkbox"
                            name="checkNotify"
                            id="checkNotify"
                        />
                        <span>{!this.state.langUS ? "Protección de datos y privacidad.": "Data protection and privacy."}  <FcDataProtection style={{fontSize: "17px"}} /> </span>
                    </label>
                    </div>

                                <button className="rn-button-style--2 btn-solid" type="submit" name="submit" id="mc-embedded-subscribe-" style={{maxWidth: "95%", fontSize: "18px", marginTop: '1px', fontWeight: "bold"}}>
                                <MdEmail style={{fontSize:"25px"}} /> {!this.state.langUS ? "Enviar programa": "Send program"}
                                </button>
                        </div>
                        </div>

                        </form>

                        <div>
                        <button onClick={() => this.openModalEmail() } className="rn-button-style--1 btn-solid" type="submit" name="submit"  style={{marginTop: '13px', fontSize: "18px"}}>
                        {!this.state.langUS ? "Cerrar": "Close"}
                        </button>
                        </div>
                   
                        </div>
               
                        </div>
                        </div>
                        </div>
                        </div>
                    </div>
                    </Modal.Body>
                </Modal>


                <FooterTwo langUS={this.state.langUS} />

            </React.Fragment>
        )
    }

    async componentDidMount() {
        axios.get('https://ipapi.co/json/').then((response) => {
            let data = response.data;
            let country = data.country;
            let utc_offset = data.utc_offset;
            this.setState({utc_offset: utc_offset, countryCode: country});
    
            }).catch(e => {
                console.log(e)
            })

            
        const slugBootcamp = this.props.match.params.slug;
        const url = new URL(window.location.href);
        const price = url.searchParams.get("price") || '';
        if (price.includes('true')){
            this.setState({isPdfPrice: true});
        }

        if (slugBootcamp == 'bootcamp-fullstack-us'){
            this.setState({langUS: true, countryCode: 'US'});
        }


        axios.get('https://ksijsnxup5eneya3r7zgy36y4a0evcbr.lambda-url.us-east-1.on.aws/').then((response) => {
            let data = response.data;
            document.getElementById('whatsapp').style.display='block';
    
            this.setState({slugBootcamp, Temario: data.data[slugBootcamp], Programa: slugBootcamp.replace('-', ' ').toUpperCase()});

            window.urlWasi = this.GetLinkWS();

        }).catch((e) => {
        console.log(e)
    })


    


}}

export default VisorTemario;