import React, { Component , Fragment } from "react";
// import Particles from "react-particles";
// import { loadFull } from "tsparticles";
import TeamFull from "../elements/Team_full";
// import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import FooterTwo from "../component/footer/FooterTwo";
import FooterFloat from "../component/footer/FooterFloat";
import Header from "../component/header/Header";
// import PageHelmet from "../component/common/Helmet";
import { FiCheck, FiClock, FiUser, FiMonitor, FiRadio, FiDownload, FiVideo, FiCheckCircle, FiMail} from "react-icons/fi";
import { GoRocket } from "react-icons/go";
import { FcDataProtection } from "react-icons/fc";
import { GiHamburgerMenu, GiPlatform } from "react-icons/gi";
import { FaGraduationCap, FaWhatsapp, FaCalendarCheck, FaRunning, FaEye, FaCreditCard, FaPaypal } from "react-icons/fa";
import { BsCircle, BsArrowLeftCircle } from "react-icons/bs";
import { AiOutlineDown, AiOutlineRight, AiOutlineClose } from "react-icons/ai";
import { MdWork, MdEmail, MdHelp } from "react-icons/md";
import ModalVideo from 'react-modal-video';
import Typical from 'react-typical';
import Modal from "react-bootstrap/Modal";
import {AccordionWrapper, AccordionItem} from "custom-react-accordion";
import 'custom-react-accordion/dist/Accordion.css'
import NonPassiveTouchTarget from "./NonPassiveTouchTarget";
import TouchCarousel, { clamp } from "react-touch-carousel";
import touchWithMouseHOC from "react-touch-carousel/lib/touchWithMouseHOC";
import "./styles.css";
import data5 from "./data5";
import data2 from "./data2";
import cx from "classnames";
import AOS from 'aos';
import 'aos/dist/aos.css';
import ServiceList5 from "../elements/service/ServiceList5";
import TestimonialOne from "../blocks/testimonial/TestimonialOne";
import ReactCountryFlag from "react-country-flag";
import axios from 'axios';
import {
    Accordion,
    AccordionItem as AccordionItemZero,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
import Selectrix from "react-selectrix";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TestimonialTree from "../blocks/testimonial/TestimonialTree";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import ReactPlayer from "react-player";
import FlipCountdown from '@rumess/react-flip-countdown';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
// import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Alert from 'react-bootstrap/Alert';
import Preguntas from "../component/dojopy/Preguntas";
import Plataforma from "../component/dojopy/Plataforma";
// import {Helmet} from 'react-helmet'
import { Helmet, HelmetProvider } from 'react-helmet-async';


const cardSize = 300;
const cardSize2 = 280;
const cardPadCount = 2;
const carouselWidth = clamp(window.innerWidth, 0, 960);



AOS.init({
    // Global settings:
    disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
    initClassName: 'aos-init', // class applied after initialization
    animatedClassName: 'aos-animate', // class applied on animation
    useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
    
    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 120, // offset (in px) from the original trigger point
    delay: 10, // values from 0 to 3000, with step 50ms
    duration: 1000, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
  });

// const then = moment('05 26 2019, 6:00 am', 'MM DD YYYY, h:mm a');
// const now = moment();
// const countdown = moment(then - now);
// const days = countdown.format('D');
// const hours = countdown.format('HH');
// const minutes = countdown.format('mm');
// const seconds = countdown.format('ss');


const MapCountryCode = {
    "PE": "+51",
    "MX": "+52",
    "CO": "+57",
    "CL": "+56",
    "EC": "+593",
    "US": "+1",
    "PY": "+595",
    "AR": "+54",
    "BO": "+591",
    "UY": "+598",
    "PA": "+507",
    "CR": "+506",
    "GT": "+502",
    "CU": "+53",
    "SV": "+503",
    "ES": "+34",
    "HN": "+504",
    "NI": "+505",
    "PR": "+1",
    "DO": "+1",
    "BR": "+55"
}


const KeyCountry =  [
    {key: "PE", label: "Perú"},
    {key: "BO", label: "Bolivia"},
    {key: "MX", label: "México"},
    {key: "CO", label: "Colombia"},
    {key: "EC", label: "Ecuador"},
    {key: "PR", label: "Puerto Rico"},
    {key: "SV", label: "El Salvador"},
    {key: "CL", label: "Chile"},
    {key: "CR", label: "Costa Rica"},
    {key: "HN", label: "Honduras"},
    {key: "ES", label: "España"},
    {key: "UY", label: "Uruguay"},
    {key: "GT", label: "Guatemala"},
    {key: "PA", label: "Panamá"},
    {key: "NI", label: "Nicaragua"},
    {key: "PY", label: "Paraguay"},
    {key: "DO", label: "República Dominicana"},
    {key: "BR", label: "Brasil"},
    {key: "US", label: "Estados Unidos"},
    {key: "CA", label: "Canada"},
]


function CarouselContainer(props) {
    const {
      cursor,
      carouselState: { active, dragging },
      ...rest
    } = props;
    let current = -Math.round(cursor) % data5.length;
    while (current < 0) {
      current += data5.length;
    }
    // Put current card at center
    const translateX =
      (cursor - cardPadCount) * cardSize + (carouselWidth - cardSize) / 2;
    return (
      <NonPassiveTouchTarget
        className={cx("carousel-container", {
          "is-active": active,
          "is-dragging": dragging
        })}
      >
        <NonPassiveTouchTarget
          className="carousel-track"
          style={{ transform: `translate3d(${translateX}px, 0, 0)` }}
          {...rest}
        />
  
        <div className="carousel-pagination-wrapper">
          <ol className="carousel-pagination">
            {data5.map((_, index) => (
              <li key={index} className={current === index ? "current" : ""} />
            ))}
          </ol>
        </div>
      </NonPassiveTouchTarget>
    );
}

    
function CarouselContainer2(props) {
    const {
      cursor,
      carouselState: { active, dragging },
      ...rest
    } = props;
    let current = -Math.round(cursor) % data2.length;
    while (current < 0) {
      current += data2.length;
    }
    // Put current card at center
    const translateX =
      (cursor - cardPadCount) * 300 + (carouselWidth - 300) / 2;
    return (
      <NonPassiveTouchTarget
        className={cx("carousel-container", {
          "is-active": active,
          "is-dragging": dragging,
        })}
        style={{height: "450px"}}
      >
        <NonPassiveTouchTarget
          className="carousel-track"
          style={{ transform: `translate3d(${translateX}px, 0, 0)` }}
          {...rest}
        />
  
        <div className="carousel-pagination-wrapper">
          <ol className="carousel-pagination">
            {data2.map((_, index) => (
              <li key={index} className={current === index ? "current" : ""} />
            ))}
          </ol>
        </div>
      </NonPassiveTouchTarget>
    );
  }

const Container = touchWithMouseHOC(CarouselContainer);
const Container2 = touchWithMouseHOC(CarouselContainer2);

// const SlideList = [
//     {
//         textPosition: 'text-center',
//         category: '',
//         title: 'Desarrollo <br/> Web Full Stack a medida y escalable',
//         description: '',
//         buttonText: 'INSCRÍBETE HOY',
//         buttonLink: '#project'
//     }
// ]



const InfoAcordeon = {
    nodejs: "Es ideal para manejar aplicaciones de alto tráfico de usuarios y eventos, como por ejemplo Twitter donde a cada segundo se envían cientos de miles de tuits. El desarrollo de aplicaciones es mucho más rápido, las aplicaciones también y por lo tanto el usuario puede acceder a una mejor experiencia de uso",
    database: "Es muy importante saber qué, cómo y dónde se guardarán tus datos. Aprende desde cero los fundamentos y práctica para administrar DBs profesionalmente, aprende bases de datos Relacionales y No Relacionales.",
    web: "En este módulo aprenderás a crear tu sitio web partiendo del prototipo en papel. Te sumergirás en las mejores prácticas del desarrollo web, trabajando con HTML y CSS. Conocerás herramientas para optimizar al máximo tu sitio web, implementando prácticas de versionado de código con GIT, y preprocesadores como SASS. Al finalizar, sabrás cómo aplicar Bootstrap a tus proyectos, y comprenderás lo importante del SEO en tus desarrollos. Subirás tu sitio a un servidor, y aprenderás a interactuar con este servicio. También sabrás cómo presentar un presupuesto y atender a tu cliente final.  ",
    js: "En este módulo aprenderás los fundamentos del lenguaje de programación más usado en la actualidad, con el cual es posible crear aplicaciones de todo tipo. Explorarás inicialmente herramientas propias del mismo, indagando casos prácticos de aplicación y cómo aplicar técnicas de desarrollo para apps modernas con AJAX. Al graduarte, estarás en condiciones de crear soluciones web interactivas, y trasladar los conocimientos del curso a cualquier framework JavaScript.  ",
    react: "En este módulo, que es el cuarto nivel del Bootcamp, aprenderás a programar por componentes, mediante Javascript y React, y también conocerás las ventajas de la utilización del flujos de datos. Comprenderás la utilización del virtual DOM mediante los desarrollos de React JS. Al finalizar, podrás crear tus propias aplicaciones SPA, y estarás en condiciones de hacer cualquier desarrollo con uno de los frameworks más populares y avanzados del momento.",
    python: "Python es uno de los lenguajes de programación más valorados y usados a nivel mundial según estadísticas de Stack Overflow la plataforma más grande de programadores, multitud de Startups y empresas lo utilizan en su día a día como Instagram, Spotify, Amazon, Netflix, Google, Facebook, Dropbox, Youtube, etc,  Python es flexible, claro y con un código legible para todos, tambien trabajaremos con el Framework Django.",
    git: "Aprende todas las herramientas necesarias para dominar el mundo de la programación colaborativa. Aprenderás las bases y conceptos esenciales que necesitas para trabajar en proyectos profesionales, ya sea en una empresa, conjunto de programadores. Con este módulo aprenderás todo lo necesario para involucrarte en cualquier grupo de programadores.",
    project: "El proyecto final se desarrolla en el quinto mes y sirve para certificar el aprendizaje poniéndolo en práctica creando una réplica de una empresa o Startup real como MercadoLibre, Instagram o Airbnb, con tus mentores. El proyecto final es una herramienta increíble para agregar a tu portafolio y así mostrar tu trabajo en tus próximas oportunidades laborales 😎!",
    deploy_aws: "Amazon Web Services (AWS) es una de las principales plataformas de servicios de computación en la nube a nivel mundial, en este Bootcamp desplegaremos el proyecto final en esta infraestructura muy potente, con tecnología de vanguardia, seguridad, confiabilidad, y escalabilidad.",
    deploy_container: "En esta parte, aprenderemos cómo empaquetar código en unidades estándar de software llamadas contenedores. Estos contenedores pueden ayudarnos a desarrollar software de forma más rápida y sencilla que antes, aquí implementaremos nuestro backend complementamente en containers automatizados.",
    testing: "Como bien sabemos, el proceso de creación de software se compone de varias fases. Desde su diseño hasta su puesta en producción, debe pasar por varios momentos en los que este software va evolucionando por ello debemos tener siempre un sistema de testing automatizado y documentación de nuestros servicios. ",
    project_hackaton: "Aquí tendrás la libertad de elegir que proyecto o Startup quieres aprender a crear desde cero con tus mentores full stack.",
    serverless: "La arquitectura serverless también conocida como FaaS (Functions as a Service), habilita la ejecución de una aplicación mediante contenedores temporales y sin estado; estos son creados en el momento en el que se produce un evento que dispare dicha aplicación.",
    employer: "Al finalizar del bootcamp, nuestro equipo de talento te asesorará para lograr tus objetivos laborales.",
    api: "En este módulo de API REST con Django explorarás los conceptos fundamentales de las APIs REST, como los métodos HTTP, los recursos, los endpoints y la autenticación. Aprenderás cómo diseñar una API RESTful siguiendo las mejores prácticas, manteniendo la coherencia y facilitando la interacción con otras aplicaciones y servicios. ",
    apiSecure: "En este módulo aprenderás a implementar pruebas automatizadas para verificar el correcto funcionamiento de tu código, así como a identificar y solucionar posibles vulnerabilidades y riesgos de seguridad en tu aplicación. Este módulo te dotará de las habilidades necesarias para garantizar la confiabilidad y protección de tus proyectos web, ofreciendo una experiencia segura y libre de errores para los usuarios finales.",
    django: "El módulo de Django en este bootcamp de backend te brindará una introducción completa al poderoso framework de desarrollo web Python. Aprenderás a construir aplicaciones web robustas y escalables utilizando los principios de diseño y las herramientas que Django ofrece. Desde la creación de modelos y vistas hasta la implementación de autenticación de usuarios y manejo de formularios, este módulo te equipará con las habilidades necesarias para desarrollar aplicaciones web profesionales con eficiencia y facilidad. ¡Prepárate para sumergirte en el mundo emocionante de Django y llevar tus habilidades de desarrollo al siguiente nivel!"
}





class BootcampBackend extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isOpenShowPrueba: false,
            bootcampSlug: "backend-completo",
            isOpenTemario: false,
            show_pricing: false,
            pricingAPI: {},
            timePrueba: '',
            DataFechas: [],
            fecha_start_end: "",
            isPriceEspecial: false,
            PriceEspecialBody: "",
            fechaLimite: "",
            Plan: 1,
            formaPago: 1,
            utc_dif: 0,
            select_mes: "",
            select_turno: "",
            select_modalidad: "",
            Email: "",
            Name: "",
            LastName: "",
            Phone: "",
            fechaSelect: "fecha_1",
            isOpenEmail: false,
            isOpenSuscribe: false,
            isOpen0: false,
            isOpen: false,
            isOpen2: false,
            isOpen3: false,
            isOpenResume: false,
            countryCode: "US",
            price_cuota: "",
            price_cuota_2: "",
            price_completo: "",
            price_completo_regular: "",
            isPricePais: false,
            price_payment: "1",
            salario_fullstack_jr: "$900 DÓLARES",
            salario_fullstack_md: "$1,300 DÓLARES",
            salario_fullstack_sr: "+ $2,000 DÓLARES",
            isOpenCheckout: false,
            days: 0,
            mes: 1,
            hours: 0,
            minutes: 0,
            seconds: 0,
            enrollMes: "",
            enrollDay: "",
            photoIndex: 0,
            isOpenImg: false,
            MesAcceso: null,
            loadingEnroll: false,
            isCuota: 1 ,
            showMore: false,
            showMoreA: false,
            showMoreB: false,
            showMoreC: false,
            showMoreD: false,
            showMoreE: false,
            showMoreF: false,
            showMoreG: false,
            showMoreI: false,
            showPhoneField: false,
            VideoPitch: false,
            sendDataForm: false,
            price_mensual: "",
            bootcampSlugMensual: 'backend-completo-2p',
            DescuentoBody: ""
        };
        this.openModalEmail = this.openModalEmail.bind(this);
        this.openModalCheckout = this.openModalCheckout.bind(this);
        this.openModalSuscribe = this.openModalSuscribe.bind(this);
        this.openModalVid = this.openModalVid.bind(this);
        this.selectBoot = this.selectBoot.bind(this);
        this.openModal0 = this.openModal0.bind(this);
        this.openModal = this.openModal.bind(this);
        this.openModal2 = this.openModal2.bind(this);
        this.openModal3 = this.openModal3.bind(this);
        this.openModal4 = this.openModal4.bind(this);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onRenderOption = this.onRenderOption.bind(this);
        this.OpenWS = this.OpenWS.bind(this);
        this.GetLinkWS = this.GetLinkWS.bind(this);
        this.FechaEnroll = this.FechaEnroll.bind(this);
        this.DownloadPdf = this.DownloadPdf.bind(this);
        this.onSubmitEmail = this.onSubmitEmail.bind(this);
        this.setEmailValidate = this.setEmailValidate.bind(this);
        this.DynamicCountry = this.DynamicCountry.bind(this);
        

       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    //    window.addEventListener('load', function() {
    //     document.title = '';
    //     })
    }

    // async customInit(engine) {
    //     console.log(engine);
    //     // this adds the bundle to tsParticles
    //     await loadFull(engine);
    //   }

    // async particlesLoaded(container) {
    //     console.log(container);
    // };


   
  
    DynamicCountry(country=''){
        const newCountry = country || this.state.countryCode;
        
        if (country){
            this.setState({countryCode: country});
        }

        
        this.setState({
            isPricePais: false,
            utc_offset: '-0500',
            utc_dif: 0,
            DataFechas: this.state.pricingAPI['backend-completo']['fechas'],
            fecha_start_end: this.state.pricingAPI['backend-completo']['fecha_start_end'],
            isPriceEspecial: this.state.pricingAPI['backend-completo']['isPriceEspecial'],
            PriceEspecialBody: this.state.pricingAPI['backend-completo']['PriceEspecialBody'],
            fechaLimite: this.state.pricingAPI['backend-completo']['fechaLimite'],
            price_completo: this.state.pricingAPI['backend-completo']['price']['usd']['price_view_base'], //495
            price_completo_regular: this.state.pricingAPI['backend-completo']['price']['usd']['price_view_base_regular'],
            price_payment: "1",
            price_mensual: this.state.pricingAPI[this.state.bootcampSlugMensual]['price']['usd']['price_view_final'],
            salario_fullstack_jr: "$900 DÓLARES MENSUAL",
            salario_fullstack_md: "$1,300 DÓLARES MENSUAL",
            salario_fullstack_sr: "+ $2,000 DÓLARES MENSUAL",
            DescuentoBody: this.state.pricingAPI[this.state.bootcampSlug]['DescuentoBody'],
        })


        try {
            if (this.state.pricingAPI['backend-completo'].price[country]){
                this.setState({
                    isPricePais: true,
                    price_completo: this.state.pricingAPI['backend-completo'].price[country].price_view_base,
                    price_completo_regular: this.state.pricingAPI['backend-completo'].price[country].price_view_base_regular,
                    price_mensual: this.state.pricingAPI[this.state.bootcampSlugMensual]['price'][country]['price_view_final'],
                
                });
            }
        } catch (error) {
            console.log(error);
        }


        if (newCountry === 'PE') {
            this.setState({
                utc_offset: '-0500',
                salario_fullstack_jr: "S/ 3,500 MENSUAL",
                salario_fullstack_md: "S/ 5,000 MENSUAL",
                salario_fullstack_sr: "+ S/ 7,900 MENSUAL"
            });
        } else if (newCountry === 'MX') {
            this.setState({
                utc_dif: -1,
                utc_offset: '-0600',
                // price_cuota: "$7,375 MXN",
                // price_completo: "$14,750 MXN",
                // price_completo_regular: "$21,450 MXN",
                salario_fullstack_jr: "$17,500 MXN MENSUAL",
                salario_fullstack_md: "$25,500 MXN MENSUAL",
                salario_fullstack_sr: "+ $39,500 MXN MENSUAL"
                });
        } else if (newCountry === 'CR'){
            this.setState({utc_dif: -1, utc_offset: '-0600'});
        } else if (newCountry === 'CL'){
            this.setState({utc_dif: -1, utc_offset: '-0300'});
        } else if (newCountry === 'BO'){
            this.setState({
                utc_dif: -1,
                utc_offset: '-0400',
                });
        } else if (newCountry === 'CU'){
            this.setState({utc_dif: +1});
        } else if (newCountry === 'SV'){
            this.setState({utc_dif: -1});
        } else if (newCountry === 'GT'){
            this.setState({utc_dif: -1});
        } else if (newCountry === 'HN'){
            this.setState({utc_dif: -1, utc_offset: '-0600'});
        } else if (newCountry === 'NI'){
            this.setState({utc_dif: -1});
        } else if (newCountry === 'PY'){
            this.setState({utc_dif: +1, utc_offset: '-0300'});
        } else if (newCountry === 'PR'){
            this.setState({utc_dif: +1, utc_offset: '-0300'});
        } else if (newCountry === 'DO'){
            this.setState({utc_dif: +1});
        } else if (newCountry === 'UY'){
            this.setState({
                utc_dif: +2,
                utc_offset: '-0300'
            });
        } else if (newCountry === 'BR'){
            this.setState({
                utc_dif: +2,
                utc_offset: '-0300'
            });
        }
    }



    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    OpenMeet(){
        if (this.state.Phone){
            window.open(`https://calendly.com/dojopy/bootcamp-fullstack?name=${this.state.Name}&email=${this.state.Email}&phone=${this.state.Phone}`, '_blank').focus();
        } else {
            window.open("https://calendly.com/dojopy/bootcamp-fullstack", '_blank').focus();
        }
    }

    OpenWS(){
        if (window.type_device){
            window.open("https://api.whatsapp.com/send?phone=51936670597&text=Hola, informes%20sobre%20el%20BootCamp%20Backend", '_blank').focus();
        } else {
            window.open("https://web.whatsapp.com/send?phone=51936670597&text=Hola, informes%20sobre%20el%20BootCamp%20Backend", '_blank').focus();
        }
    }



    GetLinkWS(){
        if (window.type_device){
            return "https://api.whatsapp.com/send?phone=51936670597&text=Hola, informes%20sobre%20el%20BootCamp%20Backend";
        } else {
            return "https://web.whatsapp.com/send?phone=51936670597&text=Hola, informes%20sobre%20el%20BootCamp%20Backend";
        }
    }

    OpenPayment(){
        window.open("https://secure.2checkout.com/checkout/buy?merchant=250911784622&tpl=default&prod=4U4OOBRGUW&qty=1&lang=es&languages=es", '_blank').focus();
    }



    onRenderOption ( option, index ) {
        return (
            <li>
            <ReactCountryFlag
                countryCode={option.key}
                svg
                style={{
                    width: '2.1em',
                    height: '2.1em',
                    padding: '1px'
                }}
                title="país"
            />  { option.key }
            </li>
                )
    }

    onRenderSelection( selected, settings, deselect ){
        return 	(<span style={{ padding: 2 }}>
		{ selected ?
            <span style={{"color": "black"}}>
                <ReactCountryFlag
                countryCode={selected.key}
                svg
                style={{
                    width: '2.5em',
                    height: '2.5em',
                    padding: '2px'
                }}
                title="país"
        />  {selected.key}
            </span>
            :
               <span style={{padding: 5}}></span>  
        }
	</span>)
    }

    FechaEnroll(e){
        let fecha_enroll = e.currentTarget.value;
        this.setState({fechaSelect: fecha_enroll});

        // if (fecha_enroll === "fecha_3"){
        //     localStorage.setItem('modalidad', 'full_time');
        // } else {
        //     localStorage.setItem('modalidad', 'part_time');
        // }

        window.location.href = '#inversion';
    }

    DownloadPdf(e) {
        e.preventDefault();

        if(this.state.Email === "" ){
            toast.warning('El campo Email es requerido.');
            return;
        }

        localStorage.setItem('email', this.state.Email);

        toast.success(`Enviamos el programa y temario completo por Email, gracias!`);
        this.openModalEmail();

        axios.post('https://cprij4toee.execute-api.us-east-1.amazonaws.com/dev',
        {lead: true, evento: false, bootcamp: 'Backend', email: this.state.Email, name: this.state.Name, phone: this.state.Phone, country: this.state.countryCode})
        .then(res => {
            console.log(res);
            // window.location.href = `/inscripcion/fullstack/${this.state.countryCode}`;
            // toast.success(`Hola ${this.state.Name}!, tengamos una breve sesión informativa para completar tu inscripción`);
        })
    }

    onSubmit(e) {
        e.preventDefault();

        if(this.state.Email === "" ){
            toast.warning('El campo Email es requerido.');
            return;
        }
        if(this.state.Name === "" ){
            toast.warning('El campo Nombre es requerido.');
            return;
        }
        if(this.state.Phone === "" ){
            toast.warning('El campo WhatsApp es requerido.');
            return;
        }

        this.setState({loadingEnroll: true, sendDataForm: true});

        localStorage.setItem('email', this.state.Email);
        localStorage.setItem('name', this.state.Name);
        localStorage.setItem('phone', this.state.Phone);

        // toast.success(`${this.state.Name} enviamos tus datos al equipo de Dojopy, gracias!`);

        // let cupon = window.location.search.split('ref=')[1];

        axios.post('https://cprij4toee.execute-api.us-east-1.amazonaws.com/dev',
        {bootcamp: 'Web FullStack', plan: this.state.Plan, email: this.state.Email, name: this.state.Name, phone: this.state.Phone, country: this.state.countryCode, fecha: this.state.select_fecha, lastname: this.state.LastName},)
        .then(res => {
            this.setState({loadingEnroll: false});
            window.location.href = `/inscripcion/fullstack/${this.state.countryCode}`;
            // toast.success(`Hola ${this.state.Name}!, tengamos una breve sesión informativa para completar tu inscripción`);

        })
    }


    onSubmitEmail(e) {
        try {
            e.preventDefault();            
        } catch (error) {
            console.log(e);
        }

        this.setState({loadingEnroll: true});

        if(this.state.Email === "" ){
            toast.warning('El campo Email es requerido.');
            return;
        }

        axios.post('https://cprij4toee.execute-api.us-east-1.amazonaws.com/dev',
        { email: this.state.Email, phone: this.state.Phone, country: this.state.countryCode},)
        .then(res => {
            this.setState({isOpenEmail: false});
        })

    }


renderCard(index, modIndex) {
    const item = data5[modIndex];
    return (
        <div
        key={index}
        className="carousel-card"
        >
        <div
            className="carousel-card-inner"
            style={{backgroundImage: item.background , backgroundSize: "cover"}}
        >
            {item.title}
        </div>
        </div>
    );
    }

renderCard2(index, modIndex) {
    const item = data2[modIndex];
    return (
        <div
        key={index}
        className="carousel-card"
        >
        <div style={{width: "275px"}}>
           <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "23px", "background": "white"}}>
                <h4>
                {item.title}
                </h4>
                <img height="130px" src={item.background} />
            </div>
        </div>
        </div>
    );
    }


    renderPrice(price,  prueba=false){
        return (
            <>
            <div className="row" style={{placeContent: "center"}}>
                <div>
                    <p style={{fontSize: "40px", "color": prueba ? "black": "white", fontWeight: "bold", paddingTop: "9px", paddingBottom: "9px"}}>
                   {this.state.isPricePais &&
                   <ReactCountryFlag
                        countryCode={this.state.countryCode}
                        svg
                        style={{
                            width: '1em',
                            height: '1em',
                            padding: '1px',
                            marginRight: '8px'
                        }}
                        title="país"
                    /> 
                   }
                    {price}
                    </p>
                </div>
            </div>
            </>
        )
}



      renderHorario(){
        if (this.state.utc_offset === "-0500") {
            return  <p style={{fontSize: "20px", fontWeight: 600}} className="mt-2">7:00 PM a 9:00 PM</p>
        } else if (this.state.utc_offset === "-0400"){
            return  <p style={{fontSize: "20px", fontWeight: 600}} className="mt-2">8:00 PM a 10:00 PM</p>
        } else if (this.state.utc_offset === "-0300"){
            return  <p style={{fontSize: "20px", fontWeight: 600}} className="mt-2">9:00 PM a 11:00 PM</p>
        } else if (this.state.utc_offset === "-0600"){
            return  <p style={{fontSize: "20px", fontWeight: 600}} className="mt-2">6:00 PM a 8:00 PM</p>
        } else {
            return  <p style={{fontSize: "20px", fontWeight: 600}} className="mt-2">7:00 PM a 9:00 PM</p>
        }
  }

  renderHorarioFinSemana(){
    if (this.state.utc_offset === "-0500") {
        return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">10:00 AM a 12:00 AM</p>
    } else if (this.state.utc_offset === "-0600"){
        return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">9:00 AM a 11:00 AM</p>
    } else if (this.state.utc_offset === "-0400"){
        return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">11:00 AM a 13:00 PM</p>
    } else {
        return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">10:00 AM a 12:00 M</p>
    }
}



  renderFechaSelect(item, key){

    return (
        <div key={key} className="row row--35 align-items-center rn-address" data-aos="fade-up"  style={{boxShadow: "#c6c9d8 8px 6px 15px 1px"}}>
        <div className="col-12 mb-3">
            <h2 style={{marginBottom: "0px", fontSize: window.type_device && "39px" }}>
                {item.fecha_inicio}
            </h2>
            <div style={{width: window.type_device ? "200px": "450px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "20px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>

            <div className="row">
                <div className="col-md-2 col-12  mt-2">
                <span style={{padding: "3px", "padding-top": "4px","padding-bottom": "4px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                <FiVideo/> En vivo
                                </span>
                </div>
                <div className="col-md-3 col-12  mt-2">
                    <span style={{"padding-top": "4px","padding-bottom": "4px", "font-size": "18px", "color":"white", "background-color": "slateblue", "border-radius": "8px", "padding-left": "8px", "padding-right": "8px"}}>15 plazas limitadas </span>
                </div>
            </div>

        </div>

        <div className="col-md-3 col-12" style={{height: "110px", borderRight: !window.type_device ? "1px solid #121212" : "none" }}>
            <div> <h5 className="mb-2" ><b> Duración</b></h5> </div>
            <div> <p style={{fontSize: "20px"}}> 4 semanas
            <span style={{display: "inline-block"}}></span>
            </p> </div>
            <span className="bg-white m-1" style={{padding: "5px 7px", border: "1px solid mediumpurple", display: "inline-block", borderRadius: "10px", fontSize: "16px"}}>
            <b>{item.fecha_start_end}</b>
            </span>
        </div>

        <div className="col-md-3 col-12" style={{height: "110px", marginTop: window.type_device ? "25px": "none", borderRight: !window.type_device ? "1px solid #121212" : "none" }}>
            <div> <h5 className="mb-2"><b> Días</b></h5> </div>
            <div> <p style={{fontSize: "20px"}}> {item.dias}  </p> </div>
        </div>

        <div className="col-md-3 col-12" style={{height: "110px", marginTop: "none"}}>
            <div> <h5 className="mb-2"><b> Horario</b></h5> </div>
            
            <div className="row">
                <div className="col-12">
                {item.turno == 'noche' ? this.renderHorario() : this.renderHorarioFinSemana()}
                </div>
            </div>                               
        </div>

        <div className="col-md-3 col-12" style={{marginTop: "30px"}}>
            <div>
            <a href={"#inversion"}>
            <button
            style={{lineHeight: "20px", fontSize: "18px", marginBottom: "13px", padding: "15px 30px" }}
            className=" btn-efect">
            ELEGIR FECHA</button>
            </a>

            </div>
        </div>

    </div>
    )
  }



  renderFechas() {

    return (
        <div>
            {!this.state.select_mes && this.state.DataFechas.map((item, key) => (

                this.state.select_turno ?
                 this.state.select_turno == item.turno && this.renderFechaSelect(item, key)
                :
                this.renderFechaSelect(item, key)

            ))}

            {this.state.select_mes && this.state.DataFechas.map((item, key) => (

                // this.state.select_mes == item.mes && this.renderFechaSelect(item, key)
                
                this.state.select_turno ?
                this.state.select_turno == item.turno && this.state.select_mes == item.mes && this.renderFechaSelect(item, key)
               :
                this.state.select_mes == item.mes && this.renderFechaSelect(item, key)


            ))}
      </div>

    )}



    selectBoot(plan){
        this.setState({Plan: plan});
        window.location.href='#enroll';
    }

    openModalVid (){
        this.setState({isOpenVid: !this.state.isOpenVid});
    }
    openModal0 () {
        this.setState({isOpen0: !this.state.isOpen0});
    }
    openModalEmail (){
        this.setState({Phone: ""});
        this.setState({isOpenTemario: !this.state.isOpenTemario});
    }

    openModalCheckout (){
        this.setState({isOpenCheckout: !this.state.isOpenCheckout});
        this.setState({price_payment: "715"});
        // if (type_bootcamp === 1){
        //     this.setState({price_payment: "95"});
        // } else {
        //     this.setState({price_payment: "175"});
        // }

    }

    openModalSuscribe (e){
        window.location.href = "/Inscripcion";
        // if (e){
        //     this.setState({fechaSelect: e.target.value});
        // }
        // this.setState({isOpenSuscribe: !this.state.isOpenSuscribe});
    }

    openModal () {
        this.setState({isOpen: !this.state.isOpen});
    }

    openModal2 () {
        this.setState({isOpen2: !this.state.isOpen2});
    }
    openModal3 () {
        this.setState({isOpen3: !this.state.isOpen3});
    }
    openModal4(){
        this.setState({isOpenResume: !this.state.isOpenResume});
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open');
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }
    stickyHeader () {}


    validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };
    
    setEmailValidate (email){
        this.setState({ Email: email});
        if (this.validateEmail(email)){
            this.setState({showPhoneField: true});
        }
    }


    renderPais(){
        return (
        <Selectrix
            height={300}
            onRenderOption={this.onRenderOption}
            onRenderSelection={this.onRenderSelection}
            placeHolderInside={true}
            placeholder={"País"}
            customScrollbar={true}
            searchable={false}
            materialize={false}
            defaultValue={this.state.countryCode}
            options={KeyCountry}
            onChange={value => this.DynamicCountry(value.key)}
        />
        )
    }


    render(){
        // const PostList = BlogContent.slice(0 , 5);

        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }

        const style = {
            marginTop: '12px',
            color: '#2F2F2F',
            border: '2px solid #2F2F2F'
          };

          const TypingAnimation =  React.memo(()=>{
            return <Typical 
            className={'my-tipical'}
                    steps={['Crea plataformas web modernas', 500, 'El mejor bootcamp Backend con Python', 500]}
                    loop={true}
                    wrapper="h2"
                /> 

          },(props,prevProp)=> true ); // this line prevent re rendering

          const TypingAnimation2 =  React.memo(()=>{
            return   <Typical
            steps={['Aprende 100% online en vivo', 500 ,'donde quieras y cuando quieras', 1000]}
            loop={Infinity}
            wrapper="h2"
        />

          },(props,prevProp)=> true ); // this line prevent re rendering

        return (
            <Fragment>

                <Helmet>
                    <title>Bootcamp Backend con Python | 100% online en vivo | DojoFullStack</title>
                    <meta name="description" content="Aprenderás a crear aplicaciones backend robustas y escalables desde cero con Python. Domina el Backend de un sitio web usando las mejores prácticas conocidas y la lógica de programación." />
                    <meta
                        property="og:description"
                        content="Aprenderás a crear aplicaciones backend robustas y escalables desde cero con Python. Domina el Backend de un sitio web usando las mejores prácticas conocidas y la lógica de programación."
                    />
                   <meta
                    name="twitter:description"
                    content="Aprenderás a crear aplicaciones backend robustas y escalables desde cero con Python. Domina el Backend de un sitio web usando las mejores prácticas conocidas y la lógica de programación."
                    />
                </Helmet>
                {/* Start Header Area  */}
                <Header bootcampSlug={this.state.bootcampSlug}  langUS={this.state.langUS} countryCode={this.state.countryCode} DynamicCountry={this.DynamicCountry} />
                {/* End Header Area  */}

                {/* Start Slider Area   */}

                <div className="slider-activation slider-creative-agency with-particles" id="home">

                { false &&
                <div className="frame-layout__particles">
                <Particles
                    init={this.customInit}
                    options={{
                        fullScreen: { enable: false },
                        fpsLimit: 120,
                        interactivity: {
                        events: {
                            onHover: {
                                enable: true,
                                mode: "repulse",
                            },
                            resize: true,
                        },
                        modes: {
                            push: {
                                quantity: 4,
                            },
                            repulse: {
                                distance: 200,
                                duration: 0.4,
                            },
                        },
                        },
                    particles: {
                        color: {
                            value: "#ffffff",
                        },
                        links: {
                            color: "#ffffff",
                            distance: 150,
                            enable: true,
                            opacity: 0.5,
                            width: 1,
                        },
                        collisions: {
                            enable: true,
                        },
                        fullScreen: { enable: false },
                        move: {
                            direction: "none",
                            enable: true,
                            outModes: {
                                default: "bounce",
                            },
                            random: false,
                            speed: 2,
                            straight: false,
                        },
                        number: {
                            density: {
                                enable: true,
                                area: 800,
                            },
                            value: 20,
                        },
                        opacity: {
                            value: 0.5,
                        },
                        shape: {
                            type: "circle",
                        },
                        size: {
                            value: { min: 1, max: 5 },
                        },
                    },
                }}

                />;
                
    </div>
    }

                    <div className="bg_image" style={{backgroundImage: 'linear-gradient(rgb(0 0 0 / 50%), rgb(91 18 237 / 95%)), url(/assets/images/bg/bg-image-27.webp)' }} >
                                <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" >
                                    <div className="container">

                                        <div className="row">

                                            <div className="col-lg-12">

                                                <div className="inner text-left" style={{"text-align-last": "left"}}>

                                                {window.type_device ?
                                            <span style={{borderRadius: "10px", padding: "3px", color: "white" ,fontWeight:"bold", height: "30px", fontSize: "15px" ,"width": "143px","border": "1px solid white", "text-align-last": "center"}}>
                                            BOOTCAMP
                                            </span>:
                                            <span style={{borderRadius: "10px", padding: "3px", color: "white" ,fontWeight:"bold", height: "35px", fontSize: "17px" ,"width": "155px","border": "1px solid white", "text-align-last": "center"}}>
                                            BOOTCAMP
                                            </span>
                                        }


                                                    <h1 className="title" style={{"text-align": "left", marginBottom: "10px"}}>DESARROLLO
                                                    <span className="h1-dojopy title title-gradient" >BACKEND CON PYTHON</span>
                                                    </h1>


                                                    <div className="row col-12 mb-3" style={{textAlign: "left", height: "60px"}}>
                                                    <TypingAnimation/>
                                                    </div>
                                                 
                                                    
                                                    <p className="description" style={{"padding-top": "9px", fontSize: "18px"}}>

                                                    
                                                    
                                                    <div className="row">
                                                        <div style={{"text-align-last": "right"}} className="col-1"><FiCheck style={{"color": "yellow"}}/></div>
                                                        <div className="col-10" style={{paddingBottom: "9px", textAlign: "left" }}>
                                                            100% online
                                                            <span style={{marginLeft: "7px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                                            <FiVideo/> EN VIVO
                                                            </span>
                                                         </div>
                                                    </div>


                                                    <div className="row">
                                                        <div style={{"text-align-last": "right"}} className="col-1"><FiCheck style={{"color": "yellow"}}/></div>
                                                        <div className="col-10" style={{paddingBottom: "9px", textAlign: "left" }}>
                                                            4 semanas
                                                         </div>
                                                    </div>

                                                    <div className="row">
                                                        <div style={{"text-align-last": "right"}} className="col-1"><FiCheck style={{"color": "yellow"}}/></div>
                                                        <div className="col-10" style={{paddingBottom: "9px", textAlign: "left" }}>
                                                        Sesiones grupales y mentorías 1 a 1 personalizadas 1 a 1.
                                                         </div>
                                                    </div>  


                                                    <div className="row">
                                                        <div style={{"text-align-last": "right"}} className="col-1"><FiCheck style={{"color": "yellow"}}/></div>
                                                        <div className="col-10" style={{paddingBottom: "5px", textAlign: "left" }}>
                                                            Crea múltiples proyectos reales.
                                                         </div>
                                                    </div>


                                               
                                                        </p>

                                                    <div className="slide-btn">
                                                        <a href="#inversion">
                                                        <button className="rn-button-style--2 btn-primary-color">
                                                        Inscribirme
                                                        </button>
                                                        </a>
                                                    
                                                        </div>
                                                    <span style={{"font-size": "10px", "paddingTop": "7px"}}> Calidad 100% asegurada </span>
                                                 <div className="slide-btn">
                                                     <button value="" style={{textAlignLast: "center", fontSize: "15px"}} className="rn-button-style--2 btn-primary-color-ws" onClick={this.OpenWS}>
                                                     <FaWhatsapp style={{fontSize: "20px", marginRight: "10px"}}/>
                                                          ¿Dudas? Escríbenos
                                                    </button>
                                                </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                </div>
                {/* End Slider Area   */}




  {/* Start About Area */}
  <div data-aos="fade-up" className="about-area ptb--60 bg_color--5" id="about_dojopy">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                            
                                <div className="col-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">Bootcamp Backend Python
                                            <span style={{marginLeft: "8px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "22px"}}>
                                                <FiVideo/> En vivo
                                            </span>
                                            </h2>
                                            <p>
                                           <b> Somos una Startup educativa, el aliado que te acompaña para desarrollarte como programador y
desarrollador web, durante y después del Bootcamp.</b>
                                            </p>
                                            <p>
                                            Aprenderás a crear aplicaciones backend robustas y escalables desde cero. <br/>
                                            Domina el <b>Backend</b> de un sitio web usando las mejores prácticas conocidas y la lógica de programación. <br/>
                                            <a style={{"cursor": "pointer", "text-decoration": "underline"}} onClick={this.openModal3}>Conoce más</a>
                                            </p>



                                        </div>
                                    <div className="row mt--30 rn-address" style={{boxShadow: "#c6c9d8 8px 6px 15px 1px"}}>
                                            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title"><FiClock/> Duración</h3>
                                                    <p style={{fontSize: "20px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    4 semanas
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title"><FiUser/> Modalidad</h3>
                                                    <p style={{fontSize: "20px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span> 100% online
                                                    <span style={{marginLeft: "7px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                                        <FiVideo/> EN VIVO
                                                    </span>
                                                     <br/>
                                                     <div className="pt-2">
                                                     <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span> Sesiones grupales y mentorías 1 a 1 personalizadas 1 a 1.<br/>
                                                     </div>
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title"><FiRadio/> Dedicación</h3>
                                                    <p style={{fontSize: "20px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                     2 sesiones por semana.<br/>
                                                    </p>
                                                </div>
                                            </div>

                                
                                            

                                            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Requisitos</h3>
                                                    <p style={{fontSize: "20px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    <a style={{"cursor": "pointer", "text-decoration": "underline"}} onClick={this.openModal2}>Ver</a>
                                                    
                                                    </p><br/>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Temario</h3>
                                                    <p style={{fontSize: "20px"}}>
                                                    <button className="rn-button-style--2 btn-solid" style={{fontSize: "20px", padding: "10px"}} onClick={() => this.openModalEmail()}>
                                                <FiDownload style={{fontSize: "18px", margin: "3px"}}/>
                                                    Ver Temario
                                                </button>
                                                    </p><br/>
                                                </div>
                                            </div>


                                            <div className="col-12 col-md-9 col-lg-9">
                                                <div className="about-us-list" style={{maxWidth: "450px"}}>
                                                <h3 className="title"><FaGraduationCap/> Certificado</h3>
                                                    <img onContextMenu={(e) => e.preventDefault()} src="/assets/images/dojopy/CERTIFICADO_PY_.webp"></img>
                                                </div>
                                            </div>

                                       
                                    </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}






    {/* Start About Area */}
    <div data-aos="fade-right" className="about-area ptb--120 bg_color--5" id="benefits" style={{marginTop: "-100px"}}>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                              
                                <div className="col-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">
                                            ¿Para quién es este Bootcamp?
                                            </h2>
                                          
                                        </div>


                                    {window.type_device ?
                                        <TouchCarousel
                                        component={Container2}
                                        cardSize={cardSize2}
                                        cardCount={data2.length}
                                        cardPadCount={cardPadCount}
                                        loop={true}
                                        autoplay={2e3}
                                        renderCard={this.renderCard2}
                                        /> :

                                        <div className="row mt--30">
                                        <div className="col-12 col-md-3">
                                            <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "25px", "background": "white"}}>
                                                <h4>
                                                Quieres cambiar de carrera
                                                </h4>
                                                <img height="150px" src="/assets/images/icons/change.webp"></img>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "25px", "background": "white"}}>
                                                <h4>
                                                Mejora tu currículo con nuevas habilidades
                                                </h4>
                                                <img height="150px" src="/assets/images/icons/curriculum.webp"></img>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "25px", "background": "white"}}>
                                                <h4>
                                                Emprendedores y visionarios
                                                </h4>
                                                <img height="150px" src="/assets/images/icons/idea.webp"></img>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "25px", "background": "white"}}>
                                                <h4>
                                                    Si aún no sabes sobre tecnologías web
                                                </h4>
                                                <img height="150px" src="/assets/images/dojopy/web.webp"></img>
                                            </div>
                                        </div>

                                    </div>
                                    }
                               

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}




    {/* Start About Area */}
    <div data-aos="fade-right" className="about-area ptb--120 bg_color--5" id="benefits" style={{marginTop: "-100px"}}>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                              
                                <div className="col-12 col-md-8">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">
                                            ¿Por qué el desarrollo Backend?
                                            </h2>
                                        </div>
                                        <div>
                                            <p>
                                            Te explicamos por qué el Desarrollo Backend es genial de verdad:

                                            Cada vez más empresas buscan programadores backend capaces de comprender y crear aplicaciones web que brinden todo lo que éstas necesiten para
dar respuesta a sus usuarios.

Este perfil se especializa en la creación de APIs, bases de datos y arquitecturas escalables. Es decir, en todo aquello que
no se ve, pero que es fundamental para el funcionamiento de las aplicaciones y sitios web que usamos todos los días.

                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-4">
                                    <img src="/assets/images/icons/empleo_up.webp"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}




    {/* Start About Area */}
    <div data-aos="fade-right" className="about-area ptb--120 bg_color--5" id="profile" style={{marginTop: "-100px"}}>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                              
                                <div className="col-12 col-md-9">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">
                                            Un vistazo a tu futuro perfil
                                            </h2>
                                            <h3 className="title mt-4">
                                            Puesto prestigioso
                                            </h3>
                                        </div>
                                        <div>
                                        <span className="bg-white m-1" style={{padding: "5px 7px", border: "1px solid mediumpurple", display: "inline-block", borderRadius: "10px", fontSize: "18px"}}>Desarrollador Backend</span>
                                        <span className="bg-white m-1" style={{padding: "5px 7px", border: "1px solid mediumpurple", display: "inline-block", borderRadius: "10px", fontSize: "18px"}}>Desarrollador Web Full stack</span>
                                        <span className="bg-white m-1" style={{padding: "5px 7px", border: "1px solid mediumpurple", display: "inline-block", borderRadius: "10px", fontSize: "18px"}}>Desarrollador de Software</span>
                                        <span className="bg-white m-1" style={{padding: "5px 7px", border: "1px solid mediumpurple", display: "inline-block", borderRadius: "10px", fontSize: "18px"}}>Desarrollador Cloud AWS</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                            <h3 className="title mt-4">
                                            Actividades interesantes
                                            </h3>
                                            <div>
                                                <p style={{fontSize: "17px", "margin-bottom": "10px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    Escribir código JavaScript
                                                </p>
                                                <p style={{fontSize: "17px", "margin-bottom": "10px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    Desarrollar APIs y servicios web robustos y escalables.
                                                </p>
                                               
                                                <p style={{fontSize: "17px", "margin-bottom": "10px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    Crear productos digitales en equipo
                                                </p>

                                                <p style={{fontSize: "17px", "margin-bottom": "10px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    Ayudar a las empresas a desarrollarse y a los usuarios a ser más felices
                                                </p>
                                                
                                            </div>
                                    </div>

                                    <div className="col-12 col-md-9">
                                            <h3 className="title mt-4">
                                            Habilidades claves para el mercado laboral
                                            </h3>
                                            <div>
                                            <TouchCarousel
                                                component={Container}
                                                cardSize={cardSize}
                                                cardCount={data5.length}
                                                cardPadCount={cardPadCount}
                                                loop={true}
                                                autoplay={2e3}
                                                renderCard={this.renderCard}
                                                />
                                            </div>
                                    </div>

                            
                                    <div className="col-12 col-md-9">
                                            <h3 className="title mt-4">
                                            Salario alto y perspectivas seguras
                                            </h3>
                                            <p style={{fontSize: "13px"}}>Estos salarios mensuales promedio están tomados de Computrabajo, el portal de empleo líder en Latinoamérica. Pero tú puedes ser mejor que el promedio.</p>
                                            <div style={{boxShadow: "3px 2px 6px 0px #808080b0"}} className="p-3 m-2 bg-white col-10 col-md-6">
                                                <h3 style={{fontWeight: "500"}}>{this.state.salario_fullstack_jr}</h3>
                                                <p>Desarrollador Backend Junior <span className="text-primary d-inline-block">después del Bootcamp</span> </p>
                                            </div>
                                            <div style={{boxShadow: "3px 2px 6px 0px #808080b0"}} className="p-3 m-2 bg-white col-11 col-md-8">
                                                <h3 style={{fontWeight: "500"}}>{this.state.salario_fullstack_md}</h3>
                                                <p>Desarrollador Backend Middle <span className="text-primary d-inline-block">en 1 año</span> </p>
                                            </div>
                                            <div style={{boxShadow: "3px 2px 6px 0px #808080b0"}} className="p-3 m-2 bg-white col-12 col-md-10">
                                                <h3 style={{fontWeight: "500"}}>{this.state.salario_fullstack_sr}</h3>
                                                <p>Desarrollador Backend Senior <span className="text-primary d-inline-block">en 2 años</span> </p>
                                            </div>
                                           
                                    </div>

                            </div>
                </div>
            </div>
        </div>
        {/* End About Area */}






 {/* Start About Area */}
 {/* <div data-aos="fade-right" className="about-area ptb--60 bg_color--5" id="bonus2" style={{marginTop: "1px"}}>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                            <div className="text-center mb--30 col-12">
                                <h3 className="title">Proyectos Startup</h3>
                            </div>

                                <div className="col-md-4 col-12">

                                    <div className="thumbnail zoom">
                                        <img style={{maxHeight: "450px", maxWidth: "450px", transition: "0.2s"}} className="w-100" src="/assets/images/dojopy/project1.webp" alt="shirt"/>
                                        <p style={{padding: "13px"}}>Web Personal</p>
                                    </div>
                                </div>

                                <div className="col-md-4 col-12">
                                    <div className="thumbnail zoom">
                                        <img style={{maxHeight: "450px", maxWidth: "450px", transition: "0.2s"}} className="w-100" src="/assets/images/dojopy/project2.webp" alt="shirt"/>
                                        <p style={{padding: "13px"}}>Web para negocios</p>
                                    </div>
                                </div>

                                <div className="col-md-4 col-12">
                                    <div className="thumbnail zoom">
                                        <img style={{maxHeight: "450px", maxWidth: "450px", transition: "0.2s"}} className="w-100" src="/assets/images/dojopy/project3.webp" alt="shirt"/>
                                        <p style={{padding: "13px"}}>Web Tienda Virtual</p>
                                    </div>
                                </div>

                       

                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End About Area */}


   {/* Start About Area */}
   <div data-aos="fade-right" className="about-area ptb--120 bg_color--5" id="bonus2" style={{marginTop: "1px", color: "black"}}>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                            <div className="text-center mb--30 col-12">
                            <span style={{marginLeft: "7px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                                            <FiVideo/> BOOTCAMP EN VIVO
                                                            </span>
                                <h2 className="title text-black" style={{padding: "10px"}}>
                                    ¿Cómo funciona el Bootcamp?
                                    </h2>
                                    
                            </div>
                               <div className="p-3">
                                   <p>
                                   Cada semana tendrás <b>2 encuentros grupales en vivo</b> con el Instructor, al final de cada semana hay actividades prácticas, no te preocupes si te trabas en las actividades siempre tendrás asesorías personalizadas.
                                   <br/>
                                   En Dojopy proponemos un nuevo modelo educativo que incluye entornos de aprendizaje sincrónicos y asincrónicos con un enfoque que vincula la teoría y la práctica, mediante un aprendizaje activo.
                                    </p>
                                    

                                    <div className="row mt--30">
                                        <div className="col-12 col-md-3 mt-3">
                                            <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "25px", "background": "white"}}>
                                                <h4>
                                                Conéctate a tu primera sesión <span style={{marginLeft: "7px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                                            <FiVideo/> en vivo
                                                            </span>
                                                </h4>
                                                <img height="190px" src="/assets/images/dojopy/live_zoom.webp" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3 mt-3">
                                            <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "25px", "background": "white"}}>
                                                <h4>
                                                Practica con nuevos retos y actividades cada semana
                                                </h4>
                                                <img height="150px" src="/assets/images/dojopy/coding.webp" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3 mt-3">
                                            <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "25px", "background": "white"}}>
                                                <h4>
                                                Recibe asesoría personalizada 1 a 1
                                                </h4>
                                                <img height="150px" src="/assets/images/dojopy/mentor_.webp" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3 mt-3">
                                            <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "25px", "background": "white"}}>
                                                <h4>
                                                    Presenta y comparte tus proyectos, <br/> así todos aprenden más
                                                </h4>
                                                <img height="150px" src="/assets/images/dojopy/programming _.webp"></img>
                                            </div>
                                        </div>

                                    </div>

                                        
                                    
                                    {/* <br/>
                                    <div className="text-center">
                                    <button onClick={() => this.setState({VideoPitch: true})} style={{textAlignLast: "center", "font-weight": "600"}} className="rn-button-style--2 btn-primary-color-4 mt-2">
                                            <FaEye style={{marginRight: "7px", fontSize: "23px"}} />
                                            Ver presentación</button>
                                    </div> */}
                               </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}



             


                {/* Start About Area */}
                <div data-aos="fade-right" className="about-area ptb--60 bg_color--5" id="frontend">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">

                                <div className="col-12 mt-3 mb-3">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h3 className="title pb-3">Módulos principales</h3>    
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}




              


                {/* Start About Area */}
                <div data-aos="fade-left"  className="about-area ptb--60 bg_color--5" id="backend">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img height={window.type_device ? "250px": "350px"} src="/assets/images/dojopy/8.webp" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                        <span style={{marginLeft: "7px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                                            <FiVideo/> EN VIVO
                                                            </span>
                                            <h2 className="title mb-0">BACKEND CON PYTHON Y DJANGO</h2>
                                            <div style={{width: window.type_device ? "200px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>

                                            <p>
                                        <b>Conviértete en quien crea la
        lógica detrás de todas las aplicaciones web.</b>  </p>


                                        </div>


                                        {!this.state.showMoreD && 
                                            <div className="col-12 text-left">
                                                <button
                                                onClick={() => this.setState({showMoreD: true}) }
                                                type="submit"
                                                name="submit"
                                                id="mc-embedded-subscribe-"
                                                style={{ marginTop: "5px", marginBottom: "5px", fontSize: "18px",
                                                "border-radius": "20px",
                                                "border": "2px solid #6a5acd",
                                                "padding": "9px 20px"

                                            }}
                                                >
                                                Mostrar más <br/>
                                                <AiOutlineDown style={{padding: "5px", fontSize: "30px"}}/>
                                                </button>
                                            </div>}

                {this.state.showMoreD && 
                <>
                

                                            <p>
        Cada vez más empresas buscan programadores backend capaces de comprender y crear aplicaciones web que brinden todo lo que estas necesiten para
        dar respuesta a sus usuarios.<br/>

        Este perfil se especializa en la creación de APIs, bases de datos y arquitecturas escalables. Es decir, en todo aquello que
        no se ve, pero que es fundamental para el funcionamiento de las aplicaciones y sitios web que usamos todos los días.<br/>

        En este bootcamp aprenderás desde los fundamentos de la programación hasta el lanzamiento de aplicaciones web sólidas haciendo uso de las últimas tecnologías
        de la industria.
                                            </p>


                                    <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Python</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> REST / RESTfull / APIs</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Django Framework</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Autenticación JWT</h4>
                                                </div>
                                            </div>
                                        </div>
                                        </>}

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}




                {/* Start About Area */}
                <div data-aos="fade-left"  className="about-area ptb--60 bg_color--5" id="backend">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-80" height={window.type_device ? "250px": "350px"} src="/assets/images/dojopy/9.webp" alt="tercer módulo bases de datos"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <span style={{padding: "3px", marginLeft: "7px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                                            <FiVideo/> {!this.state.langUS ? "En vivo": "Live"}
                                                            </span>
                                            <h2 className="title mb-0">{!this.state.langUS ? "BASES DE DATOS": "DATABASES"} </h2>
                                            <div style={{width: window.type_device ? "200px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>

                                            <p>
                                         <b>{!this.state.langUS ? " Conviértete en quien crea la lógica detrás de todas las aplicaciones web.": "Become the one who creates the logic behind all web applications."}</b>  </p>

                                        </div>

                                        {!this.state.showMoreE && 
                                            <div className="col-12 text-left">
                                                <button
                                                onClick={() => this.setState({showMoreE: true}) }
                                                type="submit"
                                                name="submit"
                                                id="mc-embedded-subscribe-"
                                                style={{ marginTop: "5px", marginBottom: "5px", fontSize: "18px",
                                                "border-radius": "20px",
                                                "border": "2px solid #6a5acd",
                                                "padding": "9px 20px"

                                            }}
                                                >
                                                {!this.state.langUS ? "Mostrar más": "Show more"} <br/>
                                                <AiOutlineDown style={{padding: "5px", fontSize: "30px"}}/>
                                                </button>
                                            </div>}

            {this.state.showMoreE && 
                <>
               
                                            <p>
        Antes de utilizar cualquier tecnología para bases de datos debes entender los fundamentos de diseño y modelado
         requeridos para que tengas la mejor arquitectura posible.
         Construye tus propios modelos de bases de datos con las mejores prácticas desde cero

                                            </p>
                                    <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Fundamentos</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Bases de Datos Relacionales (PostgreSQL)</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Bases de Datos no Relacionales (mongoDB/Mongoose)</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Cloud Databases</h4>
                                                </div>
                                            </div>
                                        </div>
                                        </>}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}



    {/* Start About Area */}
    <div data-aos="fade-left"  className="about-area ptb--60 bg_color--5" id="backend">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-80" height={window.type_device ? "250px": "350px"} src="/assets/images/dojopy/10.webp" alt="Desplieque AWS"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                        <span style={{marginLeft: "7px", "background-color": "purple", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                                             PROGRAMA OPTATIVO
                                                            </span>
                                            <h2 className="title">SERVICIOS EN LA NUBE CON AWS</h2>
                                            <div style={{width: window.type_device ? "200px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>

                                            <p>
                                        <b>Sube tus aplicaciones web en la moderna arquitectura de AWS.</b>  </p>
                                        {!this.state.showMoreF && 
                                            <div className="col-12 text-left">
                                                <button
                                                onClick={() => this.setState({showMoreF: true}) }
                                                type="submit"
                                                name="submit"
                                                id="mc-embedded-subscribe-"
                                                style={{ marginTop: "5px", marginBottom: "5px", fontSize: "18px",
                                                "border-radius": "20px",
                                                "border": "2px solid #6a5acd",
                                                "padding": "9px 20px"

                                            }}
                                                >
                                                Mostrar más <br/>
                                                <AiOutlineDown style={{padding: "5px", fontSize: "30px"}}/>
                                                </button>
                                            </div>}
  
                                        </div>

                {this.state.showMoreF && 
                <>
                                        <p>
        AWS es una de las principales plataformas de servicios de computación
        en la nube a nivel mundial, AWS proporciona un amplio conjunto de servicios de
        infraestructura tal como potencia de cómputo, opciones de almacenamiento, redes y bases de datos ofertados
        con una utilidad bajo demanda, disponibles en cuestión de segundos y pagando solo por lo que utiliza.
                                            </p>

                                    <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Bases de datos</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Servidor privado</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Almacenamiento</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Arquitectura Serverless</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}




    {/* Start About Area */}
    <div data-aos="fade-left"  className="about-area ptb--60 bg_color--5" id="backend">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img height={window.type_device ? "250px": "350px"} src="/assets/images/dojopy/startups_logo.webp" alt="startups"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <span style={{marginLeft: "7px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                                            <FiVideo/> EN VIVO
                                                            </span>
                                            <h2 className="title">PROYECTO API REST COMPLETO</h2>
                                            <div style={{width: window.type_device ? "200px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>

                                            <p>
                                        <b> ¿Quieres aprender a crear desde cero una Startup o producto digital? </b>  </p>
                                        {!this.state.showMoreG && 
                                            <div className="col-12 text-left">
                                                <button
                                                onClick={() => this.setState({showMoreG: true}) }
                                                type="submit"
                                                name="submit"
                                                id="mc-embedded-subscribe-"
                                                style={{ marginTop: "5px", marginBottom: "5px", fontSize: "18px",
                                                "border-radius": "20px",
                                                "border": "2px solid #6a5acd",
                                                "padding": "9px 20px"

                                            }}
                                                >
                                                Mostrar más <br/>
                                                <AiOutlineDown style={{padding: "5px", fontSize: "30px"}}/>
                                                </button>
                                            </div>}
                            {this.state.showMoreG && (

                                         <p>
                Nosotros somos el único Bootcamp que propone como proyecto final desarrollar un MVP de una startup o empresa digital
                aprende a crear una plataforma como MercadoLibre, Airbnb con nuestro equipo de mentores full stack desde cero. <br/>
                💪 tú puedes ser el próximo fundador de la siguiente red social o marketplace innovadora 🚀.
                                            </p> )}
                                        </div>
                                

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}





 {/* Start About Area */}
 <div className="about-area ptb--60 bg_color--5" id="platform">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img height="250px" src="/assets/images/dojopy/TALENTO_search.webp" alt="talento developer"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                        {/* <span style={{"background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "20px"}}>
                                                <MdOndemandVideo/> Live
                                            </span> */}
                                            <h2 className="title">
                                                PROGRAMA DE EMPLEO
                                            </h2>
                                        
                                            <div style={{width: window.type_device ? "250px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>

                                            <p>
                                            Al finalizar el programa podrás acceder al curso de preparación profesional. <br/>
                                            </p>

<p>
Aquí te ayudaremos a reunir todos los documentos y habilidades que necesitas para conseguir un trabajo.<br/>
Elaborarás un currículo, un portafolio con tus proyectos más exitosos y escribirás una carta de motivación. <br/>
Con nuestro apoyo, desarrollarás una estrategia de búsqueda y te prepararás para las entrevistas.
</p>




                                           
                                            {/* <p>
                                           Este programa te ayudará a posicionar tu perfil digital en el mercado y a
desarrollar las habilidades blandas necesarias para potenciar la
inserción en el mundo laboral, tales como la creación de un CV Tech, la construcción de tu marca personal, estrategias
de posicionamiento en LinkedIn, entrevistas laborales, portafolios, entre otros. <br/>
<b>Te asesoramos para empezar a trabajar con tu primera empresa como desarrollador web.</b>
                            </p> */}

                                </div>
                           
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}


        
                <Plataforma/>



            <div className="about-wrapper bg_color--5" style={{paddingTop: "20px"}} id="fechas">
                <div className="container">
                <h2 className="title text-center" style={{paddingBottom: "8px" }}>FECHAS DE INICIO</h2>
                {this.renderFechas()}
                </div>
            </div>





                <div className="rn-pricing-table-area ptb--80 bg_color--5" data-aos="fade-right" id="inversion">
                        <div className="text-center mb--30">
                        <h2 className="title" style={{fontSize: "45px"}}>INSCRÍBETE HOY</h2>
                        </div>


                        <div className="container" >
                            <div className="row" style={{justifyContent: "center"}}>


   {/* Start PRicing Table Area  */}
   <div className="col-12 mt-1" style={{paddingBottom: "13px", paddingRight: "15px", paddingLeft: "15px", maxWidth: "470px", marginRight: window.type_device ? "auto": "1px" }} >

{/* <img src='/assets/images/dojopy/nabidad-beca.webp' height={'99px'}
style={{position: "relative", bottom: '-35px', zIndex: "99"}} /> */}

    <div className="rn-pricing" style={{boxShadow: "rgba(140, 82, 255, 0.9) 4px 4px 15px 3px", border: "none", background: "linear-gradient(135deg, #C56CD6 0%,#3425AF 100%)", borderRadius: "20px"}} >
        <div className="pricing-table-inner">

            <div className="pricing-header pb-0 mb-2 mt-1">

            {this.state.fechaLimite.length > 0 &&
            <div className="mb-3">
            <FlipCountdown
                        size={'small'}
                        yearTitle='Year'
                        dayTitle='Días'
                        hourTitle='Horas'
                        minuteTitle='Min'
                        secondTitle='Segs'
                        monthTitle='Mes'
                        hideYear
                        hideMonth
                        endAt={this.state.fechaLimite} // Date/Time
                        />
            </div>
}
        

            
            <span style={{padding: "6px 10px", margin: "5px", "background-color": "tomato", "border-radius": "7px", "color": "white", "font-size": "20px", fontWeight: "bold"}}>
                    {this.state.countryCode !== 'ES' ?  this.state.DescuentoBody : "¡Ahorra un 50% por Año Nuevo!"}
            </span>

            <h4 className="text-white mt-2 mb-1 mx-1">{this.state.countryCode !== 'ES' ?  this.state.titlePricePre : "Realiza el pago antes de las 18:59 horas del 12 de enero y ahorra un 50%"}</h4>




            <h3 className="text-white mt-1 mb-0">Monto Total</h3>
                 


                <span style={{color: "white", display: "inline-block"}}>
                <del style={{color: "lightgray", fontSize: "26px"}}>
                &nbsp; {this.state.price_completo_regular} &nbsp;
                </del>
                </span>


                <h3 className="pricing" style={{marginBottom: "5px"}} >
                {this.renderPrice(this.state.price_completo)}
                </h3>

                {this.state.countryCode === 'MX' &&
<div className="d-flex" style={{justifyContent: "center"}}>

<a style={{color: "white", fontStyle: "italic", fontSize: "16px", textDecoration: "underline"}} href={`https://www.google.com/search?q=convertir+${this.state.price_mount}+d%C3%B3lares+a+pesos+mexicanos`} target="_blank">
Convertir a pesos mexicanos
</a>
</div>}

        
                <div className="slide-btn" style={{marginTop: "15px", textAlign: "center"}}>
            <a href={`/inscripcion/${this.state.bootcampSlug}/${this.state.countryCode}`}>
                    <button
                    onClick={this.TrackEventConversion}
                    id="enroll_bootcamp_2"
                    style={{lineHeight: "28px", fontSize: "20px", marginBottom: "13px", padding: "15px 30px" }}
                    className=" btn-efect">
                    <GoRocket style={{fontSize: "20px", marginRight: "10px"}}/>
                    Empezar inscripción
                     </button>
                    </a>
            </div>


                <div className="col-12 text-center mt-2">
                    <button
                    onClick={() => this.setState({isOpenResume: !this.state.isOpenResume})}
                    name="submit"
                    id="mc-embedded-subscribe-"
                    style={{color: "white", marginTop: "5px", marginBottom: "5px", fontSize: "22px",
                    "border-radius": "20px",
                    "border": "2px solid white",
                    "padding": "9px 20px"
                }}>
                    Ver detalles
                    <AiOutlineRight style={{padding: "5px", fontSize: "30px"}}/>
                    </button>
                </div>


            </div>

        </div>
    </div>
</div>
{/* End PRicing Table Area  */}



          {/* <div className="col-12 mt-1" style={{paddingBottom: "13px", paddingRight: "10px", paddingLeft: "10px", maxWidth: "390px", marginRight: window.type_device ? "auto": "1px" }} >
               
               <div className="rn-pricing" style={{boxShadow: "#00fa9a59 3px 3px 15px 0px", border: "none", background:"linear-gradient(135deg, #fad961 0%,#f76b1c 100%)", borderRadius: "20px"}}>
                   <div className="pricing-table-inner">
                       
                       <div className="pricing-header pb-1">
                           
                       <span style={{padding: "3px", marginLeft: "7px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                       <FiVideo/> En vivo
                                       </span>                                                
       
                       <h3 className="text-black mb-2" style={{textTransform: "uppercase"}} >PRUEBA gratis <br/>
                        {this.state.timePrueba}</h3>
                            
                         

                            <h4 style={{margin: "5px", fontSize: "19px", color: "black", fontWeight: "500"}} >
                               <b>¿Te gustaría probar nuestro Bootcamp antes de inscribirte?</b>
                               <br/>
                               ¡No hay problema! Obtén una prueba gratis de {this.state.timePrueba} para que puedas experimentar por ti mismo cómo nuestro Bootcamp puede ayudarte a alcanzar tus objetivos.
                               ¡Despega hacia tus metas con <b>confianza!</b> 🚀
                           </h4>
                      
                           <div>
       
                           </div>
                                                        
       
                           <div className="slide-btn" style={{marginTop: "15px", textAlign: "center"}}>
                       <a href={`/inscripcion/backend-prueba/${this.state.countryCode}`}>
                               <button
                               onClick={this.TrackEventConversion}
                               id="enroll_bootcamp_2"
                               style={{lineHeight: "28px", fontSize: "20px", marginBottom: "13px", padding: "15px 30px" }}
                               className=" btn-efect">
                               <GoRocket style={{fontSize: "20px", marginRight: "10px"}}/>
                               EMPEZAR PRUEBA</button>
                               </a>
                       </div>
              
       
                           <div className="col-12 text-center mt-1">
                               <button
                               onClick={() => this.setState({isOpenShowPrueba: !this.state.isOpenShowPrueba})}
                               name="submit"
                               style={{color: "black", marginTop: "5px", marginBottom: "5px", fontSize: "20px",
                               "border-radius": "20px",
                               "border": "2px solid black",
                               "padding": "9px 15px"
                           }}>¿Qué incluye la Prueba?<AiOutlineRight style={{padding: "5px", fontSize: "28px"}}/>
                               </button>
                           </div>
       
       
                       </div>
       
                   </div>
               </div>
           </div>
           */}

           {/* End PRicing Table Area  */}
       

                            </div>



                        </div>
                    </div>




{/* 
<div className="container" id="enroll" style={{margin: "auto"}}>

<div className="row mt-5 mb-5">
<div className="col-12 col-sm-12 col-md-5 col-lg-5 m-auto" style={{"box-shadow": "6px 5px 15px 0px #321b6278", "border-radius": "30px", "background": "white", maxWidth: window.type_device ? "340px": ""}}>


<div className="about-inner inner pb-4 pt-4"  >
        <div>
            <h2 class="text-center title" style={{fontSize: "45px"}}>INSCRIPCIÓN</h2>

            <div className="contact-form--1" style={{ paddingTop: "25px" }}>
            <form onSubmit={this.onSubmit}>
                <div className="row" style={{paddingLeft: !window.type_device ? "45px" : "", paddingRight: !window.type_device ? "45px" : ""}}>
                <label class="col-12" htmlFor="item01">
                    <input
                    type="text"
                    name="name"
                    id="item01"
                    value={this.state.Name}
                    onChange={(e) => {
                        this.setState({ Name: e.target.value });
                    }}
                    placeholder="¿Cuál es tu nombre?"
                    />
                </label>

                <label class="col-12" htmlFor="item02">
                    <input
                    type="email"
                    name="email"
                    id="item02"
                    value={this.state.Email}
                    onChange={(e) => {
                        this.setState({ Email: e.target.value });
                    }}
                    placeholder="¿Cuál es tu Email?"
                    />
                </label>

                <div className="col-12">
                    <Selectrix
                    height={300}
                    onRenderOption={this.onRenderOption}
                    onRenderSelection={this.onRenderSelection}
                    placeHolderInside={true}
                    placeholder={"País"}
                    customScrollbar={true}
                    searchable={false}
                    materialize={false}
                    defaultValue={this.state.countryCode}
                    options={[
                        { key: "PE", label: "(+51)" },
                        { key: "MX", label: "(+52)" },
                        { key: "CO", label: "(+57)" },
                        { key: "CL", label: "(+56)" },
                        { key: "EC", label: "(+593)" },
                        { key: "US", label: "(+1)" },
                        { key: "PY", label: "(+595)" },
                        { key: "AR", label: "(+54)" },
                        { key: "BO", label: "(+591)" },
                        { key: "UY", label: "(+598)" },
                        { key: "PA", label: "(+507)" },
                        { key: "CR", label: "(+506)" },
                        { key: "GT", label: "(+502)" },
                        { key: "CU", label: "(+53)" },
                        { key: "SV", label: "(+503)" },
                        { key: "ES", label: "(+34)" },
                        { key: "HN", label: "(+504)" },
                        { key: "NI", label: "(+505)" },
                        { key: "PR", label: "(+1)" },
                        { key: "DO", label: "(+1)" },
                        { key: "BR", label: "(+55)" },
                    ]}
                    onChange={(value) => this.DynamicCountry(value.key)}
                    />
                </div>

            
                <div className="col-12" style={{ margin: "8px" }}></div>

                <label className="col-12" htmlFor="Phone">
                    <input
                    type="text"
                    name="phone"
                    id="Phone"
                    value={this.state.Phone}
                    onChange={(e) => {
                        this.setState({ Phone: e.target.value });
                    }}
                    placeholder="N.º Celular / WhatsApp"
                    />
                </label>

         
                <div className="col-12 text-center">

                    <button
                        className="rn-button-style--2 btn-solid"
                        type="submit"
                        name="submit"
                        id="mc-embedded-subscribe-"
                        style={{ marginTop: "13px", fontSize: "17px", marginBottom: "13px" }}
                        >
                        {!this.state.loadingEnroll && <GoRocket style={{fontSize: "20px", marginRight: "8px"}}/>}
                        {this.state.loadingEnroll  &&
                                                <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                style={{fontSize: "20px", marginRight: "8px"}}
                                                />
                            }


                        EMPEZAR INSCRIPCIÓN
                    </button>
                </div>

                </div>
            </form>
            </div>
        </div>
        </div>
</div>        
</div>

</div> */}







   {/* Start About Area */}
    {/* <div data-aos="fade-right" className="about-area ptb--30 bg_color--5" id="bonus2" style={{marginTop: "1px"}}>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                            <div className="text-center col-12">
                          
                                <h3 className="title" style={{padding: "7px"}}>
                                    ¿Buscas una modalidad diferente?
                                    </h3>
                            </div>
                               <div className="p-3">
                                    <p>
                                   Hola, 👋 si la fecha y el horario establecido en el Bootcamp en VIVO no son favorables para ti,
                                   el Bootcamp Flex a tu propio ritmo es la solución.
                                    </p>
                                    <div className="slide-btn mt-2">
                                        <a href="/bootcamp-flex">
                                        <button value="" style={{textAlignLast: "center", fontSize: "18px"}} className="rn-btn">
                                                        Conoce el Bootcamp Flex aquí
                                                    </button>
                                        </a>
                                                   
                                                </div>
                               </div>

                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End About Area */}



                    <div className="container" style={{marginTop: "30px"}} >
                   

             

                    <div style={{marginTop: "30px"}} className="section-title service-style--3 text-center mb--25 mb_sm--0 bg_color--5">
                    <h4 className="title">Métodos de Pago <br/>(Paga con tarjeta de débito, crédito, transferencia o efectivo.)</h4>
                    <img height="83px" className="ayudar" src="/assets/images/dojopy/payment-card.png" alt="métodos de pago"/>
                    </div>

     
                    </div>







{/* Start Team Area  */}
<div className="rn-team-area ptb--50 bg_color--1" id="team" data-aos="fade-right" >
            <div className="container">
                        <div className="row mt-5">

</div>

                        </div>
                </div>
                {/* End Team Area  */}




                {/* Start About Area */}
                <div data-aos="fade-right" className="about-area ptb--80 " id="methodology">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">


                                 {window.type_device ? (
                                        <div className="col-12" style={{textAlign: "-webkit-center", marginLeft: "-45px", marginBottom: "-40px"}}>
                                            <ReactPlayer
                                            muted={true}
                                            loop={true}
                                            url={"/assets/video/demo_lite_1.mp4"}
                                            playing={true}
                                            width={400}
                                            height={250}
                                                /> 
                                        </div>
                                    ):(
                                        <div className="col-12" style={{textAlign: "-webkit-center"}}>
                                        <ReactPlayer
                                        muted={true}
                                        loop={true}
                                        url={"/assets/video/demo_lite_1.mp4"}
                                        playing={true}
                                        width={520}
                                        /> 
                                        </div>
                                    )}


                                <div className="col-12" style={{textAlign: "-webkit-center", paddingTop: "30px"}}>
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                        {window.type_device ? (
                                            <h2>Aprende 100% online en vivo</h2>
                                        ): (
                                            <TypingAnimation2/>
                                        )}
                                        
                                        </div>

                                        <h4> 
                                        En Dojopy proponemos un nuevo modelo educativo que incluye entornos de
aprendizaje sincrónicos y asincrónicos con un enfoque que vincula la teoría y la
práctica, mediante un aprendizaje activo.
                                            </h4>

                                    <div className="row mt--30">
                                                    
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> 100% online en vivo</h4>
                                                </div>
                                            </div>
                                        

                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Sesiones grupales y seguimiento personalizado 1 a 1 en vivo</h4>
                                             
                                                </div>
                                            </div>
                                
                                            <div className="col-12">
                                                <div className="about-us-list">
                                                    <h4 className="title"><FiCheck style={{"color": "slateblue"}}/>
                                        
                                                    Aprende, comparte y crea proyectos con miembros de Latinoamérica.</h4>
                                                </div>
                                                <div>
                                                <img style={{maxHeight: "350px", maxWidth: "650px"}} className="w-100" src="/assets/images/dojopy/latan.webp" alt="zoom"/>
                                                </div>
                                            </div> 

                                        </div>

                                    </div>
                                </div>
                                
                            </div>
                            
                        </div>

                {/* <div className="service-area creative-service-wrapper ptb--80 bg_color--1" id="service" style={{marginTop: "-20px"}}>
                    <div className="container" data-aos="fade-up" >
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList5 item="6" column="col-md-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div> */}

                    </div>
                </div>
                {/* End About Area */}



                

                <Modal show={this.state.isOpenResume} size="md" onHide={this.openModal4} >
                    <span style={{textAlign: "right", "color":"slateblue"}}> <AiOutlineClose onClick={() => this.openModal4()} style={{fontSize: "33px", marginRight: "12px", marginTop: "12px", color: "slateblue"}} /> </span>

                    <Modal.Body>
                    <div className="">
                        <div className="section-title">
                            <h3 className="title"></h3>
                            
                            <div className="pricing-body mb-3">
                                      
                                      <ul className="list-style--1" style={{marginTop: "5px"}}>

                                        <div className="row">
                                            <div className="col-1">
                                                <span style={{"color":"slateblue", "fontSize": "22px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                            </div>
                                            <div className="col-10">
                                            <p style={{fontSize: "16px", "margin-bottom": "7px"}}>
                                                <b style={{color: "mediumseagreen"}}> Te ayudamos a progresar y potenciar tus habilidades, esto puede ahorrarle meses de su tiempo.</b> <br/>
                                            </p>
                                            </div>
                                        </div>
                                      
                                        
                                      
                                          <p style={{fontSize: "16px", "margin-bottom": "7px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Aprende 100% online en vivo. <br/>
                                          </p>
                                      
                                          <p style={{fontSize: "16px", "margin-bottom": "7px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          4 semanas de entrenamiento.<br/>
                                          </p>
                                      
                                          <p style={{fontSize: "16px", "margin-bottom": "7px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Grupo reducido y personalizado.
                                          <br/></p>
                                      
                                          <p style={{fontSize: "16px", "margin-bottom": "7px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                           Seguimiento y mentorías 1 a 1.. <br/>
                                          </p>
                                      
                                          
                                          <p style={{fontSize: "16px", "margin-bottom": "7px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Actividades y revisiones de código por parte de mentores<br/></p>
                                      
                                      
                                          <p style={{fontSize: "16px", "margin-bottom": "7px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Aprende con proyectos reales <br/> (API REST Tienda Online, Web de pedidos)
                                          </p>
                                      
                                      
                                          <p style={{fontSize: "16px", "margin-bottom": "7px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Optimización de CV y desarrollo profesional.<br/></p>
                                      
                                          <p style={{fontSize: "16px", "margin-bottom": "7px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Las sesiones en vivo quedan grabadas, con acceso a la Plataforma por 14 semanas adicional, para seguir practicando y aprendiendo. <br/></p>
                                      
                                          <p style={{fontSize: "16px", "margin-bottom": "7px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Certificado digital<br/></p>
                                      
                                          <p style={{fontSize: "16px", "margin-bottom": "7px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Acceso a la bolsa de empleos con empresas aliadas a nivel local e internacional. <br/></p>
                                      
                                      
                                      </ul>
                                      </div> 

                    </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{"justify-content": "center"}}>
                        <div className={window.type_device ? "mb-5": "mb-2"}>
                        <button style={{fontSize: "18px"}} className="rn-button-style--2 btn-solid" onClick={this.openModal4}>Cerrar</button>
                        </div>
                    </Modal.Footer>
                </Modal>



                <Modal show={this.state.isOpen} size="lg" onHide={this.openModal} >
                    <Modal.Body>
                        <img src="/assets/images/dojopy/CERTIFICADO_PY_.webp"></img>
                    </Modal.Body>
                    <Modal.Footer style={{"justify-content": "center"}}>
                    <button className="rn-btn" onClick={this.openModal}>Cerrar</button>
                    </Modal.Footer>
                </Modal>


                <Modal show={this.state.isOpen2} size="md" onHide={this.openModal2} >
                    <Modal.Body>
                    <div className="about-inner inner">
                        <div className="section-title">
                            <h4 style={{marginTop: "5px", marginBottom: "7px"}} className="title">Requisitos</h4>
                            <p>
                            No te preocupes. Nuestros programas son aptos para principiantes y no requieren ningún conocimiento previo ni hay limite de edad. Además, no estarás solo. Nuestro equipo estará a tu lado para guiarte en el proceso, ayudarte a establecer objetivos y a tener éxito.
                            </p>
                            <p>
                                <b>*Instalar la herramienta de transmisión en vivo:</b>
                                <img height="85px" src="/assets/images/dojopy/zoom_.webp"></img>                                
                            </p>
                    </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{"justify-content": "center"}}>
                    <button className="rn-btn" onClick={this.openModal2}>Cerrar</button>
                    </Modal.Footer>
                </Modal>


                <Modal show={this.state.isOpen3} size="md" onHide={this.openModal3} >
                    <Modal.Body>
                    <div className="about-inner inner">
                        <div className="section-title">
                            <h3 className="title"></h3>
                            <p>
                            Cada vez más empresas buscan programadores backend capaces de comprender y crear aplicaciones web que brinden todo lo que éstas necesiten para
dar respuesta a sus usuarios. <br/>

Este perfil se especializa en la creación de APIs, bases de datos y arquitecturas escalables.<br/> Es decir, en todo aquello que
no se ve, pero que es fundamental para el funcionamiento de las aplicaciones y sitios web que usamos todos los días.<br/>

En este bootcamp aprenderás desde los fundamentos de la programación hasta el lanzamiento de aplicaciones web sólidas haciendo uso de las últimas tecnologías
de la industria.

                            </p>
                    </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{"justify-content": "center"}}>
                    <button className="rn-btn" onClick={this.openModal3}>Cerrar</button>
                    </Modal.Footer>
                </Modal>


            <Modal show={this.state.isOpenTemario} size="md" onHide={this.openModalEmail} style={{zIndex: "999999", alignSelf: "center"}}>
                
            <div style={{marginTop: "15px"}}>
                <span style={{fontSize: "20px", "text-align": "left", fontWeight: "600", marginBottom: "1px", color: "black"}}>
                    <BsArrowLeftCircle onClick={() => this.openModalEmail()} style={{marginRight: "10px", cursor: "pointer", fontSize: "39px", marginLeft: "12px", color: "slateblue"}} />
                    Programa
                    </span>
                </div>

            <Modal.Body >
                <div className="inner">
                    <div className="contact-form--1">
                        <div className="container" style={{maxWidth: "370px"}}>
                            <div className="row row--35 align-items-center justify-content-center">
                                <div>

                                <div style={{textAlign: "-webkit-center"}} className="form-wrapper">
                                <div className="thumbnail" style={{paddingBottom: "8px", maxWidth: window.type_device ? "310px": "370px" }}>
                                <img style={{boxShadow: "#424241 6px 5px 15px 0px"}} className="image_temario" src="/assets/images/dojopy/bootcamp-backend.png" alt="temario bootcamp"/>
                                </div>


                    <form onSubmit={this.DownloadPdf}>
                        <div className="row p-1">
                        <div className="col-12 text-center p-1" >

                        <label class="col-12" htmlFor="item02">
                            <input
                            style={{marginBottom: "5px"}}
                            type="email"
                            name="email"
                            id="item02"
                            value={this.state.Email}
                            onChange={(e) => this.setEmailValidate(e.target.value)}
                            placeholder="¿Cuál es tu correo electrónico?"
                            />
                        </label>
                            

                    <div className="row p-2">
                        <div className="col-5">
                            <Selectrix
                            height={300}
                            onRenderOption={this.onRenderOption}
                            onRenderSelection={this.onRenderSelection}
                            placeHolderInside={true}
                            placeholder={"País"}
                            customScrollbar={true}
                            searchable={false}
                            materialize={false}
                            defaultValue={this.state.countryCode}
                            options={KeyCountry}
                            onChange={(value) => this.DynamicCountry(value.key)}
                            />
                        </div>


                        <label className="col-7" htmlFor="Phone" style={{paddingLeft: "1px"}}>
                            <input
                            style={{marginBottom: "5px"}}
                            type="text"
                            name="phone"
                            id="Phone"
                            value={this.state.Phone}
                            onChange={(e) => {
                                this.setState({ Phone: e.target.value });
                            }}
                            placeholder="N.º WhatsApp"
                            />
                        </label>
                        
                    </div>

                    <div className="col-12" style={{color: "white", fontSize: "14px", textAlign: "left"}}>
                    <label htmlFor="checkNotify" style={{float: "left", color: "black"}}>
                        <input
                            style={{width: 18, float: "left", marginTop: "-10px", marginRight: "9px", marginBottom: "1px"}} 
                            defaultChecked={true}
                            type="checkbox"
                            name="checkNotify"
                            id="checkNotify"
                        />
                        <span>Protección de datos y privacidad.  <FcDataProtection style={{fontSize: "20px"}} /> </span>
                    </label>
                    </div>

                                <button className="rn-button-style--2 btn-solid" type="submit" name="submit" id="mc-embedded-subscribe-" style={{maxWidth: "95%", fontSize: "18px", marginTop: '1px', fontWeight: "bold"}}>
                                <MdEmail style={{fontSize:"25px"}} /> Descargar programa
                                </button>
                        </div>
                        </div>

                        </form>

                        <div>
                        <button onClick={() => this.openModalEmail() } className="rn-button-style--1 btn-solid" type="submit" name="submit"  style={{marginTop: '13px', fontSize: "16px"}}>
                        Cerrar
                        </button>
                        </div>
                   
                        </div>
               
                        </div>
                        </div>
                        </div>
                        </div>
                    </div>
                    </Modal.Body>
            
            </Modal>

           


                <Modal show={this.state.isOpenSuscribe} size="md" onHide={this.openModalSuscribe}>
                <Modal.Header closeButton style={{"border-bottom": "white", "height": "10px"}}>
                </Modal.Header>
                <Modal.Body>
                <div className="inner">
                    <div className="contact-form--1">
                        <div className="container" style={{maxWidth: "500px"}}>
                            <div className="row row--35 align-items-start">
                                <div className="col-12 order-2">

                                <div style={{textAlign: "-webkit-center"}} className="form-wrapper">
                              <p style={{fontSize: "15px", textAlign: "left", fontWeight: 500}}>Resumen de tu solicitud:</p>
                              <ul style={{fontSize: "15px", textAlign: "left"}}>
                                  <li>No es necesario tener conocimientos técnicos previos.</li>
                                  <li>Apoyo profesional durante y al final del Bootcamp.</li>
                                  <li>Únete a nuestra comunidad de más de 1,000 estudiantes de todo el mundo que trabajan en tecnología.</li>
                            </ul>

                        <form onSubmit={this.onSubmit}>

                           <label htmlFor="item01">
                                <input
                                    required
                                    type="text"
                                    name="name"
                                    id="item01"
                                    value={this.state.Name}
                                    onChange={(e)=>{this.setState({Name: e.target.value});}}
                                    placeholder="¿Cuál es tu nombre?"
                                />
                            </label>

                            <label htmlFor="item02">
                                <input
                                    required
                                    type="email"
                                    name="email"
                                    id="item02"
                                    value={this.state.Email}
                                    onChange={(e)=>{this.setState({Email: e.target.value});}}
                                    placeholder="¿Cuál es tu Email?"
                                />
                            </label>

                            <div className="row">
                            <div className="col-5">
                                        <Selectrix
                                            style={{margin: "10px"}}
                                        	height={300}
                                            onRenderOption={this.onRenderOption}
                                            onRenderSelection={this.onRenderSelection}
                                            placeHolderInside={true}
                                            placeholder={"País"}
                                            customScrollbar={true}
                                            searchable={false}
                                            materialize={false}
                                            defaultValue={this.state.countryCode}
                                            options={[
                                                {key: "PE", label: "+51"},
                                                {key: "MX", label: "+52"},
                                                {key: "CO", label: "+57"},
                                                {key: "CL", label: "+56"},
                                                {key: "EC", label: "+593"},
                                                {key: "US", label: "+1"},
                                                {key: "PY", label: "+595"},
                                                {key: "AR", label: "+54"},
                                                {key: "BO", label: "+591"},
                                                {key: "UY", label: "+598"},
                                                {key: "PA", label: "+507"},
                                                {key: "CR", label: "+506"},
                                                {key: "GT", label: "+502"},
                                                {key: "CU", label: "+53"},
                                                {key: "SV", label: "+503"},
                                                {key: "ES", label: "+34"},
                                                {key: "HN", label: "+504"},
                                                {key: "NI", label: "+505"},
                                                {key: "PR", label: "+1"},
                                                {key: "DO", label: "+1"},
                                                {key: "BR", label: "+55"}                                                
                                            ]}
                                            onChange={value => this.DynamicCountry(value.key)}
                                        />
                                    </div>
                                    <div className="col-7">
                                    <label htmlFor="Phone">
                                        <input
                                            required
                                            type="text"
                                            name="phone"
                                            id="Phone"
                                            value={this.state.Phone}
                                            onChange={(e)=>{this.setState({Phone: e.target.value});}}
                                            placeholder="N.º WhatsApp"
                                        />
                                    </label>
                                    </div>
                                    <div className="col-2">
                                    <label htmlFor="checkNotify">
                                        <input
                                            style={{width: 18}}
                                            defaultChecked={true}
                                            type="checkbox"
                                            name="checkNotify"
                                            id="checkNotify"
                                        />
                                    </label>
                                    </div>
                                    <div className="col-10" style={{fontSize: "14px", textAlign: "left", marginLeft: "-10px"}}>
                                    Quiero recibir noticias de Dojopy y estar en contacto con la comunidad.
                                    </div>
                                </div>


                            <button className="rn-button-style--2 btn-solid" type="submit" name="submit" id="subscribe-boletin" style={{marginTop: '13px'}}>
                                continuar
                            </button>
                    </form>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                    </div>
                    </Modal.Body>
                </Modal>



                {/* Start Brand Area */}
                <div className="rn-brand-area bg_color--1 ptb--80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}









  {/* Start Team Area  */}
  <div className="rn-team-area ptb--80 bg_color--1" id="team" data-aos="fade-right" >
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                        <h2 className="title">Nuestro Equipo</h2>
                                        <p>Nos apasiona la tecnológica y los desafíos de este mundo cambiante.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{justifyContent: "center"}}>
                                <TeamFull column="col-4 col-md-2" />
                            </div>
                        </div>
                </div>
                {/* End Team Area  */}



 {/* <div className="about-area ptb--60 bg_color--5" id="practicas">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/dojopy/practicas.webp" alt="profesional"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                       
                                            <h2 className="title" style={{fontSize: "38px"}}>
                                            Prácticas Pre Profesionales 
                                                </h2>
                                                <p>
                                        <b> Prácticas Pre Profesionales </b>  </p>
                                            <p>
                                            Despues de finalizar el Bootcamp de 6 meses puedes aplicar a alguno de nuestros proyectos con las empresas asociadas a Dojopy EIRL, el único requisito para ingresar será haber asistido mínimo al 80% de las sesiones en vivo del bootcamp y completar más del 60% de actividades asignadas en el bootcamp y tu ingreso sera inmediato.

                            </p>
                                
                                <p>
                                <b> Durante estas prácticas también vas a recibir asesoría por nuestro equipo de mentores.
Culminado estas prácticas puedes adjuntarlo en tu C.V o perfil digital como experiencia en el desarrollo web full stack.
</b></p>
                               


                                        </div>
                                  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}



<div className="rn-testimonial-area bg_color--1 ptb--80" style={{"padding": "10px"}}>
    <div className="container">
            <div className="rn-testimonial-area bg_color--1 ptb--80" style={{"padding-bottom": "30px"}}>
                    <div className="container">
                        <TestimonialTree/>
                    </div>
                </div>
    </div>
</div>


                {/* Start Team Area  */}
                <div data-aos="fade-up" className="rn-team-area ptb--80 bg_color--1" id="modules">
                    <div className="container" style={{"maxWidth": "900px"}}>
                        <div className="row">
                            <div className="col-12">
                                <div className="text-center mb--30">
                                    <h3 className="title">El Bootcamp está formado por los siguientes módulos</h3>
                                </div>
                                <div>
                            <AccordionWrapper>
                            <AccordionItem index={1} title={"Backend con Python"} description={InfoAcordeon.python}></AccordionItem>
                            <AccordionItem index={2} title={"Backend con Django"} description={InfoAcordeon.django}></AccordionItem>
                                <AccordionItem index={3} title={"Bases de Datos"} description={InfoAcordeon.database}></AccordionItem>
                                <AccordionItem index={4} title="API REST" description={InfoAcordeon.api}></AccordionItem>
                                <AccordionItem index={5} title="Testing & Seguridad" description={InfoAcordeon.apiSecure}></AccordionItem>
                                <AccordionItem index={6} title={"Despliegue en la nube de AWS"} description={InfoAcordeon.deploy_aws}></AccordionItem>
                                <AccordionItem index={7} title={"Empleabilidad"} description={InfoAcordeon.employer}></AccordionItem>
                            </AccordionWrapper>
                                </div>
                            </div>
                        </div>
                  
                    </div>
                    

                </div>
                {/* End Team Area  */}




                <Modal show={this.state.isOpenShowPrueba} size="md" onHide={() => this.setState({isOpenShowPrueba: !this.state.isOpenShowPrueba})} style={{zIndex: "9999999999"}} >
                    <span style={{textAlign: "left", "color":"slateblue"}}> <BsArrowLeftCircle onClick={() => this.setState({isOpenShowPrueba: !this.state.isOpenShowPrueba}) } style={{ cursor: "pointer", fontSize: "35px", marginLeft: "12px", marginTop: "12px", color: "slateblue"}} /> </span>

                    <Modal.Body>
                    <div className="">
                        <div className="section-title">
                            <h3 className="title"></h3>
                            
                            <div className="pricing-body mb-3">
                                      
                                      <ul className="list-style--1" style={{marginTop: "5px"}}>

                                        <p style={{fontSize: "16px", "margin-bottom": "7px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Disfruta de {this.state.timePrueba} de acceso a la plataforma del Bootcamp Full Stack.  <br/>
                                          </p>

                                          <p style={{fontSize: "16px", "margin-bottom": "7px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Accede a las primeres lecciones del Bootcamp en vivo "Introducción a Backend con Python". <br/>
                                          </p>
                                      
                                          {/* <p style={{fontSize: "16px", "margin-bottom": "7px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Accede a las primeras actividades. (aprox. 2 h en completar). <br/>
                                          </p> */}
                                                                            
                                          <p style={{fontSize: "16px", "margin-bottom": "7px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                            Accede a módulos interactivos.
                                          </p>
                                      
                                          <p style={{fontSize: "16px", "margin-bottom": "7px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                            <b>
                                            Soporte activo y en vivo por nuestro equipo en todo momento.
                                            </b>
                                          </p>                                                                                                                      
                                                                            
                                      </ul>
                                      </div>

                                      <div className="slide-btn" style={{marginTop: "15px", textAlign: "center"}}>
                       <a href={`/inscripcion/backend-prueba/${this.state.countryCode}`}>
                               <button
                               onClick={this.TrackEventConversion}
                               id="enroll_bootcamp_2"
                               style={{lineHeight: "28px", fontSize: "20px", marginBottom: "6px", padding: "15px 30px" }}
                               className=" btn-efect">
                               <GoRocket style={{fontSize: "20px", marginRight: "10px"}}/>
                               EMPEZAR PRUEBA</button>
                               </a>
                       </div>
 

                    </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{"justify-content": "center"}}>
                        <div className={window.type_device ? "mb-5": "mb-2"}>
                        <button style={{fontSize: "18px"}} className="rn-button-style--2 btn-solid" onClick={() => this.setState({isOpenShowPrueba: !this.state.isOpenShowPrueba})}>Cerrar</button>
                        </div>
                    </Modal.Footer>
                </Modal>






  {/* Start Team Area  */}
  <div data-aos="fade-up" className="rn-team-area ptb--20 bg_color--1" id="roadmap" style={{marginBottom: "-5px" }}>
                    <div className="container" style={{"maxWidth": "900px"}}>
                        <div className="row">
                            <div className="col-12">
                                <div className="text-center mb--30">
                                    <h2 className="title">RoadMap</h2>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* End Team Area  */}


<VerticalTimeline
    lineColor="slateblue"
>
<VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'darkgreen', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  darkgreen' }}
    iconStyle={{ background: 'darkgreen', color: 'white' }}
    icon={<GoRocket style={{fontSize: "25px"}}/>}
  >
    <h3 className="vertical-timeline-element-title">Acceso Plataforma</h3>
    <p>
    Como miembro de Dojopy, tendrás acceso inmediato a la
    Plataforma innovadora para prepararse y practicar antes de las sesiones en vivo del Bootcamp.
    La plataforma está impulsada por Inteligencia Artificial y ofrece lecciones y retroalimentación personalizadas
    según el progreso del estudiante.
    También hay acceso a múltiples módulos, proyectos y soluciones compartidas de la comunidad, así como una comunidad propia de Dojopy.
    </p>
  </VerticalTimelineElement>



  <VerticalTimelineElement
    contentStyle={{ background: 'mediumseagreen', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  mediumseagreen' }}
    className="vertical-timeline-element--education"
    iconStyle={{ background: 'mediumseagreen', color: '#fff' }}
    icon={<FaRunning />}
    date=""
  >
    <h3 className="vertical-timeline-element-title">Backend con Python</h3>
    <h4 className="vertical-timeline-element-subtitle"></h4>
    <p>
                {InfoAcordeon.python}
       </p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
    contentStyle={{ background: 'slateblue', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  slateblue' }}
    className="vertical-timeline-element--education"
    iconStyle={{ background: 'slateblue', color: '#fff' }}
    icon={<FaRunning />}
    date=""
  >
    <h3 className="vertical-timeline-element-title">Backend con Django</h3>
    <h4 className="vertical-timeline-element-subtitle"></h4>
    <p>
    El framework de aplicaciones web para construir aplicaciones web robustas y escalables, Django es el framework backend más popular en Python, Instagram y Dropbox lo utilizan.
    </p>
  </VerticalTimelineElement>


  <VerticalTimelineElement
    contentStyle={{ background: 'purple', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  purple' }}
    className="vertical-timeline-element--education"
    iconStyle={{ background: 'purple', color: '#fff' }}
    icon={<FaRunning />}
    date=""
  >
    <h3 className="vertical-timeline-element-title">{!this.state.langUS ? "Testing & Seguridad" : "Testing & Security"}  </h3>
    <h4 className="vertical-timeline-element-subtitle"></h4>
    <p>
    {!this.state.langUS ? "En este módulo aprenderás a implementar pruebas automatizadas para verificar el correcto funcionamiento de tu código, así como a identificar y solucionar posibles vulnerabilidades y riesgos de seguridad en tu aplicación. Este módulo te dotará de las habilidades necesarias para garantizar la confiabilidad y protección de tus proyectos web, ofreciendo una experiencia segura y libre de errores para los usuarios finales." :
     "In this module you will learn to implement automated tests to verify the correct functioning of your code, as well as to identify and solve possible vulnerabilities and security risks in your application. This module will equip you with the necessary skills to guarantee the reliability and protection of your web projects, offering a safe and error-free experience for end users."}
    </p>
  </VerticalTimelineElement>



  <VerticalTimelineElement
    contentStyle={{ background: 'mediumseagreen', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  mediumseagreen' }}
    className="vertical-timeline-element--education"
    iconStyle={{ background: 'mediumseagreen', color: '#fff' }}
    icon={<FaRunning />}
    date=""
  >
    <h3 className="vertical-timeline-element-title">Proyecto Backend API </h3>
    <h4 className="vertical-timeline-element-subtitle"></h4>
    <p>
    {InfoAcordeon.project}
    </p>
  </VerticalTimelineElement>


  <VerticalTimelineElement
    contentStyle={{ background: 'purple', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  purple' }}
    className="vertical-timeline-element--education"
    iconStyle={{ background: 'purple', color: '#fff' }}
    icon={<FaRunning />}
    date=""
  >
    <h3 className="vertical-timeline-element-title"> {!this.state.langUS ? "Bases de Datos" : "Databases"} </h3>
    <h4 className="vertical-timeline-element-subtitle"></h4>
    <p>
        Es muy importante saber qué, cómo y dónde se guardarán tus datos. Aprende desde cero los fundamentos y práctica para administrar DBs profesionalmente.
    </p>
  </VerticalTimelineElement>



  <VerticalTimelineElement
    contentStyle={{ background: 'darkorange', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  darkorange' }}
    className="vertical-timeline-element--education"
    iconStyle={{ background: 'darkorange', color: '#fff' }}
    icon={<FaRunning />}
    date=""
  >
    <h3 className="vertical-timeline-element-title">SERVICIOS EN LA NUBE CON AWS </h3>
    <h4 className="vertical-timeline-element-subtitle">(PROGRAMA OPTATIVO)</h4>
    <p>
    {InfoAcordeon.deploy_aws}
    </p>
  </VerticalTimelineElement>


  <VerticalTimelineElement
    contentStyle={{ background: 'midnightblue', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  midnightblue' }}
    iconStyle={{ background: 'midnightblue', color: '#fff' }}
    icon={<MdWork />}
  >
    <h3 className="vertical-timeline-element-title">Empleabilidad</h3>
    <p>
    Al finalizar del bootcamp, nuestro equipo de talento te asesorará para mejorar tu perfil profesional.
    </p>

  </VerticalTimelineElement>

  <VerticalTimelineElement
    contentStyle={{ background: 'goldenrod', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  goldenrod' }}
    iconStyle={{ background: 'goldenrod', color: '#fff' }}
    icon={<FaGraduationCap />}
  >
    <h3 className="vertical-timeline-element-title">Graduación BootCamp 2024</h3>
    <p>
        Soy Backend Developer! 😎
    </p>

  </VerticalTimelineElement>



</VerticalTimeline>


       



 {/* Start About Area */}
 <div data-aos="fade-right" className="about-area ptb--120 bg_color--5" id="benefits" style={{marginTop: "-100px"}}>
                    <div className="about-wrapper">
                        <div className="container mt-3">
                            <div className="row row--35 align-items-center">
                                <div className="col-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title text-center">
                                            Después de graduarte
                                            </h2>
                                          
                                        </div>
                                    <div className="row mt--30">
                                          
                                    <div className="col-12">
                                                <div className="">
                                                    <h4 style={{fontWeight: 400}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    Aprenderás las habilidades necesarias para crear aplicaciones y servicios web robustos y escalables. Además trabajarás con Python y Django,  desplegando un API REST completa en la nube de AWS.
                                                    </h4>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="">
                                                    <h4 style={{fontWeight: 400}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                        Te conectamos con proyectos y empresas que buscan tu perfil. 💪
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="">
                                                    <h4 style={{fontWeight: 400}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    Una comunidad de apoyo profesional de por vida.
                                                    </h4>
                                                </div>
                                            </div>


                                            <div className="col-12">
                                                <div className="">
                                                    <h4 style={{fontWeight: 400}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                        Portafolio de proyectos en Github y C.V optimizado 👌.
                                                    </h4>
                                                </div>
                                            </div>


                                            <div className="col-12">
                                                <div className="">
                                                    <h4 style={{fontWeight: 400}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    Acceso al portal de empleos o bolsa de trabajo exclusiva.
                                                    </h4>
                                                </div>
                                            </div>
                                          
                                     
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}



           


                <div style={{marginBottom: "-50px"}} className="section-title service-style--3 text-center mb--25 mb_sm--0 text-center">
                    <h2 className="title">Testimonios</h2>
                </div>

                <div className="rn-testimonial-area bg_color--1 ptb--80">
                    <div className="container">
                        <TestimonialOne />
                    </div>
                </div>


                {/* Start About Area */}
                {/* <div data-aos="fade-right" className="about-area ptb--60 bg_color--5" id="bonus" style={{marginTop: "-100px"}}>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img style={{maxHeight: "350px", maxWidth: "350px"}} className="w-100" src="/assets/images/featured/cv_virtual.webp" alt="frontend"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                        <h2 className="title">BONUS #1</h2>
                                        <h4 className="title">SITIO WEB HOJA DE VIDA VIRTUAL + PORTAFOLIO DE PROYECTOS</h4>

                                        </div>
                                    <div className="row mt--30">                                          
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h4>
                                                    🔵 CV con un diseño atractivo, sobre el dominio cv.dojopy.com/@user.
                                                    </h4>
                                                </div>
                                            </div>
                                            
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h4>
                                                    🔵 Linkea tu redes sociales, correos y websites.
                                                    </h4>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h4>
                                                    🔵 Linkea tus certificados digitales de Dojopy y de terceros.
                                                    </h4>
                                                </div>
                                            </div>


                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h4>
                                                    🔵 Muestra tu portafolio de proyectos eje: Réplica de Netflix
                                                    </h4>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End About Area */}




             

                <Preguntas bootcampSlug={this.state.bootcampSlug}/>





                {/* Start About Area */}
                {/* <div className="about-area text-center  bg_color--5" id="" style={{paddingTop: "70px"}}>
                    <div className="about-wrapper">
                        <div className="container" style={{maxWidth: "650px"}}>
                            <div className="row row--35 align-items-center">
                                <div className="col-12">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginBottom: "25px"}}>
                                            <h3 className="title"> Te obsequio mi Ebook 🚀</h3>
                                        </div>
                                      

                    <div className="row align-items-center" style={{justifyContent: "center"}}>
                        <div>
                            <div style={{marginTop: "20px"}} className="service-style--1 text-center mb--25 ">
                        <a style={{fontSize: "18px", "padding-top": "10px", "padding-bottom": "10px"}} className="rn-btn" href="/ebook" >
                        <FiBookOpen style={{marginRight: "5px", fontSize: "18px"}}/>
                                            IR AL EBOOK
                        </a>
                    </div>
                        </div>
                              

                                    </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End About Area */}

             
                <div className="footer_banner" style={{borderTopLeftRadius: "30px", borderTopRightRadius: "30px", zIndex: "99", padding: "1px", background: this.props.FooterColor }}>
                    <div className="row dojofoter" style={{
                        marginTop: !window.type_device ? "3px" : "2px",
                        marginBottom: !window.type_device ? "3px": "3px",
                        paddingLeft: !window.type_device ? "250px" : "15px",
                        paddingRight: !window.type_device ? "250px": "15px",
                        paddingTop: !window.type_device ? "7px": "3px",
                        paddingBottom: !window.type_device ? "7px": "3px"
                        }}>
<div onClick={() => this.setState({isOpenTemario: true})} className={"col-12 py-0 px-0 m-1"} style={{ alignItems: "center", textAlign: "center", alignSelf: "center"}} >
<a href={'#inversion'}>
<button
onClick={this.TrackEventConversion}
id="enroll_bootcamp_2"
style={{lineHeight: "25px", fontSize: "20px", padding: "12px 27px" }}
className=" btn-efect">
<GoRocket style={{fontSize: "20px", marginRight: "10px"}}/>
Empezar Inscripción
 </button>
</a>
                    </div>
                    </div>
                </div>


                 {/* Start Footer Style  */}
                 <FooterTwo />
                {/* End Footer Style  */}

            </Fragment>
        )
    }

    async componentDidMount() {
        const btnWhatsapp = document.getElementById('whatsapp');
        btnWhatsapp.style.display = 'block';
        // document.getElementById("whatsapp").href = this.GetLinkWS();
        window.urlWasi = this.GetLinkWS();


        try {
            const response = await axios.get("https://hiuaik2gocmwdeh2ub6lqz3hxq0ycvbe.lambda-url.us-east-1.on.aws/");
            let dataPrice = response.data;
            this.setState({pricingAPI: dataPrice})
            this.DynamicCountry();

            document.getElementById('whatsapp').style.display='block';

            axios.get('https://ipapi.co/json/').then((response) => {
                let data = response.data;
                let country = data.country_code;
                let utc_offset = data.utc_offset;
                                
                this.setState({utc_offset: utc_offset, countryCode: country});
                this.DynamicCountry(country);

                }).catch((error) => {
                    // this.sleep(1000).then(r => { document.getElementById('chat-ai').style.display='block'})
                    // document.getElementById('iframe_chat').src = 'https://ai.dojopy.com/?config=dojopy-asesor&agente=1&corpus_id=dojopy_data.txt';
                    console.log(error);
                });


        } catch (error) {
            console.log(error);
          }


          this.sleep(1000).then(r => {
            this.setState({show_pricing: true});
        })


        this.sleep(10000).then(r => {
            this.setState({isOpenTemario: true});
        })



        this.sleep(50000).then(r => {
            toast.success("Lisbeth y Jhon ya se inscribieron, tu tambien puedes formar parte!");
        })




      }



}


export default BootcampBackend;
